import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { FormHelperText } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import { EMAIL_REGEX } from '../../static/Constant';
import Utils from '../../shared/Utils';

function PersonalDetails(props) {
  const { firstName } = props.values;
  const { lastName } = props.values;
  const { gender } = props.values;
  const { email } = props.values;
  const { mobile } = props.values;
  const { parentName } = props.values;
  const { personalDetailsError } = props.values;

  function handleChangeMobile(value) {
    props.handleInputChange(value, 'mobile');
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Personal Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            error={personalDetailsError && firstName.length === 0}
            required
            id="firstName"
            name="firstName"
            label="Trainee First name"
            fullWidth
            autoComplete="fname"
            value={firstName}
            onChange={props.handleInputChange}
          />
          {personalDetailsError && firstName.length === 0 && (
            <FormHelperText id="componentfirstName" error>
              Please enter a valid name
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={personalDetailsError && lastName.length === 0}
            required
            id="lastName"
            name="lastName"
            label="Trainee Last name"
            fullWidth
            autoComplete="lname"
            value={lastName}
            onChange={props.handleInputChange}
          />
          {personalDetailsError && lastName.length === 0 && (
            <FormHelperText id="componentLastName" error>
              Please enter a valid name
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={
              personalDetailsError &&
              !EMAIL_REGEX.test(String(email).toLowerCase())
            }
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            value={email}
            disabled={props.isLoggedUser}
            onChange={props.handleInputChange}
          />
          {personalDetailsError &&
            !EMAIL_REGEX.test(String(email).toLowerCase()) && (
              <FormHelperText id="componentEmail" error>
                Please enter a valid email
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={personalDetailsError && parentName.length === 0}
            required
            id="parentName"
            name="parentName"
            label="Parent Name"
            fullWidth
            value={parentName}
            onChange={props.handleInputChange}
          />
          {personalDetailsError && parentName.length === 0 && (
            <FormHelperText id="componentParentName" error>
              Please enter a valid Parent Name
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneInput
            country="sg"
            preferredCountries={['sg', 'in', 'de', 'qa', 'ae', 'jp']}
            name="mobile"
            value={mobile}
            onChange={handleChangeMobile}
          />
          {personalDetailsError &&
            (mobile.length < 6 ||
              Utils.getMobilewithPlus(mobile) === '+910000000') && (
              <FormHelperText id="componentMobile" error>
                Please enter a valid mobile number
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup
              style={{ display: 'flex' }}
              aria-label="gender"
              name="gender"
              value={gender}
              onChange={props.handleInputChange}
            >
              <div>
                <FormControlLabel
                  value="male"
                  control={<Radio color="primary" />}
                  label="Male"
                  labelPlacement="end"
                  color="secondary"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio color="primary" />}
                  label="Female"
                  labelPlacement="end"
                  color="secondary"
                />
              </div>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    academy_to: state.to,
  };
}

export default connect(mapStateToProps)(PersonalDetails);
