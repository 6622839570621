import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import PackageSummary from '../../components/PackageSummary';
import BranchCard from '../../components/BranchCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  link: {
    margin: theme.spacing(0.75),
  },
  textSummary: {
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 1000,
  },
}));

function SuccessPage(props) {
  const classes = useStyles();
  const { values } = props;
  const branch = values.branches.find(
    (b) => b.id === parseInt(values.branch, 10)
  );
  const packageSelected = values.branchPackage.branch_package.find(
    (b) => b.id === parseInt(values.packageIdSelected, 10)
  );
  let season = packageSelected.season_type_cost.find(
    (s) => s.season_type_id === parseInt(values.packageSeasonIdSelected, 10)
  );
  if (season == null) {
    season = values.packageSelected.package_sessions;
  }
  const sessionDetailsList = values.branchSessionList.filter(
    (season) => values.sessionSelected.indexOf(String(season.id)) > -1
  );
  const country = values.countryObj;
  const includeRegistrationFee =
    props.academy_to !== '/player/active' && season.include_registration_fee;
  const registrationFee = includeRegistrationFee ? branch.registration_fee : 0;
  const pageTitle =
    props.academy_to === '/player/active'
      ? 'Package Renewal Successful'
      : 'Registration Successful';

  return (
    <div className={classes.root}>
      <Typography variant="h5" gutterBottom>
        {pageTitle}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={1}>
        {(!props.isLoggedUser || values.payment === 'offline') && (
          <div>
            {' '}
            <Grid item xs={12}>
              <Typography
                className={classes.textSummary}
                variant="subtitle1"
                gutterBottom
              >
                Thank you for registering with {branch.name}. Payment needs to
                be made to the below bank account within 3 days to complete your
                registration.
              </Typography>
              {branch.upi_qr_path && (
                <Box>
                  <img
                    src={branch.upi_qr_path}
                    alt="QR Code"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </Box>
              )}
              <Typography
                className={classes.textSummary}
                variant="body1"
                gutterBottom
              >
                {branch.bank_account_details}. Please mention student name/id in
                the reference.
              </Typography>
              <Typography className={classes.textSummary} variant="subtitle2">
                You will be receiving a confirmation email with the same
                details.
              </Typography>
            </Grid>
          </div>
        )}
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title} variant="h5" gutterBottom>
            Package Details
          </Typography>
          <PackageSummary
            name={packageSelected.name}
            numberOfSession={packageSelected.number_of_weekly_sessions}
            cost={season.cost}
            regFee={registrationFee}
            currency={country.currency}
            season={
              season.country_season_type != null
                ? season.country_season_type.season_type_name
                : `No of Session : ${season.number_of_sessions}`
            }
            sessionDetailsList={sessionDetailsList}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title} variant="h5" gutterBottom>
            Academy Details
          </Typography>
          <BranchCard
            name={branch.name}
            address={branch.address}
            branch_manager={branch.branch_manager}
            email={branch.email}
            contact_number={branch.contact_number}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {props.isLoggedUser && values.payment === 'online' && (
        <div>
          {' '}
          <Grid item xs={12}>
            <Typography
              className={classes.textSummary}
              variant="subtitle1"
              gutterBottom
            >
              Thank you for registering with {branch.name}. Payment is
              Successful.
            </Typography>
          </Grid>
        </div>
      )}
      {values.backendError && (
        <div>
          {' '}
          <Grid item xs={12}>
            <Typography
              className={classes.textSummary}
              variant="subtitle1"
              gutterBottom
            >
              If your package information does not appear in the Package list
              and if there is some problem with updating your package, contact
              us at info@sportskingdom.io or Contact your branch admin.
            </Typography>
          </Grid>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    academy_to: state.to,
  };
}

export default connect(mapStateToProps)(SuccessPage);
