import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import LinkMaterial from '@material-ui/core/Link';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Swal from 'sweetalert2';
import DialogSignUpSignIn from './DialogSignUpSignIn';

const useStyles = makeStyles((theme) => ({
  box: {
    maxWidth: '20vh',
    [theme.breakpoints.up(600)]: {
      maxWidth: '32vh',
    },
  },
  media: {
    height: '16vh',
    width: '23vh',
    [theme.breakpoints.up(600)]: {
      width: '32vh',
    },
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
  },
  CardActionArea: {
    display: 'flex',
  },
  text: {
    color: '#fff',
    padding: '4px 0',
    background: 'linear-gradient(201deg, #129EDE, #FBD1F4)',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    '&:hover': {
      backgroundColor: '#CEF261',
      color: '#000',
    },
  },
}));

export default function HomeCard(props) {
  const classes = useStyles();
  const marginBlock = 1;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickSwal = () => {
    Swal.fire('Coming Soon!', 'Stay Tuned!', 'info').then(() => {});
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container item justify="center" xs={6} sm={6} md={4}>
      <Box borderRadius={16} m={marginBlock} className={classes.box}>
        {props.to && !props.enableDialog && (
          <CardActionArea
            component={Link}
            to={props.to}
            className={classes.CardActionArea}
          >
            <CardMedia className={classes.media} image={props.image} />
          </CardActionArea>
        )}
        {props.to && props.enableDialog && (
          <CardActionArea
            onClick={handleClickOpen}
            className={classes.CardActionArea}
          >
            <CardMedia className={classes.media} image={props.image} />
          </CardActionArea>
        )}
        {props.href && (
          <CardActionArea
            component={LinkMaterial}
            className={classes.CardActionArea}
            href={props.href}
            target="_blank"
            rel="noopener"
          >
            <CardMedia className={classes.media} image={props.image} />
          </CardActionArea>
        )}
        {props.swal && (
          <CardActionArea
            onClick={handleClickSwal}
            className={classes.CardActionArea}
          >
            <CardMedia className={classes.media} image={props.image} />
          </CardActionArea>
        )}
        {props.isLoading ? (
          <CircularProgress />
        ) : (
          <Typography
            className={classes.text}
            gutterBottom
            variant="h6"
            component="h3"
          >
            {props.text}
          </Typography>
        )}
      </Box>
      <DialogSignUpSignIn open={open} handleClose={handleClose} />
    </Grid>
  );
}
