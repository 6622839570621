import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    maxHeight: '15vh',
    display: 'flex',
    borderRadius: theme.spacing(4),
    textDecoration: 'none',
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.up(600)]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  typoTitle: {
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
  typoSubsTitle: {
    fontSize: '0.6rem',
    fontWeight: 900,
    color: theme.palette.secondary.main,
  },
  typoSubs: {
    fontSize: '0.6rem',
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
}));

export default function CoachCelebCard(props) {
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
      component={Link}
      to={{
        pathname: '/mentor/profile',
        coachCeleb: props.coachCeleb,
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            alt="Remy Sharp"
            src={props.image}
            className={classes.avatar}
          />
        }
        title={
          <>
            <Typography className={classes.typoTitle} align="left">
              {props.name}
            </Typography>
            <Typography
              className={classes.typoSubsTitle}
              align="left"
              variant="subtitle1"
            >
              {props.title}
            </Typography>
            <Typography
              className={classes.typoSubs}
              align="left"
              variant="subtitle1"
            >
              {props.tags}
            </Typography>
          </>
        }
      />
    </Card>
  );
}
