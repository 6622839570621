import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Utils from '../shared/Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  table: {
    minWidth: 400,
  },
  list: {
    maxWidth: 400,
    margin: 'auto',
  },
}));

const AttendedDatesTable = ({ attendance }) => {
  const classes = useStyles();

  const dateCountMap = attendance.reduce((acc, attendance) => {
    const dateKey = attendance.date;
    acc[dateKey] = (acc[dateKey] || 0) + 1; // Increment count for each occurrence of the date
    return acc;
  }, {});

  const dateArray = Object.keys(dateCountMap);

  return (
    <TableContainer component={Paper} className={classes.list}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dateArray.map((date, index) => (
            <TableRow key={date}>
              <TableCell>{Utils.getDateUIString(date)}</TableCell>
              <TableCell>{dateCountMap[date]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default AttendedDatesTable;
