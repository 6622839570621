/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-empty */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';
import Service from '../shared/Services';
import PageLoading from './PageLoading';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItemText: {
    fontSize: '0.7em', // Insert your required size
  },
}));

export default function ProgressReportTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    // Package Selection
    progress_reports: [],
  });

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    setOpen(true);
    // code to run on component mount
    axios
      .all([
        Service.getProgressReport(
          source,
          props.month,
          props.year,
          props.studentId
        ),
        Service.getSkillsMap(source),
      ])
      .then(
        axios.spread((progressReports, skillMap) => {
          const progress_reports = progressReports.data[0]
            ? progressReports.data[0].student_skill_set
            : [];

          skillMap.data.forEach((value) => {
            if (
              progress_reports.filter((e) => e.skill_set.id === value.id)
                .length > 0
            ) {
            } else {
              const skill_set = {
                id: value.id,
                skill_category: value.skill_category,
              };
              const student_skills = [];
              value.skills.forEach((value) => {
                student_skills.push({ scale: '0', skill: value });
              });

              const skillCatagory = {
                comment: '',
                skill_set,
                student_skills,
              };
              progress_reports.push(skillCatagory);
            }
          });
          setOpen(false);
          setValues((oldValues) => ({
            ...oldValues,
            progress_reports,
          }));
        })
      );

    return function cleanup() {
      source.cancel('Component Closed');
    };
  }, [props.month, props.year, props.studentId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeComment = (event, index) => {
    const { progress_reports } = values;
    progress_reports[index].comment = event.target.value;
    setValues((oldValues) => ({
      ...oldValues,
      progress_reports,
    }));
  };

  const onClickProgress = (event) => {
    event.preventDefault();

    const student_skill_set = [];
    values.progress_reports.forEach((progress) => {
      const student_skills = [];

      progress.student_skills.forEach((student_skill) => {
        const skillScale = {
          scale: Number(student_skill.scale),
          skills: student_skill.skill.id,
        };
        student_skills.push(skillScale);
      });

      const student_skill = {
        comment: progress.comment ? progress.comment : 'NA',
        skill_set: progress.skill_set.id,
        student_skills,
      };
      student_skill_set.push(student_skill);
    });

    const progressObj = {
      student_user: props.studentId,
      branch_session: props.sessionId,
      user_subscription: props.userSubscriptionId,
      month_report: props.selectedDate,
      student_skill_set,
    };
    setOpen(true);
    Service.postCoachProgressReport(progressObj)
      .then((res) => {
        Swal.fire('Progress Report Submitted!', 'Thanks Coach!', 'success');
        // props.handleShowClasses();
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
      });
  };

  const handleChangeRating = (event, index) => {
    const indexInside = event.target.name.split('_')[1];
    const { progress_reports } = values;
    progress_reports[index].student_skills[indexInside].scale =
      event.target.value;

    setValues((oldValues) => ({
      ...oldValues,
      progress_reports,
    }));
  };

  return (
    <div className={classes.root}>
      <PageLoading isLoading={open} />
      {values.progress_reports === '' ||
      parseInt(values.progress_reports.length, 10) === 0 ? (
        <Typography gutterBottom>
          Progress Report not generated for this month.
        </Typography>
      ) : (
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            {values.progress_reports.map((progress, index) => (
              <Tab
                label={progress.skill_set.skill_category}
                key={progress.skill_set.id}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </AppBar>
      )}
      {values.progress_reports.map((progress, index) => {
        progress.student_skills.sort((a, b) =>
          a.skill.id > b.skill.id ? 1 : -1
        );
        return (
          // eslint-disable-next-line react/no-array-index-key
          <TabPanel value={value} index={index} key={index}>
            {progress.student_skills.map((student_skill, indexInside) => {
              const scaleString = `scale_${student_skill.scale}`.toString();
              const skillComment = student_skill.skill[scaleString];
              const nameOfRating = `${index}_${indexInside}`.toString();

              return (
                <Box
                  component="fieldset"
                  mb={3}
                  borderColor="transparent"
                  // eslint-disable-next-line react/no-array-index-key
                  key={indexInside}
                >
                  <Typography component="legend">
                    {student_skill.skill.skill_name}
                  </Typography>
                  <Rating
                    name={nameOfRating}
                    value={Number(student_skill.scale)}
                    onChange={(event, newValue) => {
                      handleChangeRating(event, index);
                    }}
                  />

                  <Tooltip
                    disableTouchListener
                    placement="right"
                    title={
                      <>
                        <List dense>
                          <ListItem>
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              primary={`1 : ${student_skill.skill.scale_1}`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              primary={`2 : ${student_skill.skill.scale_2}`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              primary={`3 : ${student_skill.skill.scale_3}`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              primary={`4 : ${student_skill.skill.scale_4}`}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              classes={{ primary: classes.listItemText }}
                              primary={`5 : ${student_skill.skill.scale_5}`}
                            />
                          </ListItem>
                        </List>
                      </>
                    }
                  >
                    <InfoIcon color="disabled" />
                  </Tooltip>
                  <Typography variant="subtitle2">{skillComment}</Typography>
                  <Divider light />
                </Box>
              );
            })}
            <Box component="fieldset" mb={3} borderColor="transparent">
              <TextField
                id="standard-read-only-input"
                label="Comments"
                multiline
                fullWidth
                defaultValue={progress.comment}
                onChange={(event) => {
                  handleChangeComment(event, index);
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickProgress}
            >
              Submit
            </Button>
          </TabPanel>
        );
      })}
    </div>
  );
}
