import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingBottom: '2px',
    paddingTop: '0px',
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: '0.2rem',
  },
}));

export default function BookingItemContact(props) {
  const classes = useStyles();
  const highlights = props.description.split('+');

  return (
    <div>
      <Grid item>
        <div className={classes.demo}>
          <List dense>
            {highlights.map((item) => (
              <ListItem key={item} className={classes.listItem}>
                <ListItemIcon className={classes.icon}>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
    </div>
  );
}
