import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MyBookings from '@material-ui/icons/Assignment';
import Web from '@material-ui/icons/Web';
import Portrait from '@material-ui/icons/Portrait';
import HowToReg from '@material-ui/icons/HowToReg';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import { connect } from 'react-redux';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import AcademyIcon from '@material-ui/icons/SportsCricket';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    background: 'linear-gradient(201deg, #129EDE, #FBD1F4)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'black',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  media: {
    margin: 1,
    width: 180,
    height: 120,
    backgroundSize: 'contain',
  },
  mediaLogo: {
    width: 120,
    height: 40,
  },
  icon: {
    color: '#227cbe',
  },
  partner: {
    marginLeft: 'auto',
  },
}));

function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <CardMedia
            className={classes.mediaLogo}
            image={require('../static/images/SKonnect-AMS.png')}
            title="SportsKingdom"
          />
          {!open && (
            <Button
              component="a"
              raised="true"
              className={classes.partner}
              color="inherit"
              href="https://www.sportskingdom.io/contact"
              target="_blank"
              rel="noopener"
            >
              Partner with us
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <CardMedia
            className={classes.media}
            image={require('../static/images/SKonnect-AMS.png')}
            title="Sportskingdom"
            component={Link}
            to="/"
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List onClick={handleDrawerClose}>
          <ListItem button key="Home" component={Link} to="/">
            <ListItemIcon>
              <HomeOutlined className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button key="Academy" component={Link} to={props.academy_to}>
            <ListItemIcon>
              <AcademyIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Academy" />
          </ListItem>
        </List>
        <Divider />
        {props.isLoggedUser && (
          <List onClick={handleDrawerClose}>
            <ListItem button key="Profile" component={Link} to="/profile">
              <ListItemIcon>
                <HowToReg className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
            <ListItem button key="mybookings" component={Link} to="/mybookings">
              <ListItemIcon>
                <MyBookings className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="MyBookings" />
            </ListItem>
            <ListItem
              button
              key="Sign Out"
              component={Link}
              to={{ pathname: '/signin', value: 'logout' }}
            >
              <ListItemIcon>
                <Portrait className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </List>
        )}
        {!props.isLoggedUser && (
          <List onClick={handleDrawerClose}>
            <ListItem button key="Sign In" component={Link} to="/signin">
              <ListItemIcon>
                <Portrait className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </ListItem>
            <ListItem button key="Sign Up" component={Link} to="/signup">
              <ListItemIcon>
                <HowToReg className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Sign Up" />
            </ListItem>
          </List>
        )}
        <Divider />
        <List onClick={handleDrawerClose}>
          <ListItem
            component="a"
            button
            key="Futbolkingdom"
            href="https://www.futbolkingdom.io/"
            target="_blank"
            rel="noopener"
          >
            <ListItemIcon>
              <Web className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="FutbolKingdom" />
          </ListItem>
          <ListItem
            component="a"
            button
            key="CricKingdom"
            href="https://crickingdom.com/"
            target="_blank"
            rel="noopener"
          >
            <ListItemIcon>
              <Web className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="CricKingdom" />
          </ListItem>
          <ListItem
            component="a"
            button
            key="SportsKingdom"
            href="https://www.sportskingdom.io/"
            target="_blank"
            rel="noopener"
          >
            <ListItemIcon>
              <Web className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="SportsKingdom" />
          </ListItem>
          {/* <ListItem component={Link} button key='Partner with us' to="/partner">
            <ListItemIcon><Web className={classes.icon} /></ListItemIcon>
            <ListItemText primary='Partner with us' />
          </ListItem> */}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    academy_to: state.to,
  };
}

export default connect(mapStateToProps)(PersistentDrawerLeft);
