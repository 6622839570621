import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Utils from '../../../shared/Utils';
import BookingSummary from '../components/BookingSummary';
import UserSummary from '../components/UserSummary';

const useStyles = makeStyles((theme) => ({
  top: {
    marginTop: theme.spacing(3),
    maxWidth: '50vh',

    alignContent: 'center',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: '5rem',
    paddingRight: '2px',
    paddingLeft: '4px',
  },
}));
export default function Step3(props) {
  const classes = useStyles();
  const campCategory = Utils.getItemByIDFromArray(
    props.parentProps.camp.one_time_batch,
    props.parentProps.campCategorySelected
  );
  const { camp } = props.parentProps;

  return (
    <div>
      <Grid className={classes.top}>
        <BookingSummary program={camp} category={campCategory} />
      </Grid>
      <Grid className={classes.top}>
        <UserSummary
          personalDetails={props.personalDetails}
          program={camp}
          category={campCategory}
        />
      </Grid>
      <Grid className={classes.top}>
        <CheckCircle className={classes.icon} />
      </Grid>
    </div>
  );
}
