import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Today from '@material-ui/icons/Today';
import Schedule from '@material-ui/icons/Schedule';
import AttachMoney from '@material-ui/icons/AttachMoney';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LocationOn from '@material-ui/icons/LocationOn';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import AccountBalance from '@material-ui/icons/AccountBalance';
import Payment from '@material-ui/icons/Payment';
import Utils from '../shared/Utils';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    textAlign: 'left',
    margin: 15,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  posEnd: {
    marginBottom: 12,
    marginTop: 10,
    fontWeight: 'bold',
  },
  listItem: {
    padding: theme.spacing(0),
  },
}));

const getDay = (day) => {
  const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  return days[day - 1];
};

export default function PackageSummaryNotPaid(props) {
  const classes = useStyles();
  const regFee = `Registration fee : ${props.currency} ${props.regFee}`;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography gutterBottom variant="h6">
          {props.season} {props.currency} {props.cost}
        </Typography>
        <List>
          <ListItem className={classes.listItem} key={props.name}>
            <ListItemIcon>
              <Today />{' '}
            </ListItemIcon>
            <ListItemText primary={props.name} />
          </ListItem>
          {props.sessionDetailsList.map((session) => {
            const text = `${getDay(session.session_day)} ${Utils.get12HFrom24H(
              session.start_time
            )}-${Utils.get12HFrom24H(session.end_time)}`;
            return (
              <ListItem key={session.id} className={classes.listItem}>
                <ListItemIcon>
                  <Schedule />{' '}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
          <ListItem className={classes.listItem} key={regFee}>
            <ListItemIcon>
              <AttachMoney />{' '}
            </ListItemIcon>
            <ListItemText primary={regFee} />
          </ListItem>
        </List>

        <Divider />
        <Typography className={classes.posEnd} component="div">
          Total fee payable : {props.currency} {props.cost + props.regFee}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          Please transfer the fee to the below account
        </Typography>
        <Divider />
        <List>
          {props.branch.upi_qr_path && (
            <ListItem
              className={classes.listItem}
              key={props.branch.upi_qr_path}
            >
              <ListItemIcon>
                <Payment />{' '}
              </ListItemIcon>
              <Box>
                <img
                  src={props.branch.upi_qr_path}
                  alt="QR Code"
                  style={{ maxWidth: '50%', height: 'auto' }}
                />
              </Box>
            </ListItem>
          )}
          <ListItem className={classes.listItem} key={props.branch.account}>
            <ListItemIcon>
              <AccountBalance />{' '}
            </ListItemIcon>
            <ListItemText primary={props.branch.bank_account_details} />
          </ListItem>
          <ListItem className={classes.listItem} key={props.branch.name}>
            <ListItemIcon>
              <LocationOn />{' '}
            </ListItemIcon>
            <ListItemText
              primary={props.branch.name}
              secondary={props.branch.email}
            />
          </ListItem>
          <ListItem
            className={classes.listItem}
            key={props.branch.branch_manager}
          >
            <ListItemIcon>
              <SupervisorAccount />{' '}
            </ListItemIcon>
            <ListItemText
              primary={props.branch.branch_manager}
              secondary={props.branch.contact_number}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
