import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
// const useStyles = makeStyles(theme => ({
const ButtonDefault = withStyles({
  root: {
    background: '#ed1c24',
    borderRadius: 16,
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export default ButtonDefault;
