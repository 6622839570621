import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialog: {
    textAlign: 'center',
  },
  appBar: {
    position: 'relative',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
}));

export default function PackagesFloating(prop) {
  const classes = useStyles();

  return (
    <div>
      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        className={classes.fab}
        component={Link}
        to="/player/register"
      >
        <AddIcon /> RENEW
      </Fab>
    </div>
  );
}
