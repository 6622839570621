import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Box from '@material-ui/core/Box';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import CardHeader from '@material-ui/core/CardHeader';
import Utils from '../../../shared/Utils';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2),
    textDecoration: 'none',
  },
  cardHeader: {
    padding: theme.spacing(1),
  },
  listItem: {
    padding: theme.spacing(0),
  },
  title: {
    fontWeight: 1000,
    color: theme.palette.secondary.light,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: '1.5rem',
    paddingRight: '2px',
    paddingLeft: '4px',
  },
}));

export default function CategoryCard(props) {
  const classes = useStyles();
  const { category } = props;

  return (
    <Card className={classes.card}>
      <Box bgcolor="secondary.main">
        <Typography className={classes.title}>
          {category.description}
        </Typography>
      </Box>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Typography>
            {props.camp.academy.city.country.currency} {category.cost}
          </Typography>
        }
        title={
          <>
            <CalendarTodayOutlinedIcon className={classes.icon} />{' '}
            {Utils.getDateUIStringNoYear(category.start_date)} -{' '}
            {Utils.getDateUIStringNoYear(category.end_date)}
          </>
        }
        subheader={
          <>
            <QueryBuilderOutlinedIcon className={classes.icon} />{' '}
            {Utils.get12HFrom24H(category.start_time)} -{' '}
            {Utils.get12HFrom24H(category.end_time)} {props.camp.time_zone}{' '}
          </>
        }
      />
    </Card>
  );
}
