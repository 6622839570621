import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import jwtDecodeLib from 'jwt-decode';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Utils from '../../../shared/Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function BookingStepper(props) {
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [values, setValues] = React.useState({
    firstName: '',
    email: '',
    mobile: '',
    parentName: '',
    age: '',
    cognito_id: '',
  });

  useEffect(() => {
    let jwtData = '';
    try {
      jwtData = jwtDecodeLib(localStorage.getItem('JWT_TOKEN'));
      setValues((oldValues) => ({
        ...oldValues,
        firstName: jwtData ? jwtData.given_name : '',
        email: jwtData ? jwtData.email : '',
        mobile: jwtData ? jwtData.phone_number : '',
        parentName: '',
        age: Utils.getAgeFromDate(jwtData.birthdate),
        cognito_id: jwtData.sub,
      }));
    } catch (error) {
      // empty
    }
  }, []);

  const handleChange = (e) => {
    e.persist();
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeMobile = (value) => {
    setValues((oldValues) => ({
      ...oldValues,
      mobile: value,
    }));
  };

  function getStepContent(stepIndex, props, handleNext, steps) {
    switch (stepIndex) {
      case 0:
        return <Step1 parentProps={props} handleNext={handleNext} />;
      case 1:
        return (
          <Step2
            parentProps={props}
            handleNext={handleNext}
            personalDetails={values}
            handleChange={handleChange}
            handleChangeMobile={handleChangeMobile}
          />
        );
      case 2:
        return (
          <Step3
            personalDetails={values}
            parentProps={props}
            handleNext={handleNext}
          />
        );
      case 3:
        return <Step4 personalDetails={values} parentProps={props} />;
      default:
        return 'Unknown stepIndex';
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      history.goBack();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // eslint-disable-next-line consistent-return
  const renderRedirectSignIn = () => {
    if (!props.isLoggedUser && activeStep === 1) {
      return <Redirect to="/signin" />;
    }
  };

  // eslint-disable-next-line consistent-return
  const renderRedirectHome = () => {
    if (activeStep === steps.length) {
      return <Redirect to="/" />;
    }
  };

  function getSteps() {
    return ['Select Batch', 'Confirm ', 'Pay', 'Confirmation'];
  }

  return (
    <div className={classes.root}>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep, props, handleNext, steps)}
            <div>
              {activeStep !== steps.length - 1 && (
                <Button onClick={handleBack} className={classes.backButton}>
                  Back
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Done' : 'Next'}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      {renderRedirectSignIn()}
      {renderRedirectHome()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
  };
}
export default connect(mapStateToProps)(BookingStepper);
