import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Service from '../shared/Services';
import BookingSummary from './booking/components/BookingSummary';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function MyBookings() {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    // Package Selection
    bookings: [],
  });

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // code to run on component mount
    Service.getBookings(source).then((res) => {
      const bookings = res.data.results;
      bookings.sort((a, b) => (a.booking_date > b.booking_date ? -1 : 1));

      setValues((oldValues) => ({
        ...oldValues,
        bookings,
      }));
    });

    return function cleanup() {
      source.cancel('Component Closed');
    };
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {values.bookings === '' ||
        parseInt(values.bookings.length, 10) === 0 ? (
          <Typography gutterBottom>
            You do not have any messages at this moment
          </Typography>
        ) : null}
        {values.bookings.map((booking) => (
          <BookingSummary
            key={booking.id}
            simple
            booking={booking}
            program={booking.program}
            category={booking.one_time_batch}
          />
        ))}
      </div>
    </Container>
  );
}
