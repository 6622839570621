import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Service from '../../shared/Services';
import Utils from '../../shared/Utils';
import PackageSummaryNotPaid from '../../components/PackageSummaryNotPaid';
import PackagesFloating from '../../components/PackagesFloating';
import PageLoading from '../../components/PageLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  paper: {
    maxWidth: 450,
  },
}));

export default function PlayerNotPaid() {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    // Package Selection
    packages: [],
    isLoading: true,
  });

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // code to run on component mount
    Service.getPackageDetails(source).then((res) => {
      let packages = res.data.filter((a) => !a.is_active);
      packages.sort((a, b) => (a.start_date > b.start_date ? -1 : 1));
      packages = packages.filter(
        (a) => Utils.getDatefromString(a.end_date) >= new Date()
      );
      setValues((oldValues) => ({
        ...oldValues,
        packages,
        isLoading: false,
      }));
    });
    return function cleanup() {
      source.cancel('Component Closed');
    };
  }, []);

  return (
    <div align="center">
      <Paper className={classes.paper}>
        {!values.isLoading && (
          <Typography variant="h5" component="h3">
            Welcome to {values.packages[0].branch.name}
          </Typography>
        )}
        <Typography component="p">
          To complete the registration process. Please make the payment to the
          below package to the mentioned account number. If you have any further
          questions, please contact the branch admin.
        </Typography>
        <PageLoading isLoading={values.isLoading} />
        {values.packages.map((packageObj) => (
          <PackageSummaryNotPaid
            name={packageObj.branch_packages.name}
            numberOfSession={packageObj.branch_session.length}
            cost={packageObj.package_cost}
            regFee={packageObj.registration_cost}
            currency={packageObj.currency}
            season={packageObj.package_season_type.season_type_name}
            sessionDetailsList={packageObj.branch_session}
            key={packageObj.id}
            branch={packageObj.branch}
          />
        ))}
        <PackagesFloating />
      </Paper>
    </div>
  );
}
