import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Utils from '../../../shared/Utils';
import Config from '../../../shared/Config';
import BookingSummary from '../components/BookingSummary';
import CheckoutForm from '../../../components/CheckoutForm';
import Button from '../../../components/ButtonDefault';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cover: {
    height: '100%',
    width: '100%',
    // backgroundSize: 'contain',
  },
  top: {
    marginTop: theme.spacing(3),
    maxWidth: '50vh',
    alignContent: 'center',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
  middle: {
    marginTop: theme.spacing(3),
    maxWidth: '60vh',
    alignContent: 'center',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
  bottom: {
    marginTop: theme.spacing(3),
    maxWidth: '60vh',
    alignContent: 'center',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
}));

function Step2(props) {
  const classes = useStyles();
  const campCategory = Utils.getItemByIDFromArray(
    props.parentProps.camp.one_time_batch,
    props.parentProps.campCategorySelected
  );
  const { camp } = props.parentProps;
  const stripePromise = loadStripe(Config.STRIPE_PUBLISH_SPORTSKINGDOM_SG);
  const { personalDetails } = props;

  const order = {
    amount: campCategory.cost * 100,
    currency: 'SGD',
    receipt_email: personalDetails.email,
    payment_method_types: 'card',
    statement_descriptor: camp.program_name.substring(0, 22),
    program: campCategory.program,
    one_time_batch: campCategory.id,
    cognito_id: personalDetails.cognito_id,
    player_name: personalDetails.firstName,
    parent_name: personalDetails.parentName,
    age: personalDetails.age,
    mobile: personalDetails.mobile,
    email: personalDetails.email,
  };

  const handleOnlinePayment = () => {
    handleRazorPay();
  };

  const handleRazorPay = () => {
    // get package season from season_type_cost[array] with id as values.packageSeasonIdSelected
    const options = {
      key: Config.RAZORPAY_KEY_SK,
      amount: campCategory.cost * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise or INR 500.
      currency: Config.INR,
      name: camp.program_name,
      description: `${camp.program_name}-${campCategory.description}-${camp.academy.name}`,
      image: require('../../../static/images/logo.png'),
      // "order_id": "order_9A33XWu170gUtm",// We are not using order id, Instead we use data in notes to validate the subscription
      handler(response) {
        props.handleNext();
      },
      prefill: {
        name: personalDetails.firstName,
        email: personalDetails.email,
        contact: personalDetails.mobile,
      },
      notes: {
        price: campCategory.cost * 100,
        program: campCategory.program,
        one_time_batch: campCategory.id,
        cognito_id: personalDetails.cognito_id,
        player_name: personalDetails.firstName,
        parent_name: personalDetails.parentName,
        age: personalDetails.age,
        mobile: personalDetails.mobile,
        email: personalDetails.email,
      },
      theme: { color: '#ed1c24' },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <Grid>
      <Grid className={classes.top}>
        <BookingSummary program={camp} category={campCategory} />
      </Grid>
      <Grid className={classes.middle}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="Player Full Name"
              disabled
              value={personalDetails.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="parentName"
              name="parentName"
              variant="outlined"
              required
              fullWidth
              id="parentName"
              label="Parent Name"
              disabled
              value={personalDetails.parentName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="age"
              name="age"
              variant="outlined"
              required
              fullWidth
              id="age"
              label="Age"
              disabled
              type="number"
              value={personalDetails.age}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="email"
              name="email"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              disabled
              value={personalDetails.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              country="sg"
              preferredCountries={['sg', 'in', 'de', 'qa', 'ae', 'jp']}
              name="mobile"
              value={personalDetails.mobile}
              onChange={props.handleChangeMobile}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.bottom}>
        <Typography variant="body1" gutterBottom>
          Please confirm your details before proceeding
        </Typography>
        {camp.academy.city.country.name === 'India' ? (
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleOnlinePayment}
            className={classes.submit}
          >
            Proceed
          </Button>
        ) : (
          <Elements stripe={stripePromise}>
            <CheckoutForm
              order={order}
              isbooking
              handleInputChange={props.handleNext}
            />
          </Elements>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    academy_to: state.to,
  };
}

export default connect(mapStateToProps)(Step2);
