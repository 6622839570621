/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-empty */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import axios from 'axios';
import Swal from 'sweetalert2';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Service from '../shared/Services';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '1rem',
    overflowX: 'hidden',
    height: '480px',
    paddingRight: '10px',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FitnessTestTabsCoach({ studentId, sessionId }) {
  const classes = useStyles();
  const [fitnessQns, SetFitnessQns] = React.useState([]);
  const [fitnessTest, SetFitnessTest] = React.useState([]);
  const [allTest, createAllTest] = React.useState([]);
  const [openedQns, setOpenedQns] = React.useState([]);
  // const [editMode, setEditMode] = React.useState(false);
  const [hideSubmitBtn, setHideSubmitBtn] = React.useState(false);
  const [btnShow, setBtnShow] = React.useState({});

  const submitTest = () => {
    let isValid = true;
    fitnessQns
      .filter((q) => q.is_optional === false)
      .forEach((q) => {
        const answerObj = openedQns.find(
          (qns) => qns.quaternaries_question === q.id
        );
        if (answerObj) {
          if (answerObj.text_answer && answerObj.text_answer.length > 0) {
            if (isValid) isValid = true;
          } else {
            isValid = false;
          }
        } else {
          const fitnessTestObj = fitnessTest.find(
            (qns) => qns.quaternaries_question === q.id
          );
          if (fitnessTestObj) {
            if (
              fitnessTestObj.text_answer &&
              fitnessTestObj.text_answer.length > 0
            ) {
              if (isValid) isValid = true;
            } else {
              isValid = false;
            }
          } else {
            isValid = false;
          }
        }
      });
    if (!isValid) {
      Swal.fire({
        icon: 'error',
        text: 'Please answer all compulsory questions',
      });
      return;
    }

    Service.createCoachFitnessTest(openedQns, studentId, sessionId)
      .then((res) => {
        createAllTest([res.data, ...allTest]);
        Swal.fire('Fitness test submitted successfully!');
        setHideSubmitBtn(true);
      })
      .catch((error) => {});
  };

  const handleUpdate = (questionId) => {
    const answerObject = fitnessTest.find(
      (q) => q.quaternaries_question === questionId
    );
    if (!answerObject) {
      Service.createCoachFitnessTest(openedQns, studentId, sessionId)
        .then((res) => {
          createAllTest([res.data, ...allTest]);
          Swal.fire('Fitness test submitted successfully!');
          setHideSubmitBtn(true);
        })
        .catch((error) => {});
    } else {
      const { polar_answer = false, text_answer } = openedQns.find(
        (q) => q.quaternaries_question === questionId
      );
      const { id } = fitnessTest.find(
        (q) => q.quaternaries_question === questionId
      );
      const updatedObj = { polar_answer, text_answer };
      // edit Qns
      Service.updateCoachFitnessTest(id, updatedObj)
        .then((res) => {
          const temp = allTest.filter((data) => data.id !== id);
          createAllTest([res.data, ...temp]);
          Swal.fire('Updated successfully!');
          setBtnShow(false);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    Service.getCoachFitnessTest(studentId, sessionId).then((res) => {
      SetFitnessTest(res.data);
    }, []);

    Service.getFitnessQns().then((res) => {
      SetFitnessQns(res.data);
    });
  }, [sessionId, studentId]);

  function upsert(array, element, qnsId) {
    const i = array.findIndex(
      (_element) => _element.quaternaries_question === qnsId
    );
    if (i > -1) array[i].text_answer = element;
    else array.push({ quaternaries_question: qnsId, text_answer: element });
    return array;
  }

  const handleChange = (e, qnsId) => {
    e.persist();
    const newObj = [...openedQns];
    const newObject = upsert(newObj, e.target.value, qnsId);
    setOpenedQns(newObject);
    setBtnShow((prev) => ({
      ...prev,
      [qnsId]: true && fitnessTest && fitnessTest.length > 0,
    }));
  };

  return (
    <div className={classes.root}>
      {fitnessQns.map((qns) => (
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <CheckCircleIcon style={{ color: 'green' }} />
          </Grid>
          <Grid item xs={11}>
            <Typography
              variant="subtitle2"
              style={{ textAlign: 'left', marginBottom: '0.7rem' }}
            >
              {qns.question}
            </Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              fullWidth
              variant="outlined"
              name={qns.id}
              rows={4}
              required
              value={
                openedQns?.find(
                  (ftans) => ftans.quaternaries_question === qns.id
                )
                  ? openedQns?.find(
                      (ftans) => ftans.quaternaries_question === qns.id
                    ).text_answer || ''
                  : fitnessTest.find(
                      (ftans) => ftans.quaternaries_question === qns.id
                    )?.text_answer
              }
              placeholder="Specify Reasons"
              onChange={(e) => handleChange(e, qns.id)}
            />
            {btnShow[qns.id] && (
              <div style={{ textAlign: 'left', margin: '0.5rem 0' }}>
                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  style={{ marginRight: '1rem' }}
                  color="primary"
                  onClick={() => handleUpdate(qns.id)}
                >
                  Update
                </Button>
                <Button
                  disableElevation
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() =>
                    setBtnShow((prev) => ({ ...prev, [qns.id]: false }))
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      ))}
      {hideSubmitBtn || fitnessTest.length > 0 ? (
        ''
      ) : (
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: '2rem' }}
          onClick={() => submitTest()}
        >
          Submit
        </Button>
      )}

      <Typography variant="body2" style={{ color: '#ccc', marginTop: '1rem' }}>
        NOTE: All fitness assessments are carried out by their respective
        coaches
      </Typography>
    </div>
  );
}
