import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import {
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { format } from 'date-fns';
import Service from '../../../shared/Services';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '1rem',
    overflowX: 'hidden',
    height: '480px',
    paddingRight: '10px',
    backgroundColor: theme.palette.background.paper,
  },
  date: {
    padding: '1rem',
    textAlign: 'right',
    color: 'darkgrey',
  },
}));

export default function PlayerMedicalQns() {
  const classes = useStyles();
  const [MedicalQns, SetMedicalQns] = React.useState([]);
  const [MedicalTest, SetMedicalTest] = React.useState(null);
  const [allTest, createAllTest] = React.useState([]);
  const [openedQns, setOpenedQns] = React.useState([]);
  // const [editMode, setEditMode] = React.useState(false);
  const [hideSubmitBtn, setHideSubmitBtn] = React.useState(false);
  const [btnShow, setBtnShow] = React.useState({});

  const submitTest = () => {
    let isValid = true;
    MedicalQns.filter((q) => q.is_optional === false).forEach((q) => {
      const answerObj = openedQns.find(
        (qns) => qns.quaternaries_question === q.id
      );
      if (answerObj) {
        if (answerObj.text_answer && answerObj.text_answer.length > 0) {
          if (isValid) isValid = true;
        } else {
          isValid = false;
        }
        if (q.is_polar_question) {
          if (answerObj.polar_answer && answerObj.polar_answer !== null) {
            if (isValid) isValid = true;
          } else {
            isValid = false;
          }
        }
      } else {
        const fitnessTestObj = MedicalTest.find(
          (qns) => qns.quaternaries_question === q.id
        );
        if (fitnessTestObj) {
          if (
            fitnessTestObj.text_answer &&
            fitnessTestObj.text_answer.length > 0
          ) {
            if (isValid) isValid = true;
          } else {
            isValid = false;
          }
          if (q.is_polar_question) {
            if (
              fitnessTestObj.polar_answer &&
              fitnessTestObj.polar_answer !== null
            ) {
              if (isValid) isValid = true;
            } else {
              isValid = false;
            }
          }
        } else {
          isValid = false;
        }
      }
    });
    if (!isValid) {
      Swal.fire({
        icon: 'error',
        text: 'Please answer all compulsory questions',
      });
      return;
    }
    if (MedicalQns.is_optional !== false) {
      Service.createPlayerMedicalTest(openedQns)
        .then((res) => {
          createAllTest([res.data, ...allTest]);
          Swal.fire('Medical qns submitted successfully!');
          setHideSubmitBtn(true);
        })
        .catch((error) => {});
    }
  };

  const handleUpdate = (questionId) => {
    const answerObject = MedicalTest.find(
      (q) => q.quaternaries_question === questionId
    );
    if (!answerObject) {
      Service.createPlayerMedicalTest(openedQns)
        .then((res) => {
          createAllTest([res.data, ...allTest]);
          Swal.fire('Medical Qns submitted successfully!');
          setHideSubmitBtn(true);
        })
        .catch((error) => {});
    } else {
      // eslint-disable-next-line camelcase
      const { polar_answer = false, text_answer } = openedQns.find(
        (q) => q.quaternaries_question === questionId
      );
      const { id } = MedicalTest.find(
        (q) => q.quaternaries_question === questionId
      );
      const updatedObj = { polar_answer, text_answer };
      // edit Qns
      Service.updatePlayerMedicalTest(id, updatedObj)
        .then((res) => {
          const temp = allTest.filter((data) => data.id !== id);
          createAllTest([res.data, ...temp]);
          Swal.fire('Updated successfully!');
          setBtnShow(false);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    Service.getPlayerMedicalTest().then((res) => {
      SetMedicalTest(res.data);
    }, []);

    Service.getMedicalQns().then((res) => {
      SetMedicalQns(res.data);
    });
  }, []);

  function upsert(array, element, qnsId, updateText, otherAns) {
    const i = array.findIndex(
      (_element) => _element.quaternaries_question === qnsId
    );
    if (updateText) {
      if (i > -1) {
        array[i].text_answer = element;
      } else
        array.push({
          quaternaries_question: qnsId,
          text_answer: element,
          polar_answer: otherAns,
        });
    } else if (i > -1) {
      array[i].polar_answer = element;
    } else
      array.push({
        quaternaries_question: qnsId,
        polar_answer: element,
        text_answer: otherAns,
      });

    return array;
  }

  const handleChange = (e, qnsId) => {
    e.persist();
    const newObj = [...openedQns];
    const otherAns = MedicalTest.find((q) => q.quaternaries_question === qnsId)
      ? MedicalTest.find((q) => q.quaternaries_question === qnsId).polar_answer
      : null;
    const newObject = upsert(newObj, e.target.value, qnsId, true, otherAns);
    setOpenedQns(newObject);
    setBtnShow((prev) => ({
      ...prev,
      [qnsId]: true && MedicalTest && MedicalTest.length > 0,
    }));
  };

  const handlePolarAnswerChange = (e, qnsId) => {
    e.persist();
    const newObj = [...openedQns];
    const otherAns = MedicalTest.find((q) => q.quaternaries_question === qnsId)
      ? MedicalTest.find((q) => q.quaternaries_question === qnsId).text_answer
      : '';
    const newObject = upsert(newObj, e.target.value, qnsId, false, otherAns);
    setOpenedQns(newObject);
    setBtnShow((prev) => ({
      ...prev,
      [qnsId]: true && MedicalTest && MedicalTest.length > 0,
    }));
  };

  if ((MedicalQns && MedicalQns.length === 0) || !MedicalTest) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.date}>
        {format(new Date(), 'dd-MMMM-yyyy')}
      </Typography>
      {MedicalQns.map((qns) => (
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <CheckCircleIcon style={{ color: 'green' }} />
          </Grid>
          <Grid item xs={11}>
            <Typography
              variant="subtitle2"
              style={{ textAlign: 'left', marginBottom: '0.7rem' }}
            >
              {qns.question}
            </Typography>
            {qns.is_polar_question === true ? (
              <FormControl style={{ float: 'left' }}>
                <RadioGroup
                  required
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => handlePolarAnswerChange(e, qns.id)}
                  value={
                    openedQns
                      ?.find((ftans) => ftans.quaternaries_question === qns.id)
                      ?.polar_answer?.toString() ||
                    MedicalTest?.find(
                      (ftans) => ftans.quaternaries_question === qns.id
                    )?.polar_answer?.toString()
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            ) : (
              ''
            )}
            {qns.is_text_answer_possible === true ? (
              <TextField
                id="outlined-multiline-static"
                multiline
                fullWidth
                variant="outlined"
                rows={4}
                name={qns.id}
                required
                value={
                  openedQns?.find(
                    (ftans) => ftans.quaternaries_question === qns.id
                  )
                    ? openedQns?.find(
                        (ftans) => ftans.quaternaries_question === qns.id
                      ).text_answer || ''
                    : MedicalTest?.find(
                        (ftans) => ftans.quaternaries_question === qns.id
                      )?.text_answer
                }
                placeholder="Specify Reasons"
                onChange={(e) => handleChange(e, qns.id)}
              />
            ) : (
              ''
            )}
            {btnShow[qns.id] && (
              <div style={{ textAlign: 'left', margin: '0.5rem 0' }}>
                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  style={{ marginRight: '1rem' }}
                  color="primary"
                  onClick={() => handleUpdate(qns.id)}
                >
                  Update
                </Button>
                <Button
                  disableElevation
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() =>
                    setBtnShow((prev) => ({ ...prev, [qns.id]: false }))
                  }
                >
                  Cancel
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      ))}
      {hideSubmitBtn || MedicalTest?.length > 0 ? (
        ''
      ) : (
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: '2rem' }}
          onClick={() => submitTest()}
        >
          Submit
        </Button>
      )}

      <Typography variant="body2" style={{ color: '#ccc', marginTop: '1rem' }}>
        NOTE: If you have answered &apos;Yes&apos; to one or more of the above
        questions, consult your ohysician before engaging in any physical
        activity.
      </Typography>
    </div>
  );
}
