import React, { useEffect } from 'react';
import { List } from '@material-ui/core';
import axios from 'axios';
import Service from '../../shared/Services';
import PageLoading from '../../components/PageLoading';
import StudentListAttendance from '../../components/StudentListAttendance';
import Utils from '../../shared/Utils';

const FlexAttendance = (props) => {
  const [values, setValues] = React.useState({
    selectedOption: 0,
    isLoading: true,
    currentDate: Utils.formatDate(new Date()),
    packageMap: {
      0: 'All',
      // 1: '20 sessions for 100$',
      // 2: 'Monthly',
    },
    totalList: [],
    filteredList: [],
    classObj: {
      students: [],
    },
  });

  // const handleDropdownChange = (event) => {
  //   // filter the list based on  event.target.value,
  //   setValues((oldValues) => ({
  //     ...oldValues,
  //     selectedOption: event.target.value,
  //   }));
  // };

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // code to run on component mount
    // Change to new API props.type
    // Get the package id and create a map with list of students
    Service.getAttendanceFlex(source, props.type).then((res) => {
      const att = res.data;
      setValues((oldValues) => ({
        ...oldValues,
        isLoading: false,
        classObj: {
          students: att,
        },
      }));
    });

    return function cleanup() {
      source.cancel('Component Closed');
    };
  }, [props.type]);

  return (
    <div>
      <PageLoading isLoading={values.isLoading} />
      {/* <FormControl>
        <Select value={values.selectedOption} onChange={handleDropdownChange}>
          {Object.keys(values.packageMap).map((key) => (
            <MenuItem key={key} value={parseInt(key, 10)}>
              {values.packageMap[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <div>
        <List>
          {values.classObj.students.length > 0 && (
            <StudentListAttendance
              type={props.type}
              selectedDate={values.currentDate}
              classObj={values.classObj}
            />
          )}
        </List>
      </div>
    </div>
  );
};

export default FlexAttendance;
