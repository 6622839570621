import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 345,
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2),
    textDecoration: 'none',
  },
  cardHeader: {
    padding: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(0),
  },
  listItem: {
    padding: theme.spacing(0),
  },
  title: {
    fontWeight: 1000,
    color: theme.palette.secondary.light,
  },
  subTitle: {
    color: theme.palette.secondary.light,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: '1.5rem',
    paddingRight: '2px',
    paddingLeft: '4px',
  },
  dialog: {
    borderRadius: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.secondary.light,
    marginBottom: theme.spacing(4),
  },
  titleDialog: {
    marginTop: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}));

export default function UserSummary(props) {
  const classes = useStyles();
  const { personalDetails } = props;

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom>{personalDetails.firstName}</Typography>
          <Typography gutterBottom>{personalDetails.email}</Typography>
          <Typography gutterBottom>{personalDetails.mobile}</Typography>
          <Divider className={classes.divider} />
          <Typography gutterBottom>
            Thank you for booking. Your registration has been successful. We
            hope that you have an enriching learning experience.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
