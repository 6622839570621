import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Service from '../../../shared/Services';
import PlayerPackages from '../../../components/PlayerPackages';
import PackageSummaryNotPaid from '../../../components/PackageSummaryNotPaid';
import TabPanel from '../../../components/TabPanel';
import Utils from '../../../shared/Utils';
import PageLoading from '../../../components/PageLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function Packages() {
  const classes = useStyles();
  const states = ['Active', 'Past', 'Pending'];

  const [values, setValues] = React.useState({
    // Package Selection
    packageState: 0,
    packageArray: [],
    isLoading: true,
  });

  const handleChangePackageState = (event, newValue) => {
    setValues((oldValues) => ({
      ...oldValues,
      packageState: newValue,
    }));
  };

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // code to run on component mount
    Service.getPackageDetails(source).then((res) => {
      const packages = res.data.filter((a) => a.is_active);
      packages.sort((a, b) => (a.start_date > b.start_date ? -1 : 1));
      const activePackages = packages.filter(
        (a) => Utils.getDatefromString(a.end_date) >= new Date()
      );
      const pastPackages = packages.filter(
        (a) => Utils.getDatefromString(a.end_date) < new Date()
      );

      let pendingPackages = res.data.filter((a) => !a.is_active);
      pendingPackages = pendingPackages.filter(
        (a) => Utils.getDatefromString(a.end_date) >= new Date()
      );
      pendingPackages.sort((a, b) => (a.start_date > b.start_date ? -1 : 1));

      const packageArray = [activePackages, pastPackages, pendingPackages];
      setValues((oldValues) => ({
        ...oldValues,
        packageArray,
        isLoading: false,
      }));
    });

    return function cleanup() {
      source.cancel('Component Closed');
    };
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={values.packageState}
          onChange={handleChangePackageState}
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="primary"
        >
          {states.map((packageState, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tab label={packageState} key={index} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      <PageLoading isLoading={values.isLoading} />
      {values.packageArray.map((diaplayPackages, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TabPanel value={values.packageState} index={index} key={index}>
          {diaplayPackages === '' ||
          parseInt(diaplayPackages.length, 10) === 0 ? (
            <Typography gutterBottom>You do not have any packages</Typography>
          ) : null}
          {index !== 2 &&
            diaplayPackages.map((packageObj) => (
              <PlayerPackages key={packageObj.id} packageObj={packageObj} />
            ))}
          {index === 2 &&
            diaplayPackages.map((packageObj) => (
              <PackageSummaryNotPaid
                name={packageObj.branch_packages.name}
                numberOfSession={packageObj.branch_session.length}
                cost={packageObj.package_cost}
                regFee={packageObj.registration_cost}
                currency={packageObj.currency}
                season={packageObj.package_season_type.season_type_name}
                sessionDetailsList={packageObj.branch_session}
                key={packageObj.id}
                branch={packageObj.branch}
              />
            ))}
        </TabPanel>
      ))}
    </div>
  );
}
