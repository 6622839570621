import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import React from 'react';
import Facebook from '@material-ui/icons/Facebook';
import Config from '../shared/Config';

const ButtonFacebookHelper = withStyles({
  root: {
    background: '#14548f',
    borderRadius: 16,
    color: 'white',
    marginBottom: '40px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

export default function ButtonFacebook() {
  return (
    <ButtonFacebookHelper
      type="submit"
      href={Config.LOGIN_FB}
      startIcon={<Facebook />}
    >
      Login with Facebook
    </ButtonFacebookHelper>
  );
}
