import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import jwtDecodeLib from 'jwt-decode';
import Utils from '../../shared/Utils';
import MySnackbarContentWrapper from '../../components/MySnackbarContentWrapper';
import PackageSummary from '../../components/PackageSummary';
import BranchCard from '../../components/BranchCard';
import CheckoutForm from '../../components/CheckoutForm';
import PageLoading from '../../components/PageLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  link: {
    margin: theme.spacing(0.75),
  },
  title: {
    fontWeight: 1000,
  },
}));

function ReviewUpdate(props) {
  const classes = useStyles();
  const { values } = props;
  const branch = values.branches.find(
    (b) => b.id === parseInt(values.branch, 10)
  );
  const packageSelected = values.branchPackage.branch_package.find(
    (b) => b.id === parseInt(values.packageIdSelected, 10)
  );
  let season = packageSelected.season_type_cost.find(
    (s) => s.season_type_id === parseInt(values.packageSeasonIdSelected, 10)
  );
  if (season == null) {
    season = values.packageSelected.package_sessions;
  }

  const sessionDetailsList = values.branchSessionList.filter(
    (season) => values.sessionSelected.indexOf(String(season.id)) > -1
  );
  const country = values.countryObj;
  const includeRegistrationFee =
    props.academy_to !== '/player/active' && season.include_registration_fee;
  const registrationFee = includeRegistrationFee ? branch.registration_fee : 0;
  const jwtData = jwtDecodeLib(localStorage.getItem('JWT_TOKEN'));

  let seasonTypeId = values.packageSeasonIdSelected;

  if (seasonTypeId.startsWith('NA')) {
    seasonTypeId = '';
  }

  const order = {
    amount: (season.cost + registrationFee) * 100,
    currency: country.currency,
    receipt_email: jwtData.email,
    payment_method_types: 'card',
    statement_descriptor: branch.name.substring(0, 15),
    branch_id: branch.id,
    package_id: values.packageIdSelected,
    branch_session: `[${values.sessionSelected}]`,
    season_type_id: seasonTypeId,
    reg_fee_included: includeRegistrationFee,
    user_id: values.userid,
    cpid: country.country_payment_id,
  };
  const rules = 'https://www.sportskingdom.io/faqacademy';
  const terms = 'https://www.sportskingdom.io/termsacademy';

  let stripePublish = '';
  if (country.rootobj) {
    stripePublish = Utils.getStripePublish(country.rootobj, country.trunkobj);
  }
  const stripePromise = loadStripe(stripePublish);

  const message = `${values.postError}Or Contact ${branch.name} venue manager ${branch.branch_manager} at ${branch.contact_number} or ${branch.email}`;

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title} variant="h5" gutterBottom>
            Package Details
          </Typography>
          <PackageSummary
            socialLink={branch.social_network_url}
            name={packageSelected.name}
            numberOfSession={packageSelected.number_of_weekly_sessions}
            cost={season.cost}
            regFee={registrationFee}
            currency={country.currency}
            season={
              season.country_season_type != null
                ? season.country_season_type.season_type_name
                : `No of Session : ${season.number_of_sessions}`
            }
            sessionDetailsList={sessionDetailsList}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title} variant="h5" gutterBottom>
            Academy Details
          </Typography>
          <BranchCard
            branchLogo={branch.branch_logo_path}
            name={branch.name}
            address={branch.address}
            branch_manager={branch.branch_manager}
            email={branch.email}
            contact_number={branch.contact_number}
          />
        </Grid>
        {(!props.isLoggedUser || props.academy_to === '/player/register') && (
          <div>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Your Details
              </Typography>
              <Divider />
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="First Name" />
                  <Typography variant="body1">{values.firstName} </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Last Name" />
                  <Typography variant="body1">{values.lastName}</Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Email" />
                  <Typography variant="body1">{values.email} </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Phone" />
                  <Typography variant="body1">
                    {Utils.getMobilewithPlus(values.mobile)}{' '}
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            {!values.postError && (
              <Typography gutterBottom>
                Please confirm your package details and class timing before you
                proceed.
              </Typography>
            )}
          </div>
        )}
        {props.isLoggedUser && (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Payment Option</FormLabel>
              <RadioGroup
                aria-label="category"
                name="payment"
                value={values.payment}
                onChange={props.handleInputChange}
                row
              >
                {(country.name.toLowerCase() === 'india' ||
                  country.rootobj) && (
                  <Tooltip
                    title="Credit Card, Debit Card, Internet Banking, Payment Wallet etc"
                    aria-label="online"
                  >
                    <FormControlLabel
                      value="online"
                      control={<Radio color="primary" />}
                      label="Online"
                      labelPlacement="end"
                    />
                  </Tooltip>
                )}
                {!country.rootobj && (
                  <Tooltip title="Bank Transfer" aria-label="offline">
                    <FormControlLabel
                      value="offline"
                      control={<Radio color="primary" />}
                      label="Offline"
                      labelPlacement="end"
                    />
                  </Tooltip>
                )}
              </RadioGroup>
            </FormControl>
            {values.payment === 'online' && country.rootobj && (
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  order={order}
                  handleInputChange={props.handleInputChange}
                />
              </Elements>
            )}
          </Grid>
        )}
        <div>
          {' '}
          <Typography variant="subtitle2">
            By registering, you agree to our
            <Link
              className={classes.link}
              href={terms}
              target="_blank"
              rel="noopener"
            >
              Terms{' '}
            </Link>{' '}
            and
            <Link
              className={classes.link}
              href={rules}
              target="_blank"
              rel="noopener"
            >
              Rules{' '}
            </Link>
          </Typography>
        </div>
      </Grid>
      {values.postError && (
        <MySnackbarContentWrapper variant="error" message={message} />
      )}
      <PageLoading isLoading={values.isLoading} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    academy_to: state.to,
  };
}

export default connect(mapStateToProps)(ReviewUpdate);
