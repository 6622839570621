import React from 'react';
import 'date-fns';
import CoachClass from './CoachClass';

export default function ProgressReport() {
  return (
    <div>
      <CoachClass type="progress" />
    </div>
  );
}
