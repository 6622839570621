import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
// import Announcement from '@material-ui/icons/Announcement';
import BookIcon from '@material-ui/icons/Book';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import EventIcon from '@material-ui/icons/Event';
import { connect } from 'react-redux';
import FlagIcon from '@material-ui/icons/Flag';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

const useStyles = makeStyles((theme) => ({
  actionItem: {
    '&$selected': {
      background: 'aliceblue',
    },
  },
  selected: {},
  root: {
    height: 'auto',
    justifyContent: 'initial',
    overflow: 'auto',
    overflowWrap: 'initial',
    marginBottom: '1rem',
  },
}));

function MySportSubNavigation(prop) {
  const classes = useStyles();

  return (
    <BottomNavigation
      value={prop.value}
      onChange={prop.onChange}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Calendar"
        icon={<EventIcon />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
      {/* {prop.to !== '/coach' && (
        <BottomNavigationAction
          label="Messages"
          icon={<Announcement />}
          classes={{
            root: classes.actionItem,
            selected: classes.selected,
          }}
        />
      )} */}
      <BottomNavigationAction
        label="Diary"
        icon={<BookIcon />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
      <BottomNavigationAction
        label="Set Goals"
        icon={<FlagIcon />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
      <BottomNavigationAction
        label="Fitness Test"
        icon={<FitnessCenterIcon />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
      {prop.to !== '/coach' && (
        <BottomNavigationAction
          label="Feedback"
          icon={<AssignmentTurnedInIcon />}
          classes={{
            root: classes.actionItem,
            selected: classes.selected,
          }}
        />
      )}
      {prop.to === '/coach' && (
        <BottomNavigationAction
          label="Task"
          icon={<AssignmentIcon />}
          classes={{
            root: classes.actionItem,
            selected: classes.selected,
          }}
        />
      )}
      <BottomNavigationAction
        label="Medical Qns"
        icon={<LocalHospitalIcon />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
    </BottomNavigation>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    to: state.to,
  };
}

export default connect(mapStateToProps)(MySportSubNavigation);
