/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import axios from 'axios';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import {
  AppBar,
  InputLabel,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  IconButton,
  Grid,
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FlagIcon from '@material-ui/icons/Flag';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Button from '@material-ui/core/Button';
import Service from '../../../shared/Services';

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  accordion: {
    margin: '1rem 0',
    border: '1px solid #ccc',
    cursor: 'pointer',
    '&.MuiAccordion-root.Mui-expanded:last-child': {
      marginBottom: '1rem',
    },
  },
  date: {
    fontSize: '13px',
    textAlign: 'right',
  },
  goalCard: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    marginBottom: '1rem',
    padding: '1rem',
  },
  cardDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function PlayerGoals(props) {
  const classes = useStyles();
  const [quarter, setQuarter] = React.useState('JANUARY-MARCH');
  const [allGoals, setAllGoals] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [openedGoal, setOpenedGoal] = React.useState({});
  const [selectedSportId, setSelectedSportId] = React.useState(1);
  const [allUserSports, setAllUserSports] = React.useState([]);
  const [allQuarter, setAllQuarter] = React.useState([]);
  const [selectedGoalCategory, setSelectedGoalCategory] = React.useState('');

  const handleClickOpen = (categoryId) => {
    setSelectedGoalCategory(categoryId);
    setEditMode(false);
    setOpenedGoal({});
    setModal(true);
    setSelectedSportId();
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleGoalCheck = () => {
    setChecked((prvState) => !prvState);
  };

  const openGoal = (goal) => {
    setChecked(goal.status === 2);
    setEditMode(true);
    setOpenedGoal(goal);
    setModal(true);
  };

  const addGoal = () => {
    const { id, goal } = openedGoal;
    if (editMode) {
      // edit goals
      setModal(false);
      let goalStatus;
      if (checked === true) {
        goalStatus = 2;
      } else {
        goalStatus = 1;
      }
      Service.updateStudentGoals(id, goal, goalStatus)
        .then((res) => {
          const temp = allGoals.filter((data) => data.id !== id);
          setAllGoals([res.data, ...temp]);
          Swal.fire('Goal Updated Successfully!');
          setEditMode(false);
        })
        .catch((error) => {});
    } else {
      // add goals
      Service.createStudentGoal({
        goal,
        goal_category: selectedGoalCategory,
      })
        .then((res) => {
          setAllGoals([res.data, ...allGoals]);
        })
        .catch((error) => {});
      setModal(false);
      setEditMode(false);
    }
  };

  const deleteGoal = () => {
    const { id, goal } = openedGoal;
    // delete goals
    setModal(false);
    Service.deleteStudentGoals(id, goal, 0)
      .then((res) => {
        const temp = allGoals.filter((data) => data.id !== id);
        setAllGoals([res.data, ...temp]);
        Swal.fire('Goal Updated Successfully!');
        setEditMode(false);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    Service.getUserSports().then((res) => {
      setAllUserSports(res.data);
    });
    if (selectedSportId > 0) {
      Service.getAllQuarterBySport(selectedSportId).then((res) => {
        setAllQuarter(res.data);
      });
    }
  }, [selectedSportId]);

  useEffect(() => {
    Service.getStudentGoalList({
      goal_category: selectedGoalCategory,
      user: props.studentId,
    }).then((res) => {
      setAllGoals(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    e.persist();
    setOpenedGoal((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleQuarter = (e) => {
    setQuarter(e.target.value);
  };

  const handleTabChange = (e, sportId) => {
    setSelectedSportId(sportId);
  };

  const [expanded, setExpanded] = React.useState(false);
  const handleAccordionChange = (id) => (e, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedSportId}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable tabs"
        >
          {allUserSports.map((sport, index) => (
            <Tab
              label={sport.name}
              key={sport.id}
              value={sport.id}
              index={sport.id}
              {...a11yProps(sport.id)}
            />
          ))}
        </Tabs>
      </AppBar>
      <div fullWidth style={{ margin: '1rem' }}>
        <InputLabel id="demo-simple-select-label" style={{ textAlign: 'left' }}>
          Select Quarter
        </InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={quarter}
          label="Select Quarter"
          onChange={handleQuarter}
          variant="standard"
        >
          {allQuarter.map((q) => (
            <MenuItem value={q.quarter}>{q.quarter}</MenuItem>
          ))}
        </Select>
      </div>

      <div>
        {allQuarter
          .find((qrtr) => qrtr.quarter === quarter)
          ?.goal_category.map((q) => (
            <Accordion
              className={classes.accordion}
              elevation={0}
              onChange={handleAccordionChange(q.id)}
              expanded={expanded === q.id}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${q.name}-content`}
                id={`${q.name}-header`}
              >
                <FlagIcon style={{ marginRight: '1rem' }} />
                <Typography>{q.name}</Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: 'block',
                  maxHeight: '250px',
                  overflowY: 'scroll',
                }}
              >
                {allGoals
                  .filter((goal) => goal.goal_category === q.id)
                  .filter((goal) => goal.status !== 0).length > 0 ? (
                  allGoals
                    .filter((goal) => goal.goal_category === q.id)
                    .filter((goal) => goal.status !== 0)
                    .map((goal) => (
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          className={classes.goalCard}
                          onClick={() => openGoal(goal)}
                        >
                          <Box className={classes.cardDetails}>
                            <Typography variant="subtitle1">
                              <b>
                                {goal.user === goal.goal_set_by
                                  ? 'MY GOAL'
                                  : 'GOAL BY COACH'}
                              </b>
                            </Typography>
                            <IconButton fontSize="small">
                              {goal.status === 2 ? (
                                <CheckCircleOutlineIcon
                                  style={{ color: 'green' }}
                                />
                              ) : (
                                <HourglassEmptyIcon
                                  style={{ color: 'orange' }}
                                />
                              )}
                            </IconButton>
                          </Box>
                          <div style={{ overflowX: 'clip' }}>
                            <Typography
                              type="subtitle1"
                              style={{ textAlign: 'left' }}
                            >
                              {goal.goal}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    ))
                ) : (
                  <div>
                    <Typography style={{ color: '#bbb', textAlign: 'center' }}>
                      No goals added. Click on Add Goal button below.
                    </Typography>
                  </div>
                )}
              </AccordionDetails>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginBottom: '1rem' }}
                onClick={() => handleClickOpen(q.id)}
              >
                Add Goal
              </Button>
            </Accordion>
          ))}
        <Dialog fullWidth open={modal} onClose={handleClose}>
          <DialogTitle style={{ padding: '15px' }}>
            <Typography className={classes.date}>
              {format(new Date(), 'dd-MMMM-yyyy')}
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <TextField
              placeholder="Start writing here..."
              multiline
              name="goal"
              fullWidth
              rows={20}
              disabled={openedGoal.user !== openedGoal.goal_set_by}
              required
              value={openedGoal.goal}
              variant="standard"
              type="text"
              onChange={handleChange}
            />
            {openedGoal.user === openedGoal.goal_set_by ? (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={openedGoal.status === 2}
                    onChange={() => handleGoalCheck(editMode)}
                  />
                }
                label="Mark this goal as complete"
              />
            ) : null}
          </DialogContent>
          {openedGoal.user === openedGoal.goal_set_by ? (
            <DialogActions className={classes.dialogBtn}>
              {editMode ? (
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => deleteGoal(editMode)}
                >
                  Delete Goal
                </Button>
              ) : null}

              <Button
                onClick={() => addGoal(editMode)}
                color="secondary"
                variant="contained"
              >
                {editMode ? 'Update Goal' : 'Set Goal'}
              </Button>
            </DialogActions>
          ) : (
            <Typography style={{ paddingBottom: '1rem' }}>
              Student cannot change goal set by coach
            </Typography>
          )}
        </Dialog>
      </div>
    </div>
  );
}
