import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BranchCard from '../../components/BranchCard';
import PackageCard from '../../components/PackageCard';
import SessionCard from '../../components/SessionCard';
import SingleLineGridList from '../../components/SingleLineGridList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textFieldNumber: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 90,
  },
}));
function compare(a, b) {
  if (
    parseInt(a.number_of_weekly_sessions, 10) <
    parseInt(b.number_of_weekly_sessions, 10)
  ) {
    return -1;
  }
  return 1;
}

export default function BranchPackageSelection(props) {
  const [branchesForSport, setbranchesForSport] = useState([]);
  const { age } = props.values;
  const { country } = props.values;
  const { cities } = props.values;
  const { city } = props.values;
  const initCityObject = getCityObj();
  const { branch } = props.values;
  const { branchPackage } = props.values;
  const { centerCode } = props.values;
  const { sport } = props.values;
  let branchPackages = '';

  useEffect(() => {
    setbranchesForSport(
      props.values.branches.filter(
        (branch) =>
          branch.sport.name === sport ||
          branch.sport.id === parseInt(sport, 10) ||
          sport === 'All'
      )
    );
  }, [props.values.branches, sport]);

  const onClickSport = (item) => {
    if (item === 'All') {
      setbranchesForSport(props.values.branches);
    } else {
      setbranchesForSport(
        props.values.branches.filter((branch) => branch.sport.name === item)
      );
    }
    props.handleInputChange(item, 'sport');
  };

  if (branchPackage !== '') {
    branchPackages = branchPackage.branch_package.filter(checkActiveSeason);
    branchPackages.sort(compare);
  }

  function checkActiveSeason(branchPackage) {
    let res = false;
    branchPackage.season_type_cost.forEach((packageSeason) => {
      if (packageSeason.is_active) {
        res = true;
      }
    });

    if (branchPackage.package_sessions && branchPackage.package_sessions.cost) {
      res = true;
    }

    return res;
  }

  const { packageSeasonIdSelected } = props.values;
  const { currency } = props.values;
  const { packageSelected } = props.values;
  const { packageIdSelected } = props.values;

  let branchSession =
    props.values.branchSession && props.values.packageSelected
      ? getSessions()
      : [];

  function getSessions() {
    let filterSummer = [];
    if (props.values.packageSelected.is_summer) {
      filterSummer = props.values.branchSession.filter(filterGetOnlySummer);
    } else if (
      packageSelected.package_sessions &&
      packageSelected.package_sessions.sessions
    ) {
      filterSummer = props.values.branchSession.filter((session) =>
        packageSelected.package_sessions.sessions.includes(session.id)
      );
    } else {
      filterSummer = props.values.branchSession.filter(filterGetNotSummer);
      filterSummer = filterSummer.filter(filterByAge);
    }
    return filterSummer;
  }

  function filterByAge(item) {
    return item.skill_level.min_age <= age && item.skill_level.max_age >= age;
  }

  function filterGetOnlySummer(item) {
    return item.is_summer;
  }

  function filterGetNotSummer(item) {
    return !item.is_summer;
  }

  branchSession = groupBy(branchSession, 'session_day');

  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  // Used for Age testbox
  function handleInputChange(event) {
    props.handleInputChange(event, 'age');
  }

  function handleChangeCountry(event) {
    props.handleInputChange(event, 'country');
  }

  function handleChangeCity(event) {
    props.handleInputChange(event, 'city');
  }

  function handleChangeBranch(event) {
    props.handleInputChange(event, 'branch');
  }

  function handleChangePackageSeason(event) {
    props.handleInputChange(event, 'package');
  }

  function handleChangeSlots(event) {
    props.handleInputChange(event, 'slots');
  }

  function getCityObj() {
    const cityObject = cities.find(isCity);
    function isCity(cityObj) {
      return cityObj.id === parseInt(city, 10);
    }
    return cityObject;
  }

  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3} style={{ display: 'inline-block' }}>
        <form className={classes.root} autoComplete="off">
          {!centerCode && (
            <SingleLineGridList selected={sport} onClick={onClickSport} />
          )}
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                className={classes.textFieldNumber}
                id="age"
                label="Age"
                name="age"
                value={props.values.age}
                onChange={handleInputChange}
                type="number"
              />
            </FormControl>
            {!centerCode && (
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="country"> Country </InputLabel>
                <Select
                  value={country}
                  onChange={handleChangeCountry}
                  inputProps={{
                    name: 'country',
                    id: 'country',
                  }}
                  autoFocus
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {props.values.allCountriesBranches.map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                      {' '}
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {cities.length !== 0 ? (
              <FormControl className={classes.formControl}>
                <Autocomplete
                  required
                  autoFocus
                  autoHighlight
                  id="city"
                  options={cities}
                  getOptionLabel={(option) => option.city_name}
                  value={initCityObject}
                  onChange={(event, newValue) => {
                    handleChangeCity(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="City*" fullWidth />
                  )}
                />
                <Typography
                  spacing={2}
                  variant="caption"
                  display="block"
                  gutterBottom
                >
                  Search with your City Name
                </Typography>
              </FormControl>
            ) : null}
          </Grid>
          {branchesForSport.length !== 0 && age !== '' ? (
            <Grid item xs={12}>
              <Typography gutterBottom>Please select branch</Typography>
              <FormControl className={classes.formControl}>
                <RadioGroup
                  aria-label="branch"
                  name="branch"
                  id="branch"
                  value={branch}
                  onChange={handleChangeBranch}
                >
                  {branchesForSport.map((branch) => (
                    <FormControlLabel
                      value={`${branch.id}`}
                      key={branch.id}
                      control={<Radio color="secondary" />}
                      label={
                        <>
                          <BranchCard
                            branchLogo={branch.branch_logo_path}
                            name={branch.name}
                            address={branch.address}
                            branch_manager={branch.branch_manager}
                            email={branch.email}
                            contact_number={branch.contact_number}
                          />
                        </>
                      }
                      labelPlacement="end"
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {city === '' || age === '' ? (
                <Typography gutterBottom>
                  {centerCode
                    ? 'Please select Age'
                    : 'Please select Age, country and city'}
                </Typography>
              ) : (
                <Typography gutterBottom>Coming soon! Stay Tuned</Typography>
              )}
            </Grid>
          )}

          {branchPackage !== '' && branchPackages.length > 0 ? (
            <Grid item xs={12}>
              <Typography gutterBottom>Please select package</Typography>
              <RadioGroup
                aria-label="packageSeasonIdSelected"
                id="packageSeasonIdSelected"
                value={packageSeasonIdSelected}
                onChange={handleChangePackageSeason}
              >
                {branchPackages.map((packageObject) => (
                  <PackageCard
                    packageIdSelected={packageIdSelected}
                    packageObject={packageObject}
                    key={packageObject.id}
                    currency={currency}
                  />
                ))}
              </RadioGroup>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {' '}
              {branch && (
                <Typography gutterBottom>
                  Sorry ! No Packages are available at this moment!!
                </Typography>
              )}
            </Grid>
          )}
          {packageSeasonIdSelected !== '' ? (
            <Grid item xs={12} className={classes.formControl}>
              {!packageSelected.sessionless_package &&
              branchSession !== '' &&
              parseInt(Object.keys(branchSession).length, 10) > 0 ? (
                <Typography gutterBottom>
                  Please select {packageSelected.number_of_weekly_sessions}{' '}
                  session timings
                </Typography>
              ) : null}
              {!packageSelected.sessionless_package &&
              (branchSession === '' ||
                parseInt(Object.keys(branchSession).length, 10) === 0) ? (
                <Typography gutterBottom>
                  Sorry no sessions available for your age group, Please contact
                  branch manager for special batches
                </Typography>
              ) : null}
              {packageSelected.sessionless_package &&
                packageSelected.description && (
                  <Typography gutterBottom>
                    {packageSelected.description}
                  </Typography>
                )}
              {!packageSelected.sessionless_package && (
                <FormGroup spacing={3}>
                  {Object.keys(branchSession).map((key) => (
                    <SessionCard
                      key={key}
                      sessionsDay={key}
                      onChange={handleChangeSlots}
                      sessionsTimings={branchSession[key]}
                    />
                  ))}
                </FormGroup>
              )}
            </Grid>
          ) : null}
        </form>
      </Grid>
    </>
  );
}
