import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AttendedDatesTable from './TableofDatesCount';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  table: {
    minWidth: 400,
  },
  list: {
    maxWidth: 400,
    margin: 'auto',
  },
}));

const TableAttendanceBundle = (props) => {
  const classes = useStyles();
  const totalSessions = props.attObj.total_sessions;
  const attendedSessions = props.attObj.number_of_class_attended;
  const remainingSessions = totalSessions - attendedSessions;

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell>Total Sessions</TableCell>
              <TableCell>{totalSessions}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Attended Sessions</TableCell>
              <TableCell>{attendedSessions}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Remaining Sessions</TableCell>
              <TableCell>{remainingSessions}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {props.attObj.attendance.length > 0 && (
        <>
          <Typography variant="h6" className={classes.list}>
            Attended Dates
          </Typography>
          <AttendedDatesTable attendance={props.attObj.attendance} />
        </>
      )}
    </div>
  );
};

export default TableAttendanceBundle;
