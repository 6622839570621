/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Service from '../shared/Services';
import TabPanel from './TabPanel';

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ProgressReportTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState({
    // Package Selection
    progress_reports: [],
    progress_reports_list: [],
    progress_index: 0,
  });

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // code to run on component mount
    axios
      .all([
        Service.getProgressReport(
          source,
          props.month,
          props.year,
          props.studentId
        ),
      ])
      .then(
        axios.spread((progressReports) => {
          setValues((oldValues) => ({
            ...oldValues,
            progress_reports_list: progressReports.data,
            progress_reports: progressReports.data[0]
              ? progressReports.data[0].student_skill_set
              : [],
          }));
        })
      );

    return function cleanup() {
      source.cancel('Component Closed');
    };
  }, [props.month, props.year, props.studentId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleProgressReportIndexChange = (event) => {
    const index = event.target.value;

    setValues((oldValues) => ({
      ...oldValues,
      progress_index: index,
      progress_reports: values.progress_reports_list[index]
        ? values.progress_reports_list[index].student_skill_set
        : [],
    }));
  };

  return (
    <div className={classes.root}>
      {values.progress_reports === '' ||
      parseInt(values.progress_reports.length, 10) === 0 ? (
        <Typography gutterBottom>
          Progress report is not marked for this month. Please contact your
          coach for progress report.
        </Typography>
      ) : (
        <AppBar position="static" color="default">
          <Select
            value={values.progress_index}
            onChange={handleProgressReportIndexChange}
            inputProps={{
              name: 'progress',
              id: 'progress',
            }}
          >
            {values.progress_reports_list.map((item, index) => (
              <MenuItem key={item.id} value={index}>
                <Typography variant="body2" color="textSecondary">
                  {item.user_subscription.branch}
                </Typography>
                <Typography variant="subtitle2">
                  {item.user_subscription.name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="secondary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            {values.progress_reports.map((progress, index) => (
              <Tab
                label={progress.skill_set.skill_category}
                key={progress.skill_set.id}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </AppBar>
      )}
      {values.progress_reports.map((progress, index) => {
        progress.student_skills.sort((a, b) =>
          a.skill.id > b.skill.id ? 1 : -1
        );
        return (
          // eslint-disable-next-line react/no-array-index-key
          <TabPanel value={value} index={index} key={index}>
            {progress.student_skills.map((student_skill, indexInside) => {
              const scaleString = `scale_${student_skill.scale}`.toString();
              const skillComment = student_skill.skill[scaleString];

              return (
                <Box
                  component="fieldset"
                  mb={3}
                  borderColor="transparent"
                  // eslint-disable-next-line react/no-array-index-key
                  key={indexInside}
                >
                  <Typography component="legend">
                    {student_skill.skill.skill_name}
                  </Typography>
                  <Rating
                    name="Stance"
                    value={Number(student_skill.scale)}
                    readOnly
                  />
                  <Typography variant="subtitle2">{skillComment}</Typography>
                  <Divider light />
                </Box>
              );
            })}
            <Box component="fieldset" mb={3} borderColor="transparent">
              <TextField
                id="standard-read-only-input"
                label="Comments"
                multiline
                fullWidth
                value={progress.comment}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
          </TabPanel>
        );
      })}
    </div>
  );
}
