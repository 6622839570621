import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import DefaultImage from '../../../static/images/bookings/camp_cover.png';
import BookingStepper from './BookingStepper';

function BookingItemDetails(props) {
  const [camp, setCamp] = React.useState('');
  const [campCategory, setCampCategory] = React.useState('');

  useEffect(() => {
    if (props.location.camp) {
      setCamp(props.location.camp.camp);
      localStorage.setItem(
        'BOOKING_ITEM',
        JSON.stringify(props.location.camp.camp)
      );
    } else if (localStorage.getItem('BOOKING_ITEM')) {
      setCamp(JSON.parse(localStorage.getItem('BOOKING_ITEM')));
    }
  }, [camp, props.location.camp]);

  const backgroundImage = DefaultImage;
  const useStyles = makeStyles((theme) => ({
    image: {
      backgroundImage: camp.photo
        ? `url(${camp.cover_picture})`
        : `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: 'cover',
      // backgroundSize: 'contain',
      backgroundPosition: 'center',
      minHeight: '90vh',
    },
    top: {
      height: '20vh',
    },
    middle: {},
    down: {},
    proceed: {
      margin: theme.spacing(3, 0, 2),
    },
    cover: {
      height: '100%',
      width: '100%',
      // backgroundSize: 'contain',
    },
    [theme.breakpoints.up(960)]: {
      top: {
        height: '0',
      },
    },
  }));
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid item xs={12} sm={12} md={5} elevation={6} square="true">
        <BookingStepper
          camp={camp}
          campCategorySelected={campCategory}
          setCampCategory={setCampCategory}
        />
      </Grid>
      <Grid item xs={false} sm={false} md={7} className={classes.image} />
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
  };
}

export default connect(mapStateToProps)(BookingItemDetails);
