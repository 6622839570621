import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Box from '@material-ui/core/Box';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Videocam from '@material-ui/icons/Videocam';
import BookingItemInformation from './BookingItemInformation';
import BookingItemContact from './BookingItemContact';
import Utils from '../../../shared/Utils';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 345,
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2),
    textDecoration: 'none',
  },
  cardHeader: {
    padding: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(0),
  },
  listItem: {
    padding: theme.spacing(0),
  },
  title: {
    fontWeight: 1000,
    color: theme.palette.secondary.light,
  },
  subTitle: {
    color: theme.palette.secondary.light,
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: '1.5rem',
    paddingRight: '2px',
    paddingLeft: '4px',
  },
  dialog: {
    borderRadius: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.secondary.light,
    marginBottom: theme.spacing(4),
  },
  titleDialog: {
    marginTop: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}));

export default function BookingSummary(props) {
  const classes = useStyles();
  const { category } = props;
  const { program } = props;
  const { simple } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={open}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Grid>
          <Grid className={classes.down}>
            <Box bgcolor="secondary.main">
              <Typography className={classes.title}>
                {program.program_name}
              </Typography>
              <Typography className={classes.subTitle}>
                {category.description}
              </Typography>
            </Box>
            <Typography>
              <CalendarTodayOutlinedIcon className={classes.icon} />{' '}
              {Utils.getDateUIStringNoYear(category.start_date)} -{' '}
              {Utils.getDateUIStringNoYear(category.end_date)}
              <QueryBuilderOutlinedIcon className={classes.icon} />{' '}
              {Utils.get12HFrom24H(category.start_time)} -{' '}
              {Utils.get12HFrom24H(category.end_time)} {program.time_zone}
            </Typography>
            <Divider className={classes.divider} />
            <Typography>{program.academy.name}</Typography>
            <BookingItemContact program={program} />
          </Grid>
          <Divider />
          <Grid className={classes.middle}>
            <BookingItemInformation description={program.description} />
          </Grid>
        </Grid>
      </Dialog>
      <Card className={classes.card}>
        <Box bgcolor="secondary.main">
          <Typography className={classes.title}>
            {program.program_name}
          </Typography>
          {!simple && (
            <Typography className={classes.subTitle}>
              {category.description}
            </Typography>
          )}
        </Box>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Typography>
              {program.academy.city.country.currency} {category.cost}
            </Typography>
          }
          title={
            <>
              <CalendarTodayOutlinedIcon className={classes.icon} />{' '}
              {Utils.getDateUIStringNoYear(category.start_date)} -{' '}
              {Utils.getDateUIStringNoYear(category.end_date)}
            </>
          }
          subheader={
            <>
              <QueryBuilderOutlinedIcon className={classes.icon} />{' '}
              {Utils.get12HFrom24H(category.start_time)} -{' '}
              {Utils.get12HFrom24H(category.end_time)} {program.time_zone}
            </>
          }
        />
        <Divider />
        {!simple ? (
          <div>
            <Typography>{program.academy.name}</Typography>
            {props.program.program_mode.toUpperCase() === 'ONLINE' ? (
              <Typography>
                <Videocam className={classes.icon} />
                {props.program.program_mode.toUpperCase()}
              </Typography>
            ) : (
              <Typography>{program.academy.city.name}</Typography>
            )}
          </div>
        ) : (
          <CardActions>
            <Typography variant="caption">
              Booking Date: {props.booking.booking_date.substring(0, 10)}
            </Typography>
            <Button size="small" onClick={handleOpen}>
              Details
            </Button>
          </CardActions>
        )}
      </Card>
    </div>
  );
}
