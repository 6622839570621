import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import Page2 from '../components/Page2';

export default function Upskill() {
  const [skills, setSkills] = React.useState('Camps');
  const onClickSetSkills = (e) => {
    setSkills(e.target.value);
  };
  return (
    <div>
      <RadioGroup
        row
        aria-label="Upskills"
        name="row-radio-buttons-group"
        style={{ justifyContent: 'center', paddingTop: '20px' }}
        onChange={onClickSetSkills}
      >
        <FormControlLabel
          value="Certifications"
          control={<Radio />}
          label="Certifications"
          checked={skills === 'Certifications'}
        />
        <FormControlLabel
          value="Camps"
          control={<Radio />}
          label="Camps"
          checked={skills === 'Camps'}
        />
      </RadioGroup>

      {skills === 'Certifications' ? (
        <Page2 type="upskill" />
      ) : (
        <Page2 type="camp" />
      )}
    </div>
  );
}
