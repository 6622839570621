import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import List from '@material-ui/core/List';
import PersonIcon from '@material-ui/icons/Person';
import {
  ListItem,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import MedicalQnsTabsCoach from './MedicalQnsTabsCoach';
// import Utils from '../shared/Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    marginBottom: '1rem',
    cursor: 'pointer',
  },
  commentBox: {
    textAlign: 'left',
    padding: '1rem 0 1rem 1rem',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#38468e',
    color: theme.palette.common.white,
  },
  body: {
    backgroundColor: '#4657aa',
    color: theme.palette.common.white,
    fontSize: 14,
  },
}))(TableCell);

export default function StudentListMedicalQns(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    classObj: props.classObj,
  });

  const markProgress = (index) => () => {
    setValues((oldValues) => ({
      ...oldValues,
      studentId: values.classObj.students[index].student_user_id,
      studentName: values.classObj.students[index].fullname,
      userSubscriptionId: values.classObj.students[index].user_subscription_id,
    }));
    props.handleShowReport(true);
  };

  function hideProgress() {
    props.handleShowReport(false);
  }

  return (
    <div>
      {!props.showReport && (
        <List
          subheader={
            <ListSubheader>
              {' '}
              <IconButton
                onClick={props.handleShowClasses}
                edge="start"
                aria-label="mark"
              >
                <ArrowBack />
              </IconButton>{' '}
              {`${values.classObj.session_day.substring(
                0,
                3
              )} ${values.classObj.start_time.substring(
                0,
                5
              )} - ${values.classObj.end_time.substring(
                0,
                5
              )} ${values.classObj.skill_level
                .charAt(0)
                .toUpperCase()}${values.classObj.skill_level.slice(1)}`}
            </ListSubheader>
          }
          className={classes.root}
        >
          {values.classObj === '' ||
          values.classObj.students === '' ||
          parseInt(values.classObj.students.length, 10) === 0 ? (
            <Typography gutterBottom>
              No Students enrolled this class
            </Typography>
          ) : null}
          {values.classObj.students.map((student, index) => (
            <div className={classes.listItem}>
              <ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={markProgress(index)}
              >
                <ListItemAvatar>
                  <Avatar alt="player image" src={student.url}>
                    <PersonIcon fontSize="large" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ textAlign: 'left' }}
                  primary={student.fullname}
                  secondaryTypographyProps={{ style: { textAlign: 'left' } }}
                  secondary={student.student_user_id}
                />
              </ListItem>
            </div>
          ))}
        </List>
      )}
      {props.showReport && (
        <div style={{ marginTop: '1rem' }}>
          <TableContainer className={classes.root}>
            <ListSubheader>
              {' '}
              <IconButton onClick={hideProgress} edge="start" aria-label="mark">
                <ArrowBack />
              </IconButton>{' '}
              {`${values.classObj.session_day.substring(
                0,
                3
              )} ${values.classObj.start_time.substring(
                0,
                5
              )} - ${values.classObj.end_time.substring(
                0,
                5
              )} ${values.classObj.skill_level
                .charAt(0)
                .toUpperCase()}${values.classObj.skill_level.slice(1)}`}
            </ListSubheader>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    {' '}
                    {values.studentName}
                  </StyledTableCell>
                  <StyledTableCell>
                    {format(new Date(), 'dd-MMMM-yyyy')}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <MedicalQnsTabsCoach
            selectedDate={props.selectedDate}
            year={props.selectedDate.split('-')[0]}
            month={props.selectedDate.split('-')[1]}
            studentId={values.studentId}
            userSubscriptionId={values.userSubscriptionId}
            sessionId={props.classObj.session_id}
          />
        </div>
      )}
    </div>
  );
}
