/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import Typography from '@material-ui/core/Typography';
import Service from '../../shared/Services';
import Utils from '../../shared/Utils';
import StudentListAttendance from '../../components/StudentListAttendance';
import StudentListProgressReport from '../../components/StudentListProgressReport';
import PageLoading from '../../components/PageLoading';

export default function CoachClass(props) {
  const [selectedDate, setSelectedDate] = React.useState(
    Utils.formatDate(new Date())
  );
  const [open, setOpen] = React.useState(true);
  const [values, setValues] = React.useState({
    // Package Selection
    classes: [],
    showClasses: true,
    showReport: false,
  });
  const handleShowReport = (showReport) => {
    setValues((oldValues) => ({
      ...oldValues,
      showReport,
    }));
  };

  const handleDateChange = (date) => {
    setSelectedDate(Utils.formatDate(date));
    setValues((oldValues) => ({
      ...oldValues,
      index: -1,
      showClasses: true,
    }));
  };

  const handleShowClasses = () => {
    setValues((oldValues) => ({
      ...oldValues,
      showClasses: true,
    }));
  };

  const handleClickClass = (index) => {
    setValues((oldValues) => ({
      ...oldValues,
      index,
      showClasses: false,
      showReport: false,
    }));
  };

  useEffect(() => {
    setOpen(true);
    const CancelToken1 = axios.CancelToken;
    const source1 = CancelToken1.source();
    // code to run on component mount
    Service.getCoachAttendance(source1, selectedDate).then((res) => {
      setValues((oldValues) => ({
        ...oldValues,
        classes: res.data,
      }));
      setOpen(false);
    });

    return function cleanup() {
      source1.cancel('Component Closed');
    };
  }, [selectedDate]);

  return (
    <div>
      <PageLoading isLoading={open} />
      {!values.showReport && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              format="d MMM, yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      )}
      {values.classes === '' || parseInt(values.classes.length, 10) === 0 ? (
        <Typography gutterBottom>No Classes Scheduled for today</Typography>
      ) : null}
      {values.showClasses ? (
        <List dense={false}>
          {values.classes.map((classObj, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem key={index} onClick={handleClickClass.bind(this, index)}>
              <ListItemAvatar>
                <Avatar>{classObj.students.length}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${classObj.session_day.substring(
                  0,
                  3
                )} ${classObj.start_time.substring(
                  0,
                  5
                )} - ${classObj.end_time.substring(0, 5)}`}
                secondary={
                  classObj.skill_level.charAt(0).toUpperCase() +
                  classObj.skill_level.slice(1)
                }
              />
              <ListItemSecondaryAction
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={handleClickClass.bind(this, index)}
              >
                <IconButton edge="end" aria-label="mark">
                  <PlaylistAddCheck />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : props.type === 'attendnace' ? (
        <StudentListAttendance
          selectedDate={selectedDate}
          handleShowClasses={handleShowClasses}
          classObj={values.classes[values.index]}
        />
      ) : (
        <StudentListProgressReport
          selectedDate={selectedDate}
          handleShowClasses={handleShowClasses}
          handleShowReport={handleShowReport}
          classObj={values.classes[values.index]}
          showReport={values.showReport}
        />
      )}
    </div>
  );
}
