import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Utils from '../shared/Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

function compare(a, b) {
  if (
    Date.parse(`01/01/2011 ${a.start_time}`) <
    Date.parse(`01/01/2011 ${b.start_time}`)
  ) {
    return -1;
  }
  return 1;
}

export default function SessionCard(props) {
  const classes = useStyles();
  let { sessionsTimings } = props;
  sessionsTimings = sessionsTimings.filter((session) => session.is_active);
  sessionsTimings.sort(compare);
  const sessionsGroupLst = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Typography variant="h6" component="h3">
          {sessionsGroupLst[props.sessionsDay]}
        </Typography>
        {sessionsTimings.map((session) => (
          <FormControlLabel
            key={session.id}
            control={
              <Checkbox name={`${session.id}`} onChange={props.onChange} />
            }
            label={`${Utils.get12HFrom24H(
              session.start_time
            )} to ${Utils.get12HFrom24H(session.end_time)}`}
          />
        ))}
        {sessionsTimings === '' ||
        parseInt(Object.keys(sessionsTimings).length, 10) === 0 ? (
          <Typography gutterBottom>
            Sorry no sessions available for this day at this moment
          </Typography>
        ) : null}
      </Paper>
    </div>
  );
}
