import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SignIn from '../page_components/SignIn';

const useStyles = makeStyles((theme) => ({
  root: {},
  dialog: {
    textAlign: 'center',
  },
  appBar: {
    position: 'relative',
    background: theme.palette.secondary.main,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function DialogSignUpSignIn(prop) {
  const classes = useStyles();

  const handleClose = () => {
    prop.handleClose();
  };

  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={prop.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SignIn />
      </Dialog>
    </div>
  );
}
