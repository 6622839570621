import React from 'react';
import { render } from 'react-dom';
import { createStore } from 'redux';
import Root from './Root';
import './index.css';

const initialState = {
  isLoggedUser: false,
  to: '/',
};

function reducer(state = initialState, action) {
  if (action.type === 'SIGNED_IN' && !state.isLoggedUser) {
    return {
      isLoggedUser: true,
      to: '/player/register',
    };
  }
  if (action.type === 'SIGNED_OUT' && state.isLoggedUser) {
    return {
      isLoggedUser: false,
      to: '/',
    };
  }
  if (action.type === 'NOTPAID') {
    return {
      isLoggedUser: state.isLoggedUser,
      to: '/player/notpaid',
    };
  }
  if (action.type === 'PAID') {
    return {
      isLoggedUser: state.isLoggedUser,
      to: '/player/active',
    };
  }
  if (action.type === 'COACH') {
    return {
      isLoggedUser: state.isLoggedUser,
      to: '/coach',
    };
  }
  return state;
}

const store = createStore(reducer);

const WrapperWithFooter = () => [
  <Root key="1" store={store} className="content" />,
  <Footer key="2" />,
];

const Footer = () => (
  <footer className="footer">
    <p className="p">
      <a
        href="https://www.sportskingdom.io/privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy
      </a>{' '}
      ·{' '}
      <a
        href="https://www.sportskingdom.io/terms"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms
      </a>
      · Copyright © 2024,{' '}
      <a
        href="http://sportskingdom.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        SportsKingdom.
      </a>{' '}
    </p>
  </footer>
);

render(<WrapperWithFooter />, document.getElementById('root'));
