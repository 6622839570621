import React, { useEffect } from 'react';
import { format } from 'date-fns';
import {
  makeStyles,
  Box,
  Typography,
  AppBar,
  Tab,
  Tabs,
  Button,
  DialogActions,
  Card,
  CardContent,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Swal from 'sweetalert2';
import Service from '../../shared/Services';

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      // note: this style will be used when other modules will be added
      // overflowX: 'scroll',
      margin: '2rem 0 0.8rem 0',
    },
    cursor: 'pointer',
  },
  main: {
    textAlign: 'initial',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardMedia: {
    borderRadius: '15px',
    width: '90%',
    margin: '0 auto',
  },
  desc: {
    textAlign: 'left',
    fontSize: '12px',
    color: '#9d9d9d',
    overflowWrap: 'break-word',
  },
  listIcon: {
    alignSelf: 'flex-start',
    marginTop: '3px',
    color: 'red',
  },
  listItem: {
    margin: 0,
  },
  placeholder: {
    color: '#ccc',
    margin: '7rem auto',
  },
  dialogBtn: {
    justifyContent: 'flex-start',
    margin: '0.5rem 1rem',
  },
  date: {
    fontSize: '13px',
  },
  error: {
    color: '#aaa',
    fontSize: '11px',
    float: 'right',
  },
}));

export default function Task() {
  const classes = useStyles();
  const [allTasks, setAllTasks] = React.useState([]);
  const [selectedTaskId, setSelectedTaskId] = React.useState('2');
  const [openedTask, setOpenedTask] = React.useState({});
  const [modal, setModal] = React.useState(false);

  useEffect(() => {
    Service.getCoachTasks(selectedTaskId).then((res) => {
      setAllTasks(res.data);
    });
  }, [selectedTaskId]);

  const handleTabChange = (e, taskId) => {
    setSelectedTaskId(taskId);
  };

  const handleReject = () => {
    const { id } = openedTask;
    // Reject Task
    setModal(false);
    Service.updateCoachTasks(id, { status: 0 })
      .then((res) => {
        setAllTasks(allTasks.filter((data) => data.id !== id));
        Swal.fire('Task Rejected Successfully!');
      })
      .catch((error) => {});
  };

  const handleComplete = () => {
    const { id } = openedTask;
    // Complete Task
    setModal(false);
    Service.updateCoachTasks(id, { status: 4 })
      .then((res) => {
        setAllTasks(allTasks.filter((data) => data.id !== id));
        Swal.fire('Task Completed Successfully!');
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    setModal(false);
  };

  const openTask = (task) => {
    setOpenedTask(task);
    setModal(true);
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        style={{ boxShadow: 'none', marginTop: '1rem' }}
      >
        <Tabs
          value={selectedTaskId}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable tabs"
        >
          <Tab value="2" label="Pending" {...a11yProps(0)} />
          <Tab value="4" label="Completed" {...a11yProps(1)} />
          <Tab value="0" label="Rejected" {...a11yProps(2)} />
        </Tabs>
        <div value={selectedTaskId} index={1}>
          {allTasks.length > 0 ? (
            allTasks.map((task) => (
              <Box
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  margin: '1rem',
                  background: '#fff',
                }}
                onClick={() => openTask(task)}
              >
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '1rem 1rem 0 1rem',
                  }}
                >
                  <Typography className={classes.date}>
                    {/* {task.status} */}
                    <span style={{ color: 'blue' }}>
                      <b>{task.status === 2 && 'Pending'}</b>
                    </span>
                    <span style={{ color: 'green' }}>
                      <b>{task.status === 4 && 'Completed'}</b>
                    </span>
                    <span style={{ color: 'red' }}>
                      <b>{task.status === 0 && 'Rejected'}</b>
                    </span>
                  </Typography>
                  <Typography className={classes.date}>
                    Due Date: {format(new Date(task.due_date), 'dd-MMMM-yyyy')}
                  </Typography>
                </Box>
                <Card className={classes.card} elevation={0}>
                  <CardContent
                    style={{ textAlign: 'initial', padding: '0px 16px' }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle1"
                        style={{ overflowWrap: 'break-word' }}
                      >
                        <b>{task.title}</b>
                      </Typography>
                      <Typography type="subtitle1" className={classes.desc}>
                        {task.description}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))
          ) : (
            <Box className={classes.placeholder}>
              <MenuBookIcon fontSize="large" />
              <Typography type="subtitle1">There are no tasks</Typography>
            </Box>
          )}
        </div>
        {/* <div value={selectedTaskId} index={2}>
          Item Three
        </div> */}

        <Dialog fullWidth open={modal} onClose={handleClose}>
          <DialogTitle style={{ padding: '24px 24px 0' }}>
            <Typography
              variant="subtitle1"
              style={{ overflowWrap: 'break-word' }}
            >
              <b>{openedTask.title}</b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              placeholder="Description"
              multiline
              name="message"
              fullWidth
              rows={20}
              required
              value={openedTask.description}
              variant="standard"
              type="text"
              disabled
            />
          </DialogContent>
          {openedTask.status === 2 ? (
            <DialogActions className={classes.dialogBtn}>
              <Button
                onClick={handleReject}
                color="secondary"
                variant="outlined"
              >
                Reject Task
              </Button>

              <Button
                onClick={handleComplete}
                color="secondary"
                variant="contained"
              >
                Complete Task
              </Button>
            </DialogActions>
          ) : (
            <Typography
              type="subtitle1"
              style={{ padding: '0.5rem 1rem 1rem 1rem', textAlign: 'center' }}
            >
              Task status cannot be changed. Please contact admin for any
              queries.
            </Typography>
          )}
        </Dialog>
      </AppBar>
    </>
  );
}
