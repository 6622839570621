import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ProgressReportTabs from '../../../components/ProgressReportTabs';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function ProgressReport() {
  const classes = useStyles();
  const d = new Date();
  const [month, setMonth] = React.useState(d.getMonth() + 1);
  const [year, setYear] = React.useState(d.getFullYear());
  const [openMonth, setOpenMonth] = React.useState(false);
  const [openYear, setOpenYear] = React.useState(false);
  const years = [];

  for (let i = 2020; i <= d.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = (event) => {
    if (event.target.name === 'month') {
      setMonth(event.target.value);
    } else {
      setYear(event.target.value);
    }
  };

  const handleCloseMonth = () => {
    setOpenMonth(false);
  };

  const handleOpenMonth = () => {
    setOpenMonth(true);
  };

  const handleCloseYear = () => {
    setOpenYear(false);
  };

  const handleOpenYear = () => {
    setOpenYear(true);
  };

  return (
    <div>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label">Month</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={openMonth}
            onClose={handleCloseMonth}
            onOpen={handleOpenMonth}
            value={month}
            onChange={handleChange}
            name="month"
          >
            <MenuItem value={1}>Jan</MenuItem>
            <MenuItem value={2}>Feb</MenuItem>
            <MenuItem value={3}>Mar</MenuItem>
            <MenuItem value={4}>Apr</MenuItem>
            <MenuItem value={5}>May</MenuItem>
            <MenuItem value={6}>Jun</MenuItem>
            <MenuItem value={7}>Jul</MenuItem>
            <MenuItem value={8}>Aug</MenuItem>
            <MenuItem value={9}>Sep</MenuItem>
            <MenuItem value={10}>Oct</MenuItem>
            <MenuItem value={11}>Nov</MenuItem>
            <MenuItem value={12}>Dec</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label">Year</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={openYear}
            onClose={handleCloseYear}
            onOpen={handleOpenYear}
            value={year}
            onChange={handleChange}
            name="year"
          >
            {years.map((year, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={index} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <ProgressReportTabs year={year} month={month} />
    </div>
  );
}
