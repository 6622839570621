/* eslint-disable camelcase */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function compare(a, b) {
  if (
    parseInt(a.country_season_type.duration_in_months, 10) <
    parseInt(b.country_season_type.duration_in_months, 10)
  ) {
    return -1;
  }
  return 1;
}

export default function PackageCard(props) {
  const classes = useStyles();
  let packages = props.packageObject.season_type_cost;
  let fixedSessionPackages = [];
  if (props.packageObject.season_type_cost.length > 0) {
    packages = packages.filter((packageObj) => packageObj.is_active);
    packages.sort(compare);
  } else if (props.packageObject.package_sessions) {
    fixedSessionPackages = [props.packageObject.package_sessions];
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel
        defaultExpanded={
          props.packageObject.id === parseInt(props.packageIdSelected, 10)
        }
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {props.packageObject.name}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            {packages.map((season_type) => (
              <Tooltip
                disableTouchListener
                key={`${season_type.season_type_id}tool`}
                title={`${
                  props.packageObject.number_of_weekly_sessions * 4
                } sessions a month* ${
                  !season_type.include_registration_fee
                    ? ', Registration fee is exempted with this package'
                    : ''
                }  `}
              >
                <FormControlLabel
                  key={season_type.season_type_id}
                  value={`${season_type.season_type_id}`}
                  control={<Radio name={`${props.packageObject.id}`} />}
                  label={`${season_type.country_season_type.season_type_name} ${
                    season_type.cost
                  } ${props.currency}${
                    !season_type.include_registration_fee ? '*' : ''
                  }`}
                />
              </Tooltip>
            ))}
          </div>
          <div>
            {fixedSessionPackages.map((fixedSessionPackage) => (
              <Tooltip
                disableTouchListener
                key={`${fixedSessionPackage.season_type_id}tool`}
                title={`${
                  !fixedSessionPackage.include_registration_fee
                    ? '*Registration fee is exempted with this package'
                    : ''
                }  `}
              >
                <FormControlLabel
                  key={`${`NA${props.packageObject.id}`}`}
                  value={`${`NA${props.packageObject.id}`}`}
                  control={<Radio name={`${props.packageObject.id}`} />}
                  label={`${
                    fixedSessionPackage.number_of_sessions
                  } Session for ${fixedSessionPackage.cost} ${props.currency}${
                    !fixedSessionPackage.include_registration_fee ? '*' : ''
                  }`}
                />
              </Tooltip>
            ))}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
