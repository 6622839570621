import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Service from '../shared/Services';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '1rem',
    overflowX: 'hidden',
    height: '480px',
    paddingRight: '10px',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function MedicalQnsTabsCoach({ studentId, sessionId }) {
  const classes = useStyles();
  const [medicalQns, SetMedicalQns] = React.useState([]);
  const [medicalTest, SetMedicalTest] = React.useState([]);

  useEffect(() => {
    Service.getCoachMedicalTest(studentId, sessionId).then((res) => {
      SetMedicalTest(res.data);
    }, []);

    Service.getMedicalQns().then((res) => {
      SetMedicalQns(res.data);
    });
  }, [studentId, sessionId]);

  return (
    <>
      {medicalTest.length !== 0 ? (
        <div className={classes.root}>
          {medicalQns.map((ans) => (
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <CheckCircleIcon style={{ color: 'green' }} />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="subtitle2"
                  style={{ textAlign: 'left', marginBottom: '0.7rem' }}
                >
                  {ans.question}
                </Typography>
                {ans.is_polar_question === true ? (
                  <FormControl style={{ float: 'left' }}>
                    <RadioGroup
                      required
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={medicalTest
                        .find((ftans) => ftans.quaternaries_question === ans.id)
                        ?.polar_answer?.toString()}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                        disabled
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                        disabled
                      />
                    </RadioGroup>
                  </FormControl>
                ) : (
                  ''
                )}
                {ans.is_text_answer_possible === true ? (
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    fullWidth
                    name={ans.id}
                    variant="outlined"
                    rows={4}
                    required
                    disabled
                    placeholder="Specify Reasons"
                    value={
                      medicalTest.find(
                        (ftans) => ftans.quaternaries_question === ans.id
                      )?.text_answer
                    }
                  />
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          ))}

          <Typography
            variant="body2"
            style={{ color: '#ccc', marginTop: '1rem' }}
          >
            NOTE: Coaches can only read medical questions and cannot update. For
            any queries reach out to the particular student.
          </Typography>
        </div>
      ) : (
        <div style={{ color: 'darkgrey', padding: '8rem 2rem' }}>
          <Typography variant="h6">No Medical questionnaire found</Typography>
          <Typography variant="body2">
            Please ask your student to submit medical questionnaire
          </Typography>
        </div>
      )}
    </>
  );
}
