import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, styled } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import PhoneInput from 'react-phone-input-2';
import {
  FormHelperText,
  Avatar,
  Badge,
  DialogContent,
  Dialog,
  DialogActions,
  CardMedia,
  CardActions,
  Card,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import { EMAIL_REGEX } from '../static/Constant';
import LoginFacebook from '../components/ButtonFacebook';
import MySnackbarContentWrapper from '../components/MySnackbarContentWrapper';
import Utils from '../shared/Utils';
import Service from '../shared/Services';
import PageLoading from '../components/PageLoading';
import Button from '../components/ButtonDefault';

const validateEmail = (email) => EMAIL_REGEX.test(String(email).toLowerCase());

const validateDob = (dob) => {
  if (dob.length === 0 || new Date(dob) > new Date()) return false;
  const re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  return re.test(String(dob).toLowerCase());
};

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  imgCta: {
    color: 'red',
    backgroundColor: '#fff',
    border: '1px solid red',
    borderRadius: '4px',
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `2px solid ${theme.palette.background.paper}`,
  backgroundColor: 'red',
}));

function Profile(props) {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (props.isLoggedUser) {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      // code to run on component mount
      Service.getUserDetails(source).then((res) => {
        setOpen(false);
        setValues((oldValues) => ({
          ...oldValues,
          username: res.data.username,
          firstName: res.data.first_name,
          lastName: res.data.last_name,
          dob: res.data.date_of_birth,
          email: res.data.email,
          mobile: res.data.mobile,
          gender: res.data.gender,
          editMode: false,
        }));
      });

      return function cleanup() {
        source.cancel('Component Closed');
      };
    }
  }, [props.isLoggedUser]);
  const [open, setOpen] = React.useState(false);
  const [uploadedImages, SetUploadedImages] = React.useState([]);
  const [openedImage, setOpenedImage] = React.useState({});

  useEffect(() => {
    Service.getUploadedImages().then((res) => {
      SetUploadedImages(res.data);
    });
  }, []);

  const classes = useStyles();
  const [values, setValues] = React.useState({
    firstName: '',
    lastName: '',
    dob: '2011-01-01',
    email: '',
    mobile: '',
    gender: 'male',
    invalid: false,
    accepted: false,
    postError: '',
    editMode: true,
  });
  const handleChange = (e) => {
    e.persist();
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (date) => {
    setValues((oldValues) => ({
      ...oldValues,
      dob: Utils.formatDate(date),
    }));
  };

  const handleChangeMobile = (value) => {
    setValues((oldValues) => ({
      ...oldValues,
      mobile: value,
    }));
  };

  const validatePersonalDetails = (mobile) => {
    if (
      values.firstName.length === 0 ||
      !values.lastName ||
      values.lastName.length === 0 ||
      !EMAIL_REGEX.test(String(values.email).toLowerCase()) ||
      mobile === '+910000000' ||
      mobile.length < 6
    ) {
      setValues((oldValues) => ({
        ...oldValues,
        invalid: true,
      }));
      return false;
    }
    setValues((oldValues) => ({
      ...oldValues,
      invalid: false,
    }));
    return true;
  };

  const onImageChange = (event) => {
    const formData = new FormData();
    if (event.target.files && event.target.files[0]) {
      formData.append('file', event.target.files[0]);
      formData.append(
        'is_display_picture',
        uploadedImages && uploadedImages.length > 0 ? 0 : 1
      );
      Service.uploadPicture(formData)
        .then((res) => {
          Swal.fire('Image uploaded successfully!');
          setOpen(false);
          Service.getUploadedImages().then((res) => {
            SetUploadedImages(res.data);
          });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setOpen(false);
            Swal.fire(err.response.data.error);
          }
        });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const { firstName, lastName, dob, email, gender } = values;
    const mobile = Utils.getMobilewithPlus(values.mobile);
    if (
      values.editMode &&
      props.isLoggedUser &&
      !validatePersonalDetails(mobile)
    ) {
      return;
    }

    if (values.editMode) {
      setOpen(true);
      Service.updateUser({
        first_name:
          firstName.trim().charAt(0).toUpperCase() + firstName.trim().slice(1),
        last_name:
          lastName.trim().charAt(0).toUpperCase() + lastName.trim().slice(1),
        date_of_birth: dob,
        mobile,
        email,
        gender,
      })
        .then((res) => {
          setOpen(false);
          Utils.refreshJWTFromSession();
          Swal.fire('Profile Update Successful!').then(() => {
            setValues((oldValues) => ({
              ...oldValues,
            }));
          });
        })
        .catch((error) => {
          setOpen(false);
          let errorMsg = error.response.data;
          // eslint-disable-next-line prefer-destructuring
          errorMsg = errorMsg[Object.keys(errorMsg)[0]][0];
          const postError = errorMsg
            ? `${errorMsg} Please correct it and try again `
            : 'Sorry Please Try again later';
          setValues((oldValues) => ({
            ...oldValues,
            postError,
          }));
        });
    }
    setValues((oldValues) => ({
      ...oldValues,
      editMode: !values.editMode,
    }));
  };

  const handleOpenProfile = () => {
    setOpen(true);
  };

  const handleCloseProfile = () => {
    setOpen(false);
  };

  // delete picture
  const deletePicture = (img) => {
    const { id } = img;
    Service.deletePicture(id, true)
      .then((res) => {
        setOpen(false);
        SetUploadedImages(uploadedImages.filter((data) => data.id !== id));
        Swal.fire('Picture deleted successfully!');
        Service.getUploadedImages().then((res) => {
          SetUploadedImages(res.data);
        });
      })
      .catch((error) => {});
  };

  // set display picture
  const setDisplay = (img) => {
    const { id } = img;
    Service.setProfilePicture(id, true)
      .then((res) => {
        Swal.fire('Profile picture updated!');
        Service.getUploadedImages().then((res) => {
          SetUploadedImages(res.data);
        });
        setOpen(false);
        const temp = openedImage.filter((data) => data.id !== id);
        setOpenedImage([res.data, ...temp]);
      })
      .catch((error) => {});
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {!props.isLoggedUser && (
          <div>
            <LoginFacebook />
            <Divider className={classes.margin} />
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
          </div>
        )}
        <form className={classes.form} noValidate>
          <PageLoading isLoading={open} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClick={handleOpenProfile}
                badgeContent={
                  <SmallAvatar>
                    <EditIcon fontSize="small" />
                  </SmallAvatar>
                }
              >
                <Avatar
                  alt="player image"
                  style={{ width: '100px', height: '100px' }}
                  src={
                    uploadedImages.find(
                      (img) => img.is_display_picture === true
                    )?.url
                  }
                >
                  <PersonIcon fontSize="large" />
                </Avatar>
              </Badge>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Trainee First Name"
                disabled={!values.editMode}
                onChange={handleChange}
                value={values.firstName}
                error={values.invalid && values.firstName.length < 1}
                helperText={
                  values.invalid && values.firstName.length < 1
                    ? 'Invalid Name. Please correct it'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="lname"
                name="lastName"
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Trainee Last Name"
                disabled={!values.editMode}
                onChange={handleChange}
                value={values.lastName}
                error={
                  values.invalid &&
                  (!values.lastName || values.lastName.length < 1)
                }
                helperText={
                  values.invalid && values.lastName.length < 1
                    ? 'Invalid Name. Please correct it'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  name="dob"
                  margin="normal"
                  id="dob"
                  variant="outlined"
                  required
                  fullWidth
                  label="Date of Birth"
                  format="yyyy-MM-dd"
                  value={values.dob}
                  onChange={handleDateChange}
                  allowKeyboardControl={false}
                  disableFuture
                  disabled={!values.editMode}
                  error={values.invalid && !validateDob(values.dob)}
                  helperText={
                    values.invalid && !validateDob(values.dob)
                      ? 'Date of birth must be in YYYY-MM-DD formate and Must be a past date'
                      : ''
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <FormLabel component="legend" style={{ paddingRight: '1rem' }}>
                Gender:
              </FormLabel>
              <FormControl component="fieldset">
                <RadioGroup
                  disabled={!values.editMode}
                  aria-label="gender"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio color="primary" />}
                    label="Male"
                    labelPlacement="end"
                    disabled={!values.editMode}
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio color="primary" />}
                    label="Female"
                    labelPlacement="end"
                    disabled={!values.editMode}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="email"
                name="email"
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                disabled
                onChange={handleChange}
                value={values.email}
                error={values.invalid && !validateEmail(values.email)}
                helperText={
                  values.invalid && !validateEmail(values.email)
                    ? 'please enter a valid email id'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneInput
                country="sg"
                preferredCountries={['sg', 'in', 'de', 'qa', 'ae', 'jp']}
                name="mobile"
                value={values.mobile}
                onChange={handleChangeMobile}
                disabled={!values.editMode}
              />
              {values.invalid &&
                (values.mobile.length < 6 ||
                  Utils.getMobilewithPlus(values.mobile) === '+910000000') && (
                  <FormHelperText id="componentMobile" error>
                    Please enter a valid mobile number
                  </FormHelperText>
                )}
            </Grid>
          </Grid>
          {values.postError && (
            <MySnackbarContentWrapper
              variant="error"
              message={values.postError}
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!values.accepted && !props.isLoggedUser}
            onClick={submit}
          >
            {props.isLoggedUser && !values.editMode ? 'Edit' : 'Submit'}
          </Button>
        </form>
      </div>
      <>
        <Dialog
          open={open}
          onClose={handleCloseProfile}
          aria-labelledby="responsive-dialog-title"
          fullWidth
        >
          <DialogContent>
            <Grid container style={{ justifyContent: 'center' }} spacing={2}>
              {uploadedImages.length > 0 ? (
                uploadedImages.map((img) => (
                  <>
                    <Grid item xs={12} md={4}>
                      <Card>
                        {/* style={{ maxWidth: 290 }} */}
                        <CardMedia
                          style={{ height: 140 }}
                          image={img.url}
                          title="uploaded images"
                        />
                        <CardActions style={{ justifyContent: 'center' }}>
                          <Button
                            variant="outlined"
                            // size="small"
                            className={classes.imgCta}
                            onClick={() => setDisplay(img)}
                            fullWidth
                          >
                            Set as Display
                          </Button>
                        </CardActions>
                        <CardActions
                          style={{ justifyContent: 'center', paddingTop: 0 }}
                        >
                          <Button
                            variant="outlined"
                            // size="small"
                            className={classes.imgCta}
                            onClick={() => deletePicture(img)}
                            fullWidth
                          >
                            Delete Picture
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </>
                ))
              ) : (
                <Typography style={{ color: 'darkgrey' }}>
                  No images found. <br /> Please upload images to set as profile
                  picture.
                </Typography>
              )}
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: 0 }}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              style={{ color: '#fff', borderRadius: 0 }}
            >
              Upload New Picture
              <input
                hidden
                accept="image/*"
                onChange={onImageChange}
                type="file"
              />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    academy_to: state.to,
  };
}

export default connect(mapStateToProps)(Profile);
