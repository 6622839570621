import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import LocationOn from '@material-ui/icons/LocationOn';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    marginTop: theme.spacing(2),
  },
  listItem: {
    padding: theme.spacing(0),
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  title: {
    fontWeight: 1000,
  },
  hoverLink: {
    color: 'black',
    paddingLeft: '0',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default function BranchCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Box>
          <img
            src={props.branchLogo}
            alt=""
            style={{ width: '50px', height: 'auto' }}
          />
        </Box>
        <Typography gutterBottom variant="button" className={classes.title}>
          <b>{props.name}</b>
        </Typography>
        <List>
          <ListItem className={classes.listItem} key={props.branch_manager}>
            <ListItemIcon>
              <SupervisorAccount className={classes.icon} />{' '}
            </ListItemIcon>
            <ListItemText primary={props.branch_manager} />
          </ListItem>
          <ListItem className={classes.listItem} key={props.email}>
            <ListItemIcon>
              <Email className={classes.icon} />{' '}
            </ListItemIcon>
            <ListItem
              className={classes.hoverLink}
              component="a"
              href={`mailto:${props.email}`}
            >
              <ListItemText primary={props.email} />
            </ListItem>
          </ListItem>
          <ListItem className={classes.listItem} key={props.contact_number}>
            <ListItemIcon>
              <Phone className={classes.icon} />{' '}
            </ListItemIcon>
            <ListItem
              className={classes.hoverLink}
              component="a"
              href={`tel:${props.contact_number}`}
            >
              <ListItemText primary={props.contact_number} />
            </ListItem>
          </ListItem>
          <ListItem className={classes.listItem} key={props.address}>
            <ListItemIcon>
              <LocationOn className={classes.icon} />{' '}
            </ListItemIcon>
            <ListItemText primary={props.address} />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
