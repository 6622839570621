import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import jwtDecodeLib from 'jwt-decode';
import CardSection from './CardSection';
import Service from '../shared/Services';
import Button from './ButtonDefault';
import PageLoading from './PageLoading';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const { order } = props;

  const [disableButton, setDisableButton] = useState(false);
  const jwtData = jwtDecodeLib(localStorage.getItem('JWT_TOKEN'));
  const [result, setResult] = useState('');

  const handleSubmit = async (event) => {
    setDisableButton(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setDisableButton(false);
      return;
    }

    Service.createPaymentIntent(order, props.isbooking)
      .then((res) => {
        confirmCardPayment(event, res.data.client_secret);
      })
      .catch((error) => {
        setResult(
          error.message
            ? error.message
            : 'Invalid Card! Please check card details and try again '
        );
        setDisableButton(false);
      });
  };

  const confirmCardPayment = async (event, clientSecret) => {
    const resultConfirmation = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: jwtData.given_name,
          email: order.receipt_email,
        },
      },
    });

    if (resultConfirmation.error) {
      // Show error to your customer (e.g., insufficient funds)
      setResult(
        resultConfirmation.error && resultConfirmation.error.message
          ? resultConfirmation.error.message
          : 'Invalid Card! Please check card details and try again'
      );
      setDisableButton(false);
    } else {
      // The payment has been processed!
      // eslint-disable-next-line no-lonely-if
      if (resultConfirmation.paymentIntent.status === 'succeeded') {
        props.handleInputChange('', 'next');
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <form>
      <CardSection setResult={setResult} result={result} />
      <PageLoading isLoading={disableButton} />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        className={classes.submit}
        onClick={handleSubmit}
        color="primary"
        disabled={disableButton}
      >
        Pay
      </Button>
    </form>
  );
}
