/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './Components.css';
import Typography from '@material-ui/core/Typography';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#474d6c',
      fontFamily: '"Montserrat"',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

function CardSection(props) {
  const onChange = async (event) => {
    if (event.error) {
      props.setResult(event.error.message);
    } else {
      props.setResult('');
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      <CardElement onChange={onChange} options={CARD_ELEMENT_OPTIONS} />
      {props.result && (
        <Typography
          variant="caption"
          display="block"
          color="error"
          gutterBottom
        >
          {props.result}{' '}
        </Typography>
      )}
    </label>
  );
}

export default CardSection;
