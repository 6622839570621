import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CoachCelebCard from './CoachCelebCard';
import CampsCard from './CampsCard';
import Service from '../../../shared/Services';
import Utils from '../../../shared/Utils';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main,
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    maxHeight: '15vh',
    display: 'flex',
    borderRadius: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.up(600)]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  typoTitle: {
    fontWeight: 900,
    color: '#474d6c',
  },
  noPrograms: {
    marginTop: theme.spacing(4),
    fontSize: '0.6rem',
    fontWeight: 900,
    color: '#474d6c',
  },
}));

export default function BookingItemList(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    coachCelebList: [],
    campsList: [],
  });

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // code to run on component mount
    if (props.selectedSportId > 0) {
      if (props.type === 'camp') {
        Service.getAllProgramsBySport(
          props.selectedSportId,
          'players',
          source
        ).then((res) => {
          const campsList = res.data.results;
          // If a is has one time batch with start date greater than b return true
          campsList.sort((a, b) =>
            Utils.isNewestCamp(a.one_time_batch, b.one_time_batch) ? -1 : 1
          );
          setValues((oldValues) => ({
            ...oldValues,
            campsList,
          }));
        });
      } else if (props.type === 'upskill') {
        Service.getAllProgramsBySport(
          props.selectedSportId,
          'general',
          source
        ).then((res) => {
          const campsList = res.data.results;
          campsList.sort((a, b) =>
            // If a is has one time batch with start date greater than b return true
            Utils.isNewestCamp(a.one_time_batch, b.one_time_batch) ? 1 : -1
          );
          setValues((oldValues) => ({
            ...oldValues,
            campsList,
          }));
        });
      } else if (props.type === 'coach') {
        Service.getAllCelebsBySport(props.selectedSportId, source).then(
          (res) => {
            setValues((oldValues) => ({
              ...oldValues,
              coachCelebList: res.data.results,
            }));
          }
        );
      }
    } else {
      setValues({
        coachCelebList: [],
        campsList: [],
      });
    }

    return function cleanup() {
      source.cancel('Component Closed');
    };
  }, [props.selectedSportId, props.type]);

  return (
    <>
      <CssBaseline />
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {(props.type === 'camp' || props.type === 'upskill') &&
              values.campsList.map((camp, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <CampsCard
                    name={camp.camp_name}
                    image={
                      camp.photo
                        ? camp.photo
                        : require('../../../static/images/bookings/camps_dummy.png')
                    }
                    location={camp.academy.city.name}
                    camp={camp}
                    status={camp.is_active}
                  />
                </Grid>
              ))}
            {props.type === 'coach' &&
              values.coachCelebList.map((coachCeleb, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <CoachCelebCard
                    name={coachCeleb.display_name}
                    image={
                      coachCeleb.celebrity_avatars[0]
                        ? coachCeleb.celebrity_avatars[0].image
                        : require('../../../static/images/avatar.png')
                    }
                    title={coachCeleb.title}
                    tags={Utils.getTags(coachCeleb.tag)}
                    coachCeleb={coachCeleb}
                    id="1"
                  />
                </Grid>
              ))}
            {values.campsList.length === 0 &&
              values.coachCelebList.length === 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <Paper className={classes.noPrograms}>
                    <Typography>
                      {' '}
                      Sorry!! No Programs are available at this moment!! 
                    </Typography>
                  </Paper>
                </Grid>
              )}
          </Grid>
        </Container>
      </main>
    </>
  );
}
