import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { FormHelperText } from '@material-ui/core';
import axios from 'axios';
import { EMAIL_REGEX } from '../../../static/Constant';
import Utils from '../../../shared/Utils';
import MySnackbarContentWrapper from '../../../components/MySnackbarContentWrapper';
import Service from '../../../shared/Services';
import BookingSummary from '../components/BookingSummary';
import Button from '../../../components/ButtonDefault';

const validateEmail = (email) => EMAIL_REGEX.test(String(email).toLowerCase());

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cover: {
    height: '100%',
    width: '100%',
    // backgroundSize: 'contain',
  },
  top: {
    marginTop: theme.spacing(3),
    maxWidth: '50vh',
    alignContent: 'center',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
  bottom: {
    marginTop: theme.spacing(3),
    maxWidth: '60vh',
    alignContent: 'center',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
}));

function Step2(props) {
  const classes = useStyles();
  const campCategory = Utils.getItemByIDFromArray(
    props.parentProps.camp.one_time_batch,
    props.parentProps.campCategorySelected
  );
  const { camp } = props.parentProps;
  const { personalDetails } = props;

  const [values, setValues] = React.useState({
    invalid: false,
    postError: '',
    editMode: true,
  });

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // code to run on component mount
    Service.getBookings(source).then((res) => {
      const bookings = res.data.results;
      setValues((oldValues) => ({
        ...oldValues,
        bookings,
      }));
    });

    return function cleanup() {
      source.cancel('Component Closed');
    };
  }, []);

  const validatePersonalDetails = (mobile) => {
    if (
      personalDetails.firstName.length === 0 ||
      personalDetails.parentName.length === 0 ||
      !validateEmail(personalDetails.email) ||
      mobile === '+910000000' ||
      !mobile ||
      mobile.length < 6 ||
      Number.isNaN(personalDetails.age) ||
      personalDetails.age < 2 ||
      personalDetails.age > 100
    ) {
      setValues((oldValues) => ({
        ...oldValues,
        invalid: true,
      }));
      return false;
    }
    setValues((oldValues) => ({
      ...oldValues,
      invalid: false,
    }));
    return true;
  };

  const submit = (e) => {
    e.preventDefault();
    const mobile = Utils.getMobilewithPlus(personalDetails.mobile);
    if (
      values.editMode &&
      props.isLoggedUser &&
      !validatePersonalDetails(mobile)
    ) {
      return;
    }
    props.handleNext();
  };

  return (
    <Grid>
      <Grid className={classes.top}>
        <BookingSummary program={camp} category={campCategory} />
      </Grid>
      <Grid className={classes.bottom}>
        <form className={classes.form} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Player Full Name"
                disabled={!values.editMode}
                onChange={props.handleChange}
                value={personalDetails.firstName}
                error={values.invalid && personalDetails.firstName.length < 1}
                helperText={
                  values.invalid && personalDetails.firstName.length < 1
                    ? 'Invalid Name. Please correct it'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="parentName"
                name="parentName"
                variant="outlined"
                required
                fullWidth
                id="parentName"
                label="Parent Name"
                disabled={!values.editMode}
                onChange={props.handleChange}
                value={personalDetails.parentName}
                error={values.invalid && personalDetails.parentName.length < 1}
                helperText={
                  values.invalid && personalDetails.parentName.length < 1
                    ? 'Invalid Name. Please correct it'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="age"
                name="age"
                variant="outlined"
                required
                fullWidth
                id="age"
                label="Age"
                type="number"
                disabled={!values.editMode}
                onChange={props.handleChange}
                value={personalDetails.age}
                error={
                  values.invalid &&
                  (personalDetails.age.length < 1 ||
                    Number.isNaN(personalDetails.age) ||
                    personalDetails.age < 2 ||
                    personalDetails.age > 100)
                }
                helperText={
                  values.invalid &&
                  (personalDetails.age.length < 1 ||
                    Number.isNaN(personalDetails.age) ||
                    personalDetails.age < 2 ||
                    personalDetails.age > 100)
                    ? 'Invalid age. Please enter only number Eg: 12'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="email"
                name="email"
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                disabled={!values.editMode}
                onChange={props.handleChange}
                value={personalDetails.email}
                error={values.invalid && !validateEmail(personalDetails.email)}
                helperText={
                  values.invalid && !validateEmail(personalDetails.email)
                    ? 'please enter a valid email id'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneInput
                country="sg"
                preferredCountries={['sg', 'in', 'de', 'qa', 'ae', 'jp']}
                name="mobile"
                value={personalDetails.mobile}
                onChange={props.handleChangeMobile}
                disabled={!values.editMode}
              />
              {values.invalid &&
                (!personalDetails.mobile ||
                  personalDetails.mobile.length < 6 ||
                  Utils.getMobilewithPlus(personalDetails.mobile) ===
                    '+910000000') && (
                  <FormHelperText id="componentMobile" error>
                    Please enter a valid mobile number
                  </FormHelperText>
                )}
            </Grid>
          </Grid>
          {values.postError && (
            <MySnackbarContentWrapper
              variant="error"
              message={values.postError}
            />
          )}
        </form>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={submit}
          className={classes.submit}
        >
          Proceed
        </Button>
      </Grid>
      <Grid />
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    academy_to: state.to,
  };
}
export default connect(mapStateToProps)(Step2);
