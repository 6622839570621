import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Card,
  CardHeader,
  Avatar,
  CardMedia,
  CardContent,
  ListItemIcon,
  List,
  ListItemText,
  ListItem,
  Typography,
  Box,
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckIcon from '@material-ui/icons/Check';
import Service from '../shared/Services';
import season1 from '../static/images/season1.webp';
import PageLoading from './PageLoading';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: '2rem',
    border: '1px solid #ccc',
    [theme.breakpoints.down('md')]: {
      // note: this style will be used when other modules will be added
      // overflowX: 'scroll',
      margin: '2rem 0.8rem 0.8rem 0.8rem',
    },
  },
  main: {
    textAlign: 'initial',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardMedia: {
    borderRadius: '15px',
    width: '90%',
    margin: '0 auto',
  },
  desc: {
    textAlign: 'left',
    fontSize: '12px',
    color: '#9d9d9d',
  },
  listIcon: {
    alignSelf: 'flex-start',
    marginTop: '3px',
    color: 'red',
  },
  listItem: {
    margin: 0,
  },
}));

export default function SeasonCard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [season, setSeasons] = useState([]);

  useEffect(() => {
    Service.getCalendarDetails().then((res) => {
      setOpen(false);
      setSeasons(res.data);
    });
  }, []);

  return (
    <Box style={{ height: '500px', overflowY: 'scroll' }}>
      <PageLoading isLoading={open} />
      {season.map((seasons) => (
        <Card className={classes.card} elevation={0}>
          <CardHeader
            className={classes.main}
            avatar={
              <Avatar style={{ backgroundColor: 'red' }} aria-label="recipe">
                <CalendarTodayIcon />
              </Avatar>
            }
            title={seasons.name}
            subheader={seasons.season}
          />

          <CardMedia
            component="img"
            height="180"
            image={season1}
            alt="seasons images"
            className={classes.cardMedia}
          />

          <CardContent>
            <List>
              {seasons.calendar_season_plan.map((plan) => (
                <ListItem dense disableGutters>
                  <ListItemIcon className={classes.listIcon}>
                    <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItem}
                    primary={<b>{plan.name}</b>}
                    secondary={
                      <Typography type="subtitle1" className={classes.desc}>
                        {plan.description}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
