const Config = {
  BASE_URL: 'https://darwin.sportskingdom.io/api',
  AUTH_URL: 'https://darwin.sportskingdom.io/api-token-auth/',
  BASE_URL_BOOKINGS: 'https://portal.sportskingdom.io/api/',
  RAZORPAY_KEY: 'rzp_live_YTJzKyBoWHooDM',
  RAZORPAY_KEY_SK: 'rzp_live_65eVkVvuQ350K2',
  INR: 'INR',
  USERPOORID: 'ap-southeast-1_qdLBV678Q',
  CLIENTID: 'nqd39e8p3b3024f2dq272l3va',
  LOGIN_FB:
    'https://auth.sportskingdom.io/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://app.sportskingdom.io/signin/&response_type=TOKEN&client_id=nqd39e8p3b3024f2dq272l3va&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile',
  RESET: 'https://reset.sportskingdom.io/',
  USERPOOL_DOMINE: 'https://auth.sportskingdom.io',
  STRIPE_PUBLISH:
    'pk_live_51IGJdqGVS9LsbThoksv7eqkt514u0G6jkPtPnTL2rnvqpEMNDDoi3jcHHYKporihTFcacZzOm5vRD6MZhCt2L29N00hxD5d5r3',
  STRIPE_PUBLISH_SPORTSKINGDOM_SG: 'pk_live_N1iagimUOMbBbvZjNC43EaiY',
};

export default Config;
