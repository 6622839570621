import React from 'react';
import { connect } from 'react-redux';
import CoachFitness from '../page_components/coach/CoachFitness';
import PlayerFitness from '../page_components/player/registered/PlayerFitness';

function FitnessTest(prop) {
  return (
    <div>{prop.to === '/coach' ? <CoachFitness /> : <PlayerFitness />}</div>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    to: state.to,
  };
}

export default connect(mapStateToProps)(FitnessTest);
