import UserPool from './UserPool';

const Utils = {
  getStripePublish(m, p) {
    const rtm = Utils.decodeROT13(m);
    const rtp = Utils.decodeROT13(p);
    const rm = Utils.reverseString(rtm);
    const rp = Utils.reverseString(rtp);
    const op = rm + rp;
    return atob(op);
  },
  // get Date in YYYY-MM-DD
  formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  },
  // Gets Approximate DOB from age
  getDatefromAge(age) {
    const d = new Date();
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear() - age;

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  },
  // Get Age from Date
  getAgeFromDate(birthDate) {
    if (birthDate) {
      const today = new Date();
      return today.getFullYear() - birthDate.substring(0, 4);
    }
    return 0;
  },
  // getDatefromString YYYY-MM-DD and add days
  getDatefromStringAddDays(date, x) {
    const dateCopy = new Date(
      date.substring(0, 4),
      date.substring(5, 7) - 1,
      date.substring(8, 10)
    );
    return dateCopy.setDate(dateCopy.getDate() + x);
  },
  // getDatefromString YYYY-MM-DD
  getDatefromString(date) {
    return new Date(
      date.substring(0, 4),
      date.substring(5, 7) - 1,
      date.substring(8, 10)
    );
  },
  // get Registration Fee
  getRegistrationFee(season, branch) {
    return season.include_registration_fee ? branch.registration_fee : 0;
  },
  // isPastDate
  isPastDate(targetdate) {
    const target = new Date(
      targetdate.substring(0, 4),
      targetdate.substring(5, 7) - 1,
      targetdate.substring(8, 10)
    );
    const today = new Date().setHours(0, 0, 0, 0);
    if (target < today) {
      return true;
    }
    return false;
  },
  gotMonthName(num) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return monthNames[parseInt(num, 10) - 1];
  },
  getDateUIString(date) {
    return new Date(date).toUTCString().slice(4, 16);
  },
  getDateUIStringNoYear(date) {
    return new Date(date).toUTCString().slice(4, 11);
  },
  getMobilewithPlus(mobile) {
    if (mobile) {
      return mobile.charAt(0) === '+' ? mobile : `+${mobile}`;
    }
    return 0;
  },
  get12HFrom24H(time) {
    let hours = time.substring(0, 2);
    const minutes = time.substring(3, 5);
    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${AmOrPm}`;
  },
  getTags(tagsArray) {
    let result = '';
    for (let i = 0; i < tagsArray.length; i += 1) {
      const obj = tagsArray[i];
      if (i === 0) {
        result = obj.name;
      } else {
        result = `${result} | ${obj.name}`;
      }
    }
    return result;
  },
  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  getItemByIDFromArray(givenArray, id) {
    const resultItem = givenArray.find(isTarget);
    function isTarget(obj) {
      return obj.id === parseInt(id, 10);
    }
    return resultItem;
  },
  getItemByNameFromArray(givenArray, name) {
    const resultItem = givenArray.find(isTarget);
    function isTarget(obj) {
      return obj.name === name;
    }
    return resultItem;
  },
  refreshJWTFromSession() {
    // Updates the session
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        cognitoUser.refreshSession(
          session.getRefreshToken(),
          (err1, session1) => {
            if (
              session1.idToken.jwtToken &&
              localStorage.getItem('JWT_TOKEN') !==
                `Bearer ${session1.idToken.jwtToken}`
            ) {
              localStorage.setItem(
                'JWT_TOKEN',
                `Bearer ${session1.idToken.jwtToken}`
              );
            }
          }
        );
      });
    }
  },
  isNewestCamp(aList, bList) {
    if (aList.length === 0) {
      return false;
    }
    if (bList.length === 0) {
      return true;
    }
    const amaxDate = Utils.isNewCamp(aList);
    const bmaxDate = Utils.isNewCamp(bList);
    return amaxDate > bmaxDate;
  },
  isNewCamp(aList) {
    if (aList.length === 0) {
      return null;
    }
    let aMaxDate = Utils.getDatefromString(aList[0].start_date);
    // eslint-disable-next-line no-restricted-syntax
    for (const element of aList) {
      if (Utils.getDatefromString(element.start_date) > aMaxDate) {
        aMaxDate = Utils.getDatefromString(element.start_date);
      }
    }
    return aMaxDate;
  },
  updateJWTFromSession() {
    // Gets the Latest Token from Session (happens automatically by congnito on browser) and
    // Updates it if a new token is available
    // Called from APP.js to confrim session, No api call made here
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        // TO DO Clean up remove it from APP.js and only call in entry points
        // console.log(session)
        if (
          session.idToken.jwtToken &&
          localStorage.getItem('JWT_TOKEN') !==
            `Bearer ${session.idToken.jwtToken}`
        ) {
          localStorage.setItem(
            'JWT_TOKEN',
            `Bearer ${session.idToken.jwtToken}`
          );
        }
      });
    }
  },
  decodeROT13(str) {
    let decoded = '';
    for (let i = 0; i < str.length; i += 1) {
      const c = str.charCodeAt(i);
      if (c >= 65 && c <= 90) {
        decoded += String.fromCharCode(((c - 65 + 13) % 26) + 65);
      } else if (c >= 97 && c <= 122) {
        decoded += String.fromCharCode(((c - 97 + 13) % 26) + 97);
      } else {
        decoded += str.charAt(i);
      }
    }
    return decoded;
  },
  reverseString(str) {
    const reversed = str.split('').reverse().join('');
    return reversed;
  },
  // Returns false if there is one package that is not active
  hasActivePackages(packages) {
    // eslint-disable-next-line no-restricted-syntax
    for (const value of packages) {
      if (value.is_active) return true;
    }
    return false;
  },
  // Returns false if there is no package whose end date is not passed
  hasNewInActivePackages(packages) {
    const todaysDate = new Date();
    // eslint-disable-next-line no-restricted-syntax
    for (const value of packages) {
      const endDate = Utils.getDatefromString(value.end_date);
      if (todaysDate < endDate) {
        return true;
      }
    }
    return false;
  },
};

export default Utils;
