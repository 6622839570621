import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BookingList from './BookingList';
import SingleLineGridList from '../../../components/SingleLineGridList';
import Service from '../../../shared/Services';
import Utils from '../../../shared/Utils';

export default function Page2(props) {
  const [selected, setSelected] = useState('Cricket');
  const [selectedSportId, setSelectedSportId] = useState(1);
  const [sports, setSports] = useState([]);
  const onClickSport = (item) => {
    setSelected(item);
    const sportsObj = Utils.getItemByNameFromArray(sports, item);
    setSelectedSportId(sportsObj ? sportsObj.id : 0);
  };

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    Service.getAllSportsBookings(source).then((res) => {
      setSports(res.data.results);
    });
  }, []);

  return (
    <div className="container">
      <SingleLineGridList selected={selected} onClick={onClickSport} />
      <BookingList
        type={props.type}
        selectedSportId={selectedSportId}
        selected={selected}
      />
    </div>
  );
}
