import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircle from '@material-ui/icons/AccountCircleOutlined';
import Phone from '@material-ui/icons/PhoneOutlined';
import Mail from '@material-ui/icons/MailOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingBottom: '2px',
    paddingTop: '0px',
  },
  icon: {
    color: theme.palette.secondary.main,
  },
}));

export default function BookingItemContact(props) {
  const classes = useStyles();
  const manager = props.program.manager.user;
  const { address } = props.program.academy;

  return (
    <div>
      <Grid item>
        <div className={classes.demo}>
          <List dense>
            <ListItem key={manager.first_name} className={classes.listItem}>
              <ListItemIcon className={classes.icon}>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText
                primary={`${manager.first_name} ${manager.last_name}`}
              />
            </ListItem>
            <ListItem key={manager.phone_no} className={classes.listItem}>
              <ListItemIcon className={classes.icon}>
                <Phone />
              </ListItemIcon>
              <ListItemText primary={manager.phone_no} />
            </ListItem>
            <ListItem key={manager.email} className={classes.listItem}>
              <ListItemIcon className={classes.icon}>
                <Mail />
              </ListItemIcon>
              <ListItemText primary={manager.email} />
            </ListItem>
            {props.program.program_mode !== 'online' && (
              <ListItem key={address} className={classes.listItem}>
                <ListItemIcon className={classes.icon}>
                  <LocationOnOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={address} />
              </ListItem>
            )}
          </List>
        </div>
      </Grid>
    </div>
  );
}
