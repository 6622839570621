import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {},
});

export default function TableAttendance(props) {
  const classes = useStyles();

  const classAttObjMap = new Map();
  let totalAttended = 0;
  let totalAbsent = 0;

  props.attObj.attendance.forEach((singleAttObj, index) => {
    const classNameTime = `${singleAttObj.session_day.substring(
      0,
      3
    )} ${singleAttObj.start_time.substring(
      0,
      5
    )}-${singleAttObj.end_time.substring(0, 5)}`;
    if (!classAttObjMap.has(classNameTime)) {
      classAttObjMap.set(classNameTime, { attended: 0, absent: 0 });
    }
    const temp = classAttObjMap.get(classNameTime);

    if (singleAttObj.present) {
      temp.attended += 1;
      totalAttended += 1;
    } else {
      temp.absent += 1;
      totalAbsent += 1;
    }
    classAttObjMap.set(classNameTime, temp);
  });

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell size="small">Class</TableCell>
            <TableCell size="small">Attended</TableCell>
            <TableCell size="small">Absent</TableCell>
            <TableCell size="small">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(classAttObjMap).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell size="small">{key}</TableCell>
              <TableCell size="small">{value.attended}</TableCell>
              <TableCell size="small">{value.absent}</TableCell>
              <TableCell size="small">
                {value.attended + value.absent}
              </TableCell>
            </TableRow>
          ))}
          <TableRow key="total">
            <TableCell size="small">Total</TableCell>
            <TableCell size="small">{totalAttended}</TableCell>
            <TableCell size="small">{totalAbsent}</TableCell>
            <TableCell size="small">{totalAttended + totalAbsent}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
