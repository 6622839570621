import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CategoryCard from './CategoryCard';
import Utils from '../../../shared/Utils';

export default function BookingItemSelection(props) {
  const categories = props.category.filter(
    (item) => !Utils.isPastDate(item.end_date)
  );
  categories.sort((a, b) => (a.start_date > b.start_date ? 1 : -1));

  const handleChange = (event) => {
    props.setCampCategory(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="category"
        name="category"
        value={props.campCategory}
        onChange={handleChange}
      >
        {categories.map((category) => (
          <FormControlLabel
            value={`${category.id}`}
            key={category.id}
            control={<Radio />}
            label={
              <>
                <CategoryCard camp={props.camp} category={category} />
              </>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
