import React from 'react';
import { connect } from 'react-redux';
import CoachGoals from '../page_components/coach/CoachGoals';
import PlayerGoals from '../page_components/player/registered/PlayerGoals';

function SetGoals(prop) {
  return <div>{prop.to === '/coach' ? <CoachGoals /> : <PlayerGoals />}</div>;
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    to: state.to,
  };
}

export default connect(mapStateToProps)(SetGoals);
