import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import BookingItemContact from '../components/BookingItemContact';
import BookingItemInformation from '../components/BookingItemInformation';
import BookingItemSelection from '../components/BookingItemSelection';
import Button from '../../../components/ButtonDefault';

const useStyles = makeStyles((theme) => ({
  root: {},
  top: {
    height: '20vh',
  },
  middle: {},
  down: {},
  proceed: {
    margin: theme.spacing(3, 0, 2),
  },
  cover: {
    height: '100%',
    width: '100%',
    // backgroundSize: 'contain',
  },
  [theme.breakpoints.up(960)]: {
    top: {
      height: '0',
    },
  },
}));

function Step1(props) {
  const classes = useStyles();
  const { camp } = props.parentProps;
  const campCategory = props.parentProps.campCategorySelected;
  const { setCampCategory } = props.parentProps;
  const camps = camp.photo
    ? camp.photo
    : require('../../../static/images/bookings/camp_cover.png');

  return (
    <div>
      {camp && (
        <Grid>
          <Grid className={classes.top}>
            <CardMedia className={classes.cover} image={camps} title="Camp" />
          </Grid>
          <Typography variant="h6">{camp.program_name}</Typography>
          <Typography>{camp.academy.name}</Typography>
          <Divider />
          <Grid className={classes.middle}>
            <BookingItemInformation description={camp.description} />
          </Grid>
          <Divider />
          <Grid className={classes.down}>
            <BookingItemContact program={camp} />
          </Grid>
          <BookingItemSelection
            camp={camp}
            category={camp.one_time_batch}
            campCategorySelected={campCategory}
            setCampCategory={setCampCategory}
          />
          <Grid>
            <Button
              onClick={props.handleNext}
              className={classes.proceed}
              type="submit"
              fullWidth
              disabled={!campCategory}
              variant="contained"
              color="primary"
            >
              Proceed
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
  };
}
export default connect(mapStateToProps)(Step1);
