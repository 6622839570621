import React from 'react';
import SeasonCard from '../../components/SeasonCard';

export default function Calendar() {
  return (
    <div>
      <SeasonCard />
    </div>
  );
}
