import axios from 'axios';
import Config from './Config';

const { BASE_URL } = Config;

const { AUTH_URL } = Config;

const { BASE_URL_BOOKINGS } = Config;

const Api = {
  getAll: function getAll(source) {
    return axios.get(`${BASE_URL}/get-country-list`, {
      cancelToken: source.token,
    });
  },
  getBranchSessions: function getBranchSessions(branchId) {
    return axios.get(`${BASE_URL}/branch/${branchId}/sessions`);
  },
  getAllPackages: function getAllPackages() {
    return axios.get(`${BASE_URL}/get-all-package-cost`);
  },
  signIn: function signIn(id, pass) {
    return axios.post(`${AUTH_URL}`, {
      username: id,
      password: pass,
    });
  },
  signInFb: function signInFb(accessToken) {
    return axios.post(`${BASE_URL}/token-auth/facebook/`, {
      accessToken,
    });
  },
  registerStudent: function registerStudent(student) {
    return axios.post(`${BASE_URL}/student-registration/`, student, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getUserDetails: function getUserDetails() {
    return axios.get(`${BASE_URL}/update-user/`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  updateUser: async function updateUser(user) {
    return axios.put(`${BASE_URL}/update-user/`, user, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getUserStudent: async function getUserStudent() {
    return axios.get(`${BASE_URL}/student-registration/`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  updateStudent: async function updateStudent(user) {
    return axios.put(`${BASE_URL}/student-registration/`, user, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getPackageDetails: function getPackageDetails() {
    return axios.get(`${BASE_URL}/user-subscriptions/`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  subscriptionOffline: function subscriptionOffline(subscription) {
    return axios.post(`${BASE_URL}/offline-user-subscriptions/`, subscription, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getMessages: function getMessages(source) {
    return axios.get(`${BASE_URL}/users-notifications/`, {
      cancelToken: source.token,
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getProgressReport: function getProgressReport(
    source,
    month,
    year,
    studentId
  ) {
    if (studentId) {
      return axios.get(
        `${BASE_URL}/coach-student-progress-report/?month=${month}&year=${year}&student_user_id=${studentId}`,
        {
          cancelToken: source.token,
          headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
        }
      );
    }
    return axios.get(
      `${BASE_URL}/get-student-progress-report/?month=${month}&year=${year}`,
      {
        cancelToken: source.token,
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },
  postCoachProgressReport: function postCoachProgressReport(progressObj) {
    return axios.post(
      `${BASE_URL}/coach-student-progress-report/`,
      progressObj,
      { headers: { Authorization: localStorage.getItem('JWT_TOKEN') } }
    );
  },
  getSkillsMap: function getSkillsMap(source) {
    return axios.get(`${BASE_URL}/get-skill-set-coach/`, {
      cancelToken: source.token,
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getCoachAttendance: function getCoachAttendance(source, date) {
    return axios.get(`${BASE_URL}/student-attendance/?date=${date}`, {
      cancelToken: source.token,
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  postCoachAttendance: function postCoachAttendance(attendanceObj) {
    return axios.post(`${BASE_URL}/student-attendance/`, attendanceObj, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  postCoachAttendanceFlex: function postCoachAttendanceFlex(attendanceObj) {
    return axios.post(`${BASE_URL}/coach-flex-attendance/`, attendanceObj, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getAttendance: function getAttendance(source) {
    return axios.get(`${BASE_URL}/student-attendance/`, {
      cancelToken: source.token,
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getAttendanceV2Student: function getAttendanceV2Student(
    source,
    categoryName
  ) {
    return axios.get(
      `${BASE_URL}/student-flex-attendance/?package_type=${categoryName}`,
      {
        cancelToken: source.token,
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },
  getAttendanceFlex: function getAttendanceFlex(source, categoryName) {
    return axios.get(
      `${BASE_URL}/flex-students/?package_type=${categoryName}`,
      {
        cancelToken: source.token,
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },
  getAllCelebsBySport: async function getAllCelebsBySport(
    categoryName,
    source
  ) {
    return axios.get(
      `${BASE_URL_BOOKINGS}celebrities/?category__name=${categoryName}`,
      { cancelToken: source.token }
    );
  },
  getAllProgramsBySport: async function getAllCampsBySport(
    categoryName,
    programType,
    source
  ) {
    return axios.get(
      `${BASE_URL_BOOKINGS}programs/?sport=${categoryName}&program_type=${programType}&limit=100`,
      { cancelToken: source.token }
    );
  },
  createPaymentIntent: async function createPaymentIntent(order, isbooking) {
    if (isbooking) {
      return axios.post(`${BASE_URL_BOOKINGS}create-payment/`, order);
    }
    return axios.post(`${BASE_URL}/create-payment/`, order);
  },
  getBookings: function getBookings(source) {
    return axios.get(`${BASE_URL_BOOKINGS}program-bookings/?limit=100`, {
      cancelToken: source.token,
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getAllSportsBookings: function getAllSportsBookings(source) {
    return axios.get(`${BASE_URL_BOOKINGS}sports/?limit=100`, {
      cancelToken: source.token,
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getCenterGroupCode: function getCenterGroupCode(centerCode) {
    return axios.get(`${BASE_URL}/branch/?center__code=${centerCode}`);
  },
  getCalendarDetails: function getCalendarDetails() {
    return axios.get(`${BASE_URL}/calendar-season/`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  getAllNotes: function getAllNotes() {
    return axios.get(`${BASE_URL}/notes/`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  createNotes: function createNotes(notes) {
    return axios.post(`${BASE_URL}/notes/`, notes, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  updateNotes: async function updateNotes(
    id,
    title,
    message,
    is_important = false
  ) {
    return axios.patch(
      `${BASE_URL}/notes/${id}`,
      { title, message, is_important },
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },
  deleteNotes: async function deleteNotes(id, isActiveNote) {
    return axios.patch(
      `${BASE_URL}/notes/${id}`,
      { is_active: isActiveNote },
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },
  getTelegramLink: function getTelegramLink() {
    return axios.get(`${BASE_URL}/telegram-deeplink`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  searchAllNotes: async function searchAllNotes(searchParam) {
    return axios.get(`${BASE_URL}/notes/?search=${searchParam}`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  searchImpNotes: async function searchImpNotes(impNote) {
    return axios.get(`${BASE_URL}/notes/?is_important=${impNote}`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  // set goals apis
  getUserSports: async function getUserSports() {
    return axios.get(`${BASE_URL}/user-sports`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  getAllQuarterBySport: async function getAllQuarterBySport(categoryName) {
    return axios.get(`${BASE_URL}/quarterly-goals?sport=${categoryName}`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  getCoachGoalList: async function getCoachGoalList(goals) {
    return axios.get(
      `${BASE_URL}/coach-quarterly-goals/?goal_category=${goals.goal_category}&user=${goals.user}`,
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  getStudentGoalList: async function getStudentGoalList() {
    return axios.get(`${BASE_URL}/user-quarterly-goals`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  createCoachGoal: function createCoachGoal(goals) {
    return axios.post(`${BASE_URL}/coach-quarterly-goals/`, goals, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  createStudentGoal: function createStudentGoal(goals) {
    return axios.post(`${BASE_URL}/user-quarterly-goals/`, goals, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  updateCoachGoals: async function updateCoachGoals(id, goal, status) {
    return axios.patch(
      `${BASE_URL}/coach-quarterly-goals/${id}/`,
      { goal, status },
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  updateStudentGoals: async function updateStudentGoals(id, goal, status) {
    return axios.patch(
      `${BASE_URL}/user-quarterly-goals/${id}/`,
      { goal, status },
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  deleteCoachGoals: async function deleteCoachGoals(id, goal, status) {
    return axios.patch(
      `${BASE_URL}/coach-quarterly-goals/${id}`,
      { goal, status },
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  deleteStudentGoals: async function deleteStudentGoals(id, goal, status) {
    return axios.patch(
      `${BASE_URL}/user-quarterly-goals/${id}/`,
      { goal, status },
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  getFitnessQns: async function getFitnessQns() {
    return axios.get(
      `${BASE_URL}/quaternaries-questions/?quaternaries__category=fitness`,
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  getMedicalQns: async function getMedicalQns() {
    return axios.get(
      `${BASE_URL}/quaternaries-questions/?quaternaries__category=medical`,
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  getFeedbackQns: async function getFeedbackQns() {
    return axios.get(
      `${BASE_URL}/quaternaries-questions/?quaternaries__category=feedback`,
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  getFeedback: async function getFeedback() {
    return axios.get(`${BASE_URL}/feedback/`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  createFeedback: async function createFeedback(feedback) {
    return axios.post(`${BASE_URL}/feedback/`, feedback, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  getCoachTasks: async function getCoachTasks(taskId) {
    return axios.get(
      `${BASE_URL}/coach-task/?status=${taskId}`,

      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  updateCoachTasks: async function updateCoachTasks(id, updatedObj) {
    return axios.patch(`${BASE_URL}/coach-task/${id}/`, updatedObj, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  // fitness (filled by coach) & medical (filled by player) APIs
  getPlayerFitnessAns: async function getPlayerFitnessAns() {
    return axios.get(`${BASE_URL}/fitness-test`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  getCoachMedicalAns: async function getCoachMedicalAns() {
    return axios.get(`${BASE_URL}/medical-test`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  getCoachFitnessTest: async function getCoachFitnessTest(
    studentId,
    sessionId
  ) {
    return axios.get(
      `${BASE_URL}/fitness-test/?student_user=${studentId}&session_id=${sessionId}`,

      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  getCoachMedicalTest: async function getCoachMedicalTest(
    studentId,
    sessionId
  ) {
    return axios.get(
      `${BASE_URL}/medical-test/?student_user=${studentId}&session_id=${sessionId}`,

      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  getPlayerMedicalTest: async function getPlayerMedicalTest() {
    return axios.get(
      `${BASE_URL}/medical-test/`,

      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  createCoachFitnessTest: async function createCoachFitnessTest(
    test,
    studentId,
    sessionId
  ) {
    return axios.post(
      `${BASE_URL}/fitness-test/?student_user=${studentId}&session_id=${sessionId}`,
      test,
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },

  createPlayerMedicalTest: async function createPlayerMedicalTest(test) {
    return axios.post(`${BASE_URL}/medical-test/`, test, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  updateCoachFitnessTest: async function updateCoachFitnessTest(
    id,
    updatedObj
  ) {
    return axios.patch(`${BASE_URL}/fitness-test/${id}/`, updatedObj, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  updatePlayerMedicalTest: async function updatePlayerMedicalTest(
    id,
    updatedObj
  ) {
    return axios.patch(`${BASE_URL}/medical-test/${id}/`, updatedObj, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },

  getUploadedImages: function getUploadedImages() {
    return axios.get(`${BASE_URL}/profile-picture/`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
  uploadPicture: async function uploadPicture(formData) {
    return axios.put(`${BASE_URL}/profile-picture/`, formData, {
      headers: {
        Authorization: localStorage.getItem('JWT_TOKEN'),
        'content-type': 'multipart/form-data',
      },
    });
  },
  deletePicture: async function deletePicture(id, isActive) {
    return axios.patch(
      `${BASE_URL}/profile-picture/?pk=${id}`,
      { deleted: isActive },
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },
  setProfilePicture: async function setProfilePicture(
    id,
    is_display_picture = false
  ) {
    return axios.patch(
      `${BASE_URL}/profile-picture/?pk=${id}`,
      { is_display_picture },
      {
        headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
      }
    );
  },
  getMediaLink: function getMediaLink() {
    return axios.get(`${BASE_URL}/social-network/`, {
      headers: { Authorization: localStorage.getItem('JWT_TOKEN') },
    });
  },
};

export default Api;
