/* eslint-disable no-restricted-syntax */
import React from 'react';
import { makeStyles, Avatar, ListItemAvatar } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import Swal from 'sweetalert2';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Service from '../shared/Services';
import Utils from '../shared/Utils';
import PageLoading from './PageLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  studentName: {
    color: 'primary',
  },
  studentNameRed: {
    color: 'red',
  },
  studentNameOrange: {
    color: 'orange',
  },
  studentId: {
    textAlign: 'left',
    fontSize: '14px',
  },
  studentIdRed: {
    textAlign: 'left',
    fontSize: '14px',
    color: 'red',
  },
  studentIdOrange: {
    textAlign: 'left',
    fontSize: '14px',
    color: 'orange',
  },
}));

export default function StudentListAttendance(props) {
  // console.log(props.classObj);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const tempclassObj = props.classObj;
  if (tempclassObj !== '' && tempclassObj.students !== '') {
    tempclassObj.students = tempclassObj.students.sort((a, b) => {
      const fa = a.fullname.toLowerCase();
      const fb = b.fullname.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }

  const [values, setValues] = React.useState({
    classObj: tempclassObj,
  });

  const handleToggle = (index) => () => {
    const { classObj } = values;
    classObj.students[index].class_attended =
      !classObj.students[index].class_attended;

    setValues((oldValues) => ({
      ...oldValues,
      classObj,
    }));
  };

  const onClick = (e) => {
    e.preventDefault();
    const attendance = [];
    if (props.type === 'membership' || props.type === 'bundle') {
      let attendanceObj = {};
      for (const studentObj of values.classObj.students) {
        if (studentObj.class_attended) {
          const studentAttendance = {
            student_id: studentObj.student_id,
            subscription_id: studentObj.subscription_id,
          };
          attendance.push(studentAttendance);
        }
        attendanceObj = {
          attendance,
        };
      }
      setOpen(true);
      Service.postCoachAttendanceFlex(attendanceObj)
        .then((res) => {
          setOpen(false);
          Swal.fire('Attendance Submitted!', 'Thanks Coach!', 'success');
          props.handleShowClasses();
        })
        .catch((error) => {
          setOpen(false);
        });
    } else {
      for (const studentObj of values.classObj.students) {
        if (
          Utils.getDatefromString(studentObj.end_date) >=
          Utils.getDatefromString(props.selectedDate)
        ) {
          const studentAttendance = {
            student_user_id: studentObj.student_user_id,
            user_subscription_id: studentObj.user_subscription_id,
            class_attended: studentObj.class_attended,
            reason: 'NA',
          };
          attendance.push(studentAttendance);
        }
      }

      const attendanceObj = {
        session_id: values.classObj.session_id,
        date: props.selectedDate,
        attendance,
      };
      setOpen(true);
      Service.postCoachAttendance(attendanceObj)
        .then((res) => {
          setOpen(false);
          Swal.fire('Attendance Submitted!', 'Thanks Coach!', 'success');
          props.handleShowClasses();
        })
        .catch((error) => {
          setOpen(false);
        });
    }
  };

  return (
    <div>
      <PageLoading isLoading={open} />
      <List
        subheader={
          values.classObj.session_day && (
            <ListSubheader>
              {' '}
              <IconButton
                onClick={props.handleShowClasses}
                edge="start"
                aria-label="mark"
              >
                <ArrowBack />
              </IconButton>{' '}
              {`${values.classObj.session_day.substring(
                0,
                3
              )} ${values.classObj.start_time.substring(
                0,
                5
              )} - ${values.classObj.end_time.substring(
                0,
                5
              )} ${values.classObj.skill_level
                .charAt(0)
                .toUpperCase()}${values.classObj.skill_level.slice(1)}`}
            </ListSubheader>
          )
        }
        className={classes.root}
      >
        {values.classObj === '' ||
        values.classObj.students === '' ||
        parseInt(values.classObj.students.length, 10) === 0 ? (
          <Typography gutterBottom>No Students enrolled this class</Typography>
        ) : null}
        {values.classObj.students.map((student, index) => (
          <div className={classes.listItem}>
            <ListItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <ListItemAvatar>
                <Avatar alt="player image" src={student.url}>
                  <PersonIcon fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={
                  // eslint-disable-next-line no-nested-ternary
                  Utils.getDatefromStringAddDays(student.end_date, 0) <
                  Utils.getDatefromString(props.selectedDate)
                    ? classes.studentNameRed
                    : Utils.getDatefromStringAddDays(student.end_date, -7) <=
                      Utils.getDatefromString(props.selectedDate)
                    ? classes.studentNameOrange
                    : classes.studentName
                }
                primary={
                  student.fullname.charAt(0).toUpperCase() +
                  student.fullname.slice(1)
                }
                secondary={
                  <div>
                    <Typography
                      type="subtitle1"
                      className={
                        // eslint-disable-next-line no-nested-ternary
                        Utils.getDatefromStringAddDays(student.end_date, 0) <
                        Utils.getDatefromString(props.selectedDate)
                          ? classes.studentIdRed
                          : Utils.getDatefromStringAddDays(
                              student.end_date,
                              -7
                            ) <= Utils.getDatefromString(props.selectedDate)
                          ? classes.studentIdOrange
                          : classes.studentId
                      }
                    >
                      {student.student_user_id},{' '}
                      {Utils.getDateUIString(student.start_date)} -{' '}
                      {Utils.getDateUIString(student.end_date)}
                    </Typography>
                  </div>
                }
              />
              {Utils.getDatefromString(student.end_date) >=
                Utils.getDatefromString(props.selectedDate) && (
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={handleToggle(index)}
                    checked={student.class_attended}
                    color="primary"
                  />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </div>
        ))}
      </List>
      {parseInt(values.classObj.students.length, 10) !== 0 && (
        <>
          <Button
            variant="contained"
            color="primary"
            disabled={open}
            onClick={onClick}
          >
            Submit
          </Button>
          <PageLoading isLoading={open} />
        </>
      )}
    </div>
  );
}
