import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Notes from '../../components/Notes';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 12px',
    },
  },
}));

export default function Diary() {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Notes />
    </div>
  );
}
