import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import Assessment from '@material-ui/icons/Assessment';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

const useStyles = makeStyles({
  actionItem: {
    '&$selected': {
      background: 'aliceblue',
    },
  },
  selected: {},
});

export default function BottomNavigationCoach(prop) {
  const classes = useStyles();

  return (
    <BottomNavigation value={prop.value} onChange={prop.onChange} showLabels>
      <BottomNavigationAction
        label="Attendance"
        icon={<SupervisedUserCircle />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
      <BottomNavigationAction
        label="Progress"
        icon={<Assessment />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
      <BottomNavigationAction
        label="My Sport"
        icon={<DirectionsRunIcon />}
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
        }}
      />
    </BottomNavigation>
  );
}
