import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinkMaterial from '@material-ui/core/Link';
import { Link, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import MySnackbarContentWrapper from '../components/MySnackbarContentWrapper';
import Button from '../components/ButtonDefault';
import UserPool from '../shared/UserPool';
import LoginFacebook from '../components/ButtonFacebook';
import Config from '../shared/Config';
import PageLoading from '../components/PageLoading';

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function SignUp(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    firstName: '',
    email: '',
    password: '',
    confirmPassword: '',
    invalid: false,
    accepted: false,
    postError: '',
    registered: false,
    disabled: false,
  });
  const handleChange = (e) => {
    e.persist();
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };
  const handleAccept = (e) => {
    e.persist();
    setValues((oldValues) => ({
      ...oldValues,
      accepted: !oldValues.accepted,
    }));
  };
  const submit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, password, confirmPassword } = values;
    setValues((oldValues) => ({
      ...oldValues,
      disabled: true,
    }));

    if (
      firstName.length < 1 ||
      lastName.length < 1 ||
      !validateEmail(email) ||
      password.length < 5 ||
      password !== confirmPassword
    ) {
      setValues((oldValues) => ({
        ...oldValues,
        invalid: true,
        disabled: false,
      }));
    } else {
      UserPool.signUp(
        email.trim().replace('@', '$'),
        password,
        [
          { Name: 'email', Value: email.trim() },
          {
            Name: 'given_name',
            Value:
              firstName.trim().charAt(0).toUpperCase() +
              firstName.trim().slice(1),
          },
          {
            Name: 'family_name',
            Value:
              lastName.trim().charAt(0).toUpperCase() +
              lastName.trim().slice(1),
          },
        ],
        null,
        (err) => {
          if (err) {
            setValues((oldValues) => ({
              ...oldValues,
              disabled: false,
              postError:
                'Email Already registered with us, Use a different email or use forget password',
            }));
          } else {
            setValues((oldValues) => ({
              ...oldValues,
              disabled: false,
            }));
            localStorage.setItem('USER', email.trim());
            localStorage.setItem('PWD', password);

            Swal.fire(
              'Registration Successful!',
              `We have sent a verification link to ${email.trim()}. Please verify your email to log in with your email.`,
              'success'
            ).then(() => {
              setValues(() => ({
                registered: true,
              }));
            });
          }
        }
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const renderRedirectSignIn = () => {
    if (values.registered) {
      return <Redirect to="/signin" />;
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
        </div>
        <form className={classes.form} noValidate>
          <PageLoading isLoading={values.disabled} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Trainee First Name"
                onChange={handleChange}
                value={values.firstName}
                error={values.invalid && values.firstName.length < 1}
                helperText={
                  values.invalid && values.firstName.length < 1
                    ? 'Invalid Name. Please correct it'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="lname"
                name="lastName"
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Trainee Last Name"
                onChange={handleChange}
                value={values.lastName}
                error={values.invalid && values.lastName.length < 1}
                helperText={
                  values.invalid && values.lastName.length < 1
                    ? 'Invalid Name. Please correct it'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="email"
                name="email"
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                onChange={handleChange}
                value={values.email}
                error={values.invalid && !validateEmail(values.email)}
                helperText={
                  values.invalid && !validateEmail(values.email)
                    ? 'please enter a valid email id'
                    : ''
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={handleChange}
                value={values.password}
                error={values.invalid && values.password.length < 5}
                helperText={
                  values.invalid && values.password.length < 5
                    ? 'Password must be minimum 5 letters'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirm-password"
                onChange={handleChange}
                value={values.confirmPassword}
                error={
                  values.invalid && values.confirmPassword !== values.password
                }
                helperText={
                  values.invalid && values.confirmPassword !== values.password
                    ? 'Confirm password must be same as password'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={values.accepted}
                    onChange={handleAccept}
                    name="accepted"
                    color="primary"
                  />
                }
                label={
                  <>
                    <Typography>
                      By clicking, you agree to our
                      <LinkMaterial
                        href="https://www.skonnect.io/terms"
                        target="_blank"
                        rel="noopener"
                      >
                        <b> Terms </b>
                      </LinkMaterial>{' '}
                      and
                      <LinkMaterial
                        href="https://www.skonnect.io/privacy"
                        target="_blank"
                        rel="noopener"
                      >
                        <b> Privacy Policy </b>
                      </LinkMaterial>
                    </Typography>
                  </>
                }
              />
            </Grid>
          </Grid>

          {values.postError && (
            <MySnackbarContentWrapper
              variant="error"
              message={values.postError}
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              (!values.accepted && !props.isLoggedUser) || values.disabled
            }
            onClick={submit}
          >
            Submit
          </Button>
          <div>
            <Grid container justify="flex-end">
              <Grid item xs>
                <LinkMaterial
                  href={Config.RESET}
                  target="_blank"
                  rel="noopener"
                  variant="body2"
                >
                  Forgot password?
                </LinkMaterial>
              </Grid>
              <Grid item xs>
                <LinkMaterial component={Link} to="/signin" variant="body2">
                  Already have an account? Sign in
                </LinkMaterial>
              </Grid>
            </Grid>
          </div>
          <div>
            <LoginFacebook />
          </div>
        </form>
        {renderRedirectSignIn()}
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    academy_to: state.to,
  };
}

export default connect(mapStateToProps)(SignUp);
