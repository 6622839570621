import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import BottomNavigationCoach from '../../components/BottomNavigationCoach';
import Attendance from './Attendance';
import ProgressReport from './ProgressReport';
import MySport from '../../components/MySport';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  bottomBar: {
    width: 'auto',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: '300',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

function getContent(value) {
  switch (value) {
    case 0:
      return <Attendance />;
    case 1:
      return <ProgressReport />;
    case 2:
      return <MySport />;
    default:
      throw new Error('Unknown step');
  }
}

export default function ActivePlayer() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <main className={classes.layout}>
      <Paper className={classes.paper}>
        <BottomNavigationCoach
          value={value}
          onChange={onChange}
          className={classes.bottomBar}
        />
        {getContent(value)}
      </Paper>
    </main>
  );
}
