/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import Service from '../../../shared/Services';

const useStyles = makeStyles(() => ({
  date: {
    padding: '1rem',
    textAlign: 'right',
    color: 'darkgrey',
  },
  root: {
    paddingTop: '1rem',
    overflowX: 'hidden',
    height: '480px',
    paddingRight: '10px',
  },
}));

export default function Feedback() {
  const classes = useStyles();
  const [feedbackAns, setFeedbackAns] = React.useState([]);
  const [feedbackQns, setFeedbackQns] = React.useState([]);
  const [feedbackStatus, setFeedbackStatus] = React.useState('NA');
  const [feedbackResponse, createFeedbackResponse] = React.useState([]);
  const [openedQns, setOpenedQns] = React.useState([]);

  function upsertTextAns(array, element, qnsId) {
    const i = array.findIndex(
      (_element) => _element.quaternaries_question === qnsId
    );
    if (i > -1) array[i].text_answer = element;
    else
      array.push({
        quaternaries_question: qnsId,
        text_answer: element,
        polar_answer: null,
      });
    return array;
  }

  function upsertPolarAns(array, element, qnsId) {
    const i = array.findIndex(
      (_element) => _element.quaternaries_question === qnsId
    );
    if (i > -1) array[i].polar_answer = element;
    else
      array.push({
        quaternaries_question: qnsId,
        text_answer: '',
        polar_answer: element,
      });
    return array;
  }

  useEffect(() => {
    Service.getFeedback()
      .then((res) => {
        setFeedbackStatus('NOT_SUBMITTED');
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setFeedbackStatus(err.response.data.error);
        }
      });
  }, []);

  useEffect(() => {
    if (feedbackStatus === 'NOT_SUBMITTED') {
      Service.getFeedbackQns().then((res) => {
        setFeedbackQns(res.data);
      });
    }
  }, [feedbackStatus]);

  const handleTextChange = (e, qnsId) => {
    e.persist();
    const newObj = [...feedbackAns];
    const newObject = upsertTextAns(newObj, e.target.value, qnsId);
    setOpenedQns(newObject);
    setFeedbackAns(newObject);
  };

  const handlePolarAns = (e, qnsId) => {
    e.persist();
    const newObj = [...feedbackAns];
    const newObject = upsertPolarAns(newObj, e.target.value, qnsId);
    setFeedbackAns(newObject);
  };

  const submitTest = () => {
    let isValid = true;
    feedbackQns
      .filter((q) => q.is_optional === false)
      .forEach((q) => {
        const answerObj = openedQns.find(
          (qns) => qns.quaternaries_question === q.id
        );
        if (answerObj) {
          if (answerObj.text_answer && answerObj.text_answer.length > 0) {
            if (isValid) isValid = true;
          } else {
            isValid = false;
          }
          if (q.is_polar_question) {
            if (answerObj.polar_answer && answerObj.polar_answer !== null) {
              if (isValid) isValid = true;
            } else {
              isValid = false;
            }
          }
        } else {
          const feedbackObj = feedbackAns.find(
            (qns) => qns.quaternaries_question === q.id
          );
          if (feedbackObj) {
            if (feedbackObj.text_answer && feedbackObj.text_answer.length > 0) {
              if (isValid) isValid = true;
            } else {
              isValid = false;
            }
            if (q.is_polar_question) {
              if (
                feedbackObj.polar_answer &&
                feedbackObj.polar_answer !== null
              ) {
                if (isValid) isValid = true;
              } else {
                isValid = false;
              }
            }
          } else {
            isValid = false;
          }
        }
      });
    if (!isValid) {
      Swal.fire({
        icon: 'error',
        text: 'Please answer all compulsory questions',
      });
      return;
    }

    Service.createFeedback(feedbackAns)
      .then((res) => {
        createFeedbackResponse([res.data, ...feedbackResponse]);
        Swal.fire('Feedback submitted successfully!');
        // setHideSubmitBtn(true);
      })
      .catch((error) => {});
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.date}>
        {format(new Date(), 'dd-MMMM-yyyy')}
      </Typography>
      {!['NOT_SUBMITTED', 'NA'].includes(feedbackStatus) ? (
        <>
          <CheckCircleIcon fontSize="large" style={{ color: 'darkgrey' }} />
          <Typography variant="body1" style={{ color: 'darkgrey' }}>
            Thank You!
          </Typography>
          <Typography variant="body2" style={{ color: 'darkgrey' }}>
            {feedbackStatus}
          </Typography>
        </>
      ) : (
        feedbackQns.map((qnst) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <CheckCircleIcon style={{ color: 'green' }} />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="subtitle2"
                  style={{ textAlign: 'left', marginBottom: '0.7rem' }}
                >
                  {qnst.question}
                </Typography>
                {qnst.is_polar_question === true ? (
                  <FormControl style={{ float: 'left' }}>
                    <RadioGroup
                      onChange={(e) => handlePolarAns(e, qnst.id)}
                      required
                      row
                      value={
                        feedbackAns.find(
                          (ftans) => ftans.quaternaries_question === qnst.id
                        )?.polar_answer
                      }
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                ) : (
                  ''
                )}

                {qnst.is_text_answer_possible === true ? (
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    fullWidth
                    variant="outlined"
                    rows={4}
                    required
                    placeholder="Specify Reasons"
                    onChange={(e) => handleTextChange(e, qnst.id)}
                    value={
                      feedbackAns.find(
                        (ftans) => ftans.quaternaries_question === qnst.id
                      )?.text_answer
                    }
                  />
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </>
        ))
      )}
      {!['NOT_SUBMITTED', 'NA'].includes(feedbackStatus) ? (
        ''
      ) : (
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: '1rem' }}
          onClick={() => submitTest()}
        >
          Submit
        </Button>
      )}
    </div>
  );
}
