import React, { useEffect } from 'react';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Doughnut } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import Service from '../../../shared/Services';
import TableAttendance from '../../../components/TableAttendance';
import TabPanel from '../../../components/TabPanel';
import Utils from '../../../shared/Utils';
import PageLoading from '../../../components/PageLoading';
import TableAttendanceBundle from '../../../components/TableAttendanceBundle';
import TableAttendanceMembership from '../../../components/TableAttendanceMembership';

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function AttendanceUtil(props) {
  const [values, setValues] = React.useState({
    // Package Selection
    att: [],
    isLoading: true,
  });

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // code to run on component mount
    if (props.type === 'bundle' || props.type === 'membership') {
      // code to run on component mount
      Service.getAttendanceV2Student(source, props.type).then((res) => {
        const att = res.data;
        att.sort((a, b) => (a.start_date > b.start_date ? -1 : 1));
        setValues((oldValues) => ({
          ...oldValues,
          isLoading: false,
          att,
        }));
      });
    } else {
      Service.getAttendance(source).then((res) => {
        const att = res.data;
        att.sort((a, b) => (a.start_date > b.start_date ? -1 : 1));
        setValues((oldValues) => ({
          ...oldValues,
          isLoading: false,
          att,
        }));
      });
    }

    return function cleanup() {
      source.cancel('Component Closed');
    };
  }, [props]);

  return (
    <div>
      <PageLoading isLoading={values.isLoading} />
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="primary"
        >
          {values.att.map((attObj, index) => (
            <Tab
              label={
                <div>
                  <Typography variant="body2" color="textSecondary">
                    {attObj.branch_name}
                  </Typography>
                  <Typography variant="subtitle2">
                    {`${Utils.getDateUIString(
                      attObj.start_date
                    )} - ${Utils.getDateUIString(attObj.end_date)}`}
                  </Typography>
                </div>
              }
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              {...a11yProps(index)}
            >
              {/* Content of the Tab */}
            </Tab>
          ))}
        </Tabs>
      </AppBar>
      {values.att.map((attObj, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TabPanel value={value} index={index} key={index}>
          {props.type === 'weekly' && (
            <Doughnut
              data={{
                labels: ['Absent', 'Attended'],
                datasets: [
                  {
                    data: [
                      attObj.total_classes_till_now -
                        attObj.number_of_class_attended,
                      attObj.number_of_class_attended,
                    ],
                    backgroundColor: ['#ed1c24', '#474d6c'],
                    hoverBackgroundColor: ['#ed1c24', '#474d6c'],
                  },
                ],
              }}
            />
          )}
          {props.type === 'weekly' && <TableAttendance attObj={attObj} />}
          {props.type === 'bundle' && <TableAttendanceBundle attObj={attObj} />}
          {props.type === 'membership' && (
            <TableAttendanceMembership attObj={attObj} />
          )}
        </TabPanel>
      ))}
    </div>
  );
}
