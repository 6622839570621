import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Class from '@material-ui/icons/Class';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import Assessment from '@material-ui/icons/Assessment';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    justifyContent: 'initial',
    overflow: 'auto',
    overflowWrap: 'initial',
  },
  icons: {
    color: theme.palette.secondary.main,
  },
}));

export default function BottomNavigationPlayer(prop) {
  const classes = useStyles();

  return (
    <BottomNavigation
      value={prop.value}
      onChange={prop.onChange}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Profile"
        icon={<AccountCircle className={classes.icons} />}
      />
      <BottomNavigationAction
        label="Packages"
        icon={<Class className={classes.icons} />}
      />
      <BottomNavigationAction
        label="Attendance"
        icon={<SupervisedUserCircle className={classes.icons} />}
      />
      <BottomNavigationAction
        label="Progress"
        icon={<Assessment className={classes.icons} />}
      />
      <BottomNavigationAction
        label="My Sport"
        icon={<DirectionsRunIcon className={classes.icons} />}
      />
    </BottomNavigation>
  );
}
