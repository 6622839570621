import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Money from '@material-ui/icons/Money';
import SportsIcon from '@material-ui/icons/Sports';
import { Link } from 'react-router-dom';
import Videocam from '@material-ui/icons/Videocam';
import clsx from 'clsx';
import Utils from '../../../shared/Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    maxHeight: '16vh',
    borderRadius: theme.spacing(2),
    textDecoration: 'none',
  },
  root1: {
    filter: 'grayscale(1)',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
  },
  content: {
    flex: '1 0 auto',
    padding: theme.spacing(1),
  },
  cover: {
    height: '16vh',
    width: '30%',
    borderRadius: theme.spacing(2),
  },
  typoTitle: {
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
  typoSubsTitle: {
    fontSize: '0.7rem',
    fontWeight: 900,
    color: theme.palette.secondary.main,
  },
  typoSubs: {
    fontSize: '0.7rem',
    fontWeight: 900,
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: '0.7rem',
  },
}));

export default function CampsCard(props) {
  const classes = useStyles();
  let max = props.camp.one_time_batch[0]
    ? props.camp.one_time_batch[0].cost
    : '-';
  let min = props.camp.one_time_batch[0]
    ? props.camp.one_time_batch[0].cost
    : '-';

  //  Filter the past one_time_batch batches
  const categories = props.camp.one_time_batch.filter(
    (item) => !Utils.isPastDate(item.start_date)
  );

  //  Loop and Save the max and min
  if (categories.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const element of props.camp.one_time_batch) {
      if (element.cost > max) {
        max = element.cost;
      } else if (element.cost < min) {
        min = element.cost;
      }
    }
  }

  const isActive = props.status ? '' : classes.root1;
  const to = props.status
    ? { pathname: '/training/details', camp: props }
    : { pathname: window.location.pathname };

  return (
    <>
      <Card component={Link} to={to} className={clsx(classes.root, isActive)}>
        <CardMedia className={classes.cover} image={props.image} title="Camp" />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography className={classes.typoTitle} align="left">
              {props.camp.program_name}
            </Typography>
            <Typography
              noWrap
              className={classes.typoSubsTitle}
              align="left"
              variant="subtitle1"
            >
              <SportsIcon className={classes.icon} /> {props.camp.academy.name}
            </Typography>
            <Typography
              noWrap
              className={classes.typoSubs}
              align="left"
              variant="subtitle1"
            >
              <Money className={classes.icon} /> {min} ~ {max}{' '}
              {props.camp.academy.city.country.currency}
            </Typography>
            {props.camp.program_mode === 'online' ? (
              <Typography
                noWrap
                className={classes.typoSubs}
                align="left"
                variant="subtitle1"
              >
                <Videocam className={classes.icon} /> ONLINE
              </Typography>
            ) : (
              <Typography
                noWrap
                className={classes.typoSubs}
                align="left"
                variant="subtitle1"
              >
                <LocationOnIcon className={classes.icon} />
                {props.location}
              </Typography>
            )}
          </CardContent>
        </div>
      </Card>
    </>
  );
}
