import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Today from '@material-ui/icons/Today';
import Schedule from '@material-ui/icons/Schedule';
import AttachMoney from '@material-ui/icons/AttachMoney';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Utils from '../shared/Utils';
import social from '../static/images/social.png';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    textAlign: 'left',
    margin: 15,
  },
  media: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pos: {
    marginBottom: 12,
  },
  posEnd: {
    marginBottom: 12,
    marginTop: 10,
    fontWeight: 'bold',
  },
  listItem: {
    padding: theme.spacing(0),
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  title: {
    fontWeight: 1000,
    fontSize: 14,
  },
}));

const getDay = (day) => {
  const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  return days[day - 1];
};

export default function PackageSummary(props) {
  const classes = useStyles();
  const regFee = `Registration fee : ${props.currency} ${props.regFee}`;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title} gutterBottom variant="button">
          {props.season} {props.currency} {props.cost}
        </Typography>
        <List>
          <ListItem className={classes.listItem} key={props.name}>
            <ListItemIcon>
              <Today className={classes.icon} />{' '}
            </ListItemIcon>
            <ListItemText primary={props.name} />
          </ListItem>
          {props.sessionDetailsList.map((session) => {
            const text = `${getDay(session.session_day)} ${Utils.get12HFrom24H(
              session.start_time
            )}-${Utils.get12HFrom24H(session.end_time)}`;
            return (
              <ListItem key={session.id} className={classes.listItem}>
                <ListItemIcon>
                  <Schedule className={classes.icon} />{' '}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
          <ListItem className={classes.listItem} key={regFee}>
            <ListItemIcon>
              <AttachMoney className={classes.icon} />{' '}
            </ListItemIcon>
            <ListItemText primary={regFee} />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Box className={classes.media}>
              {props.socialLink?.map((m) => (
                <a
                  href={m.url}
                  alt={m.name}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: '0 4px' }}
                >
                  <img
                    src={m.icon_path ? m.icon_path : social}
                    alt=""
                    style={{ width: '23px', height: 'auto' }}
                  />
                </a>
              ))}
            </Box>
          </ListItem>
        </List>

        <Divider />
        <Typography className={classes.posEnd} component="div">
          Total fee payable : {props.currency} {props.cost + props.regFee}
        </Typography>
      </CardContent>
    </Card>
  );
}
