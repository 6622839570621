import { makeStyles, useTheme } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import tennis from '../static/images/sportTennis.png';
import cricket from '../static/images/sportCricket.png';
import Yoga from '../static/images/sportsYoga.png';
import badminton from '../static/images/sportBadminton.png';
import soccer from '../static/images/sportSoccer.png';
import arts from '../static/images/sportsArts.png';
import all from '../static/images/sportsAll.png';
import basketball from '../static/images/sportsBasketball.png';
import gym from '../static/images/sportsGym.png';
import martialart from '../static/images/sportsMartialart.png';
import swimming from '../static/images/sportsSwimming.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    marginTop: theme.spacing(4),
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  tileSelected: {
    border: '8px solid #474D6D',
    maxHeight: theme.spacing(16),
  },
  tile: {
    borderRadius: theme.spacing(16),
    maxHeight: theme.spacing(16),
  },
  img: {
    height: theme.spacing(32),
    maxWidth: theme.spacing(24),
  },
  title: {},
  titleBar: {
    height: theme.spacing(4),
  },
  titleBarSelected: {
    height: theme.spacing(3),
    background: 'red',
  },
}));

const tileData = [
  {
    img: all,
    title: 'All',
  },
  {
    img: cricket,
    title: 'Cricket',
  },
  {
    img: soccer,
    title: 'Football',
  },
  {
    img: badminton,
    title: 'Badminton',
  },
  {
    img: swimming,
    title: 'Swimming',
  },
  {
    img: tennis,
    title: 'Tennis',
  },
  {
    img: basketball,
    title: 'Basketball',
  },
  {
    img: gym,
    title: 'Gym',
  },
  {
    img: martialart,
    title: 'Martial Arts',
  },
  {
    img: Yoga,
    title: 'Yoga',
  },
  {
    img: arts,
    title: 'Theatre Arts',
  },
];

export default function SingleLineGridList(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  let cols = 2.5;

  if (matches) {
    cols = 4.5;
  }

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={cols}>
        {tileData.map((tile) => (
          <GridListTile
            name={tile.title}
            id={tile.title}
            key={tile.img}
            className={
              tile.title === props.selected
                ? classes.tileSelected
                : classes.tile
            }
            onClick={(e) => {
              props.onClick(tile.title);
            }}
            bod="true"
          >
            <img src={tile.img} className={classes.img} alt={tile.title} />
            <GridListTileBar
              title={tile.title}
              classes={{
                root:
                  tile.title === props.selected
                    ? classes.titleBarSelected
                    : classes.titleBar,
                title: classes.title,
              }}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
