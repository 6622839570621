import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Swal from 'sweetalert2';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import BranchPackageSelection from './registration/BranchPackageSelection';
import PersonalDetails from './registration/PersonalDetails';
import ReviewUpdate from './registration/ReviewUpdate';
import Service from '../shared/Services';
import Utils from '../shared/Utils';
import Config from '../shared/Config';
import { EMAIL_REGEX } from '../static/Constant';
import SuccessPage from './registration/SuccessPage';
import MySnackbarContentWrapper from '../components/MySnackbarContentWrapper';
import ButtonDefault from '../components/ButtonDefault';
import PageLoading from '../components/PageLoading';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function getStepContent(step, values, handleInputChange, props) {
  switch (step) {
    case 0:
      return (
        <BranchPackageSelection
          values={values}
          handleInputChange={handleInputChange}
        />
      );
    case 1:
      if (props.isLoggedUser && props.academy_to !== '/player/register')
        return (
          <ReviewUpdate values={values} handleInputChange={handleInputChange} />
        );
      return (
        <PersonalDetails
          values={values}
          handleInputChange={handleInputChange}
        />
      );
    case 2:
      if (props.isLoggedUser && props.academy_to !== '/player/register')
        return <SuccessPage values={values} />;
      return (
        <ReviewUpdate values={values} handleInputChange={handleInputChange} />
      );
    case 3:
      return <SuccessPage values={values} />;
    default:
      throw new Error('Unknown step');
  }
}

function Registration(props) {
  const location = useLocation();
  const steps =
    props.isLoggedUser && props.academy_to !== '/player/register'
      ? ['Branch & Package', 'Review', 'Confirmation']
      : ['Branch & Package', 'Personal Details', 'Review'];

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  // Handle State of BranchPackage Selection
  const [values, setValues] = React.useState({
    redirect: false,
    // Package Selection
    allCountriesBranches: [],
    age: '',
    country: '',
    currency: '',
    cities: [],
    city: '',
    branches: [],
    branch: '',
    branchPackage: '',
    packageIdSelected: '',
    packageSelected: '',
    packageSeasonIdSelected: '',
    branchSession: [],
    branchSessionList: [],
    sessionSelected: [],
    // Personal Details
    firstName: '',
    lastName: '',
    gender: 'male',
    email: '',
    mobile: '',
    dob: '',
    parentName: '',
    is_student: false,
    // Validations
    firstStepValid: false,
    secondStepValid: false,
    thirdStepValid: false,
    personalDetailsError: false,
    postError: '',
    disableButton: false,
    payment: 'online',
    isLoading: true,
    centerCode: '',
    sport: 'All',
  });

  useEffect(() => {
    // code to run on component mount
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const params = new URLSearchParams(location.search);
    fetchResult();
    async function fetchResult() {
      if (
        !values.to ||
        values.to !== '/player/active' ||
        values.to !== '/player/notpaid'
      ) {
        const pkgs = await Service.getPackageDetails().catch((error) => {
          setValues((oldValues) => ({
            ...oldValues,
          }));
        });
        if (pkgs) {
          if (Array.isArray(pkgs.data)) {
            if (Utils.hasActivePackages(pkgs.data)) {
              props.dispatch({ type: 'PAID' });
            } else if (Utils.hasNewInActivePackages(pkgs.data)) {
              props.dispatch({ type: 'NOTPAID' });
            }
          }
        }
      }

      if (params.get('center__code') && params.get('sport')) {
        setValues((oldValues) => ({
          ...oldValues,
          isLoading: true,
        }));
        Service.getCenterGroupCode(params.get('center__code')).then((res) => {
          setValues((oldValues) => ({
            ...oldValues,
            branches: res.data,
            isLoading: false,
            age: '',
            centerCode: params.get('center__code'),
            sport: params.get('sport'),
          }));
        });
      } else if (localStorage.getItem('REGISTRATION_VALUES')) {
        const regValue = JSON.parse(
          localStorage.getItem('REGISTRATION_VALUES')
        );
        setValues(regValue);
        setValues((oldValues) => ({
          ...oldValues,
          sessionSelected: [],
        }));
        localStorage.removeItem('REDIRECT_URL');
        localStorage.removeItem('REGISTRATION_VALUES');
      } else if (
        (props.academy_to === '/player/active' ||
          props.academy_to === '/player/notpaid') &&
        values.branches.length === 0
      ) {
        axios
          .all([
            Service.getAll(source),
            Service.getUserDetails(),
            Service.getUserStudent(),
          ])
          .then(
            axios.spread((allList, user, student) => {
              const countryObj = allList.data.find(
                (country) => country.id === student.data.country
              );
              const cityObj = countryObj.cities.find(
                (b) => b.city_name === student.data.city
              );
              const { cities } = countryObj;
              const city = cityObj ? cityObj.id : '';
              const branches = cityObj
                ? cityObj.branches.filter((branch) => branch.is_active)
                : [];

              setValues((oldValues) => ({
                ...oldValues,
                allCountriesBranches: allList.data,
                age: Utils.getAgeFromDate(user.data.date_of_birth),
                dob: user.data.date_of_birth,
                country: student.data.country,
                cities,
                city,
                branches,
                user: user.data,
                userid: user.data.id,
                cityObj,
                countryObj,
                isLoading: false,
                payment: 'online',
              }));
            })
          );
      } else {
        Service.getAll(source).then((res) => {
          setValues((oldValues) => ({
            ...oldValues,
            allCountriesBranches: res.data,
            isLoading: false,
          }));
        });
      }
    }

    return function cleanup() {
      source.cancel('Component Closed');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOfflinePayment = () => {
    let season = values.packageSelected.season_type_cost.find(
      (s) => s.season_type_id === parseInt(values.packageSeasonIdSelected, 10)
    );

    if (season == null) {
      season = values.packageSelected.package_sessions;
    }
    const branch = values.branches.find(
      (b) => b.id === parseInt(values.branch, 10)
    );
    const includeRegistrationFee =
      props.academy_to !== '/player/active' && season.include_registration_fee;
    const registrationFee = includeRegistrationFee
      ? branch.registration_fee
      : 0;
    const cost = (season.cost + registrationFee) * 100;

    let seasonTypeId = values.packageSeasonIdSelected;

    if (seasonTypeId.startsWith('NA')) {
      seasonTypeId = '';
    }

    const subscription = {
      amount: cost,
      branch_id: branch.id.toString(),
      package_id: values.packageIdSelected,
      branch_session: values.sessionSelected,
      season_type_id: seasonTypeId,
      reg_fee_included: includeRegistrationFee,
    };
    subscriptionOffline(subscription);
  };

  const handleOnlinePayment = () => {
    const packageName = values.packageSelected.name;

    let season = values.packageSelected.season_type_cost.find(
      (s) => s.season_type_id === parseInt(values.packageSeasonIdSelected, 10)
    );
    if (season == null) {
      season = values.packageSelected.package_sessions;
    }

    let seasonTypeId = values.packageSeasonIdSelected;

    if (seasonTypeId.startsWith('NA')) {
      seasonTypeId = '';
    }

    const branch = values.branches.find(
      (b) => b.id === parseInt(values.branch, 10)
    );
    const includeRegistrationFee =
      props.academy_to !== '/player/active' && season.include_registration_fee;
    const registrationFee = includeRegistrationFee
      ? branch.registration_fee
      : 0;
    const cost = (season.cost + registrationFee) * 100;

    // get package season from season_type_cost[array] with id as values.packageSeasonIdSelected
    const options = {
      key: Config.RAZORPAY_KEY,
      // TODO Move to config
      amount: cost, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise or INR 500.
      currency: Config.INR,
      name: branch.name,
      description: packageName,
      image: require('../static/images/logo.png'),
      // "order_id": "order_9A33XWu170gUtm",// We are not using order id, Instead we use data in notes to validate the subscription
      handler(response) {
        if (response.razorpay_payment_id) {
          setActiveStep(activeStep + 1);
        }
      },
      prefill: {
        name: values.firstName,
        email: values.email,
        contact: values.mobile,
      },
      notes: {
        branch_id: values.branch,
        package_id: values.packageIdSelected,
        regFeeIncluded: includeRegistrationFee,
        season_type_id: seasonTypeId,
        user_id: values.userid,
        branch_session: `[${values.sessionSelected.toString()}]`,
      },
      theme: { color: '#ed1c24' },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const updateUser = () => {
    Service.updateUser({
      first_name: values.firstName,
      last_name: values.lastName,
      date_of_birth: Utils.getDatefromAge(values.age),
      mobile: Utils.getMobilewithPlus(values.mobile),
      gender: values.gender,
      email: values.email,
    })
      .then((res) => {})
      .catch((error) => {});
  };

  const updateStudent = () => {
    if (values.is_student) {
      Service.updateStudent({
        city: getCityName(),
        country: values.country,
        parent_name: values.parentName,
      })
        .then((res) => {})
        .catch((error) => {});
    } else {
      Service.registerStudent({
        city: getCityName(),
        country: values.country,
        country_of_birth: 'Please update',
        nationality: 'Please update',
        parent_name: values.parentName,
        school: 'Please update',
        identification: 'Please update',
      })
        .then((res) => {})
        .catch((error) => {});
    }
  };

  const handlePayments = () => {
    if (values.payment === 'online') {
      handleOnlinePayment();
    } else {
      handleOfflinePayment();
    }
  };

  const subscriptionOffline = (subscription) => {
    setValues((oldValues) => ({
      ...oldValues,
      disableButton: true,
      isLoading: true,
    }));
    Service.subscriptionOffline(subscription)
      .then((res) => {
        setActiveStep(activeStep + 1);
        setValues((oldValues) => ({
          ...oldValues,
          disableButton: false,
          isLoading: false,
        }));
      })
      .catch((error) => {
        setActiveStep(activeStep + 1);
        setValues((oldValues) => ({
          ...oldValues,
          disableButton: false,
          backendError: true,
          isLoading: false,
        }));
      });
  };

  const getUserDetails = (fromNext) => {
    setValues((oldValues) => ({
      ...oldValues,
      disableButton: true,
      isLoading: true,
    }));

    Service.getUserDetails().then((res) => {
      setValues((oldValues) => ({
        ...oldValues,
        dob: res.data.date_of_birth,
        username: res.data.username,
        firstName: res.data.first_name,
        lastName: res.data.last_name,
        email: res.data.email,
        mobile: res.data.mobile,
        gender: res.data.gender,
        is_student: res.data.is_student,
        userid: res.data.id,
        disableButton: false,
        isLoading: false,
      }));
      if (fromNext) {
        setActiveStep(activeStep + 1);
      }
    });
  };

  // eslint-disable-next-line consistent-return
  const renderRedirectSignIn = () => {
    if (values.redirect) {
      return <Redirect to="/signup" />;
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (activeStep === 0 && props.isLoggedUser) {
      getUserDetails(true);
    } else if (activeStep === 0 && !props.isLoggedUser) {
      Swal.fire(
        'User not signed in',
        'Please sign in to continue',
        'warning'
      ).then(() => {
        localStorage.setItem('REDIRECT_URL', '/player/register');
        localStorage.setItem('REGISTRATION_VALUES', JSON.stringify(values));
        setValues((oldValues) => ({
          ...oldValues,
          redirect: true,
        }));
      });
    } else if (activeStep === 1) {
      if (props.isLoggedUser && props.academy_to !== '/player/register') {
        if (values.payment === 'online') {
          handleOnlinePayment();
        } else {
          handleOfflinePayment();
        }
      } else {
        if (!validatePersonalDetails()) {
          return;
        }
        updateUser();
        updateStudent();

        setValues((oldValues) => ({
          ...oldValues,
          postError: '',
        }));
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 2) {
      handlePayments();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setValues((oldValues) => ({
        ...oldValues,
        sessionSelected: [],
        firstStepValid: false,
      }));
    }
    setActiveStep(activeStep - 1);
  };

  function getCityName() {
    if (values.centerCode) {
      return values.cityObj.city_name;
    }
    const cityObj = values.cities.find(isCity);
    function isCity(cityObj) {
      return cityObj.id === parseInt(values.city, 10);
    }
    return cityObj.city_name;
  }

  const validatePersonalDetails = () => {
    if (
      values.firstName.length === 0 ||
      values.lastName.length === 0 ||
      !EMAIL_REGEX.test(String(values.email).toLowerCase()) ||
      Utils.getMobilewithPlus(values.mobile) === '+910000000' ||
      values.mobile.length < 6 ||
      values.parentName.length === 0
    ) {
      setValues((oldValues) => ({
        ...oldValues,
        personalDetailsError: true,
      }));
      return false;
    }
    return true;
  };

  // Used handling state in children
  const handleInputChange = (event, item) => {
    switch (item) {
      case 'age':
        handleChangeAge(event);
        break;
      case 'country':
        handleChangeCountry(event);
        break;
      case 'city':
        handleChangeCity(event);
        break;
      case 'sport':
        handleChangeSport(event);
        break;
      case 'branch':
        handleChangeBranch(event);
        break;
      case 'package':
        handleChangePackageSeason(event);
        break;
      case 'slots':
        handleChangeSlots(event);
        break;
      case 'next':
        setActiveStep(activeStep + 1);
        break;
      case 'mobile':
        setValues((oldValues) => ({
          ...oldValues,
          mobile: event,
        }));
        break;
      default:
        handleChangeDefault(event);
    }
  };

  const handleChangeAge = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setValues((oldValues) => ({
      ...oldValues,
      [name]: value,
      sessionSelected: [],
      firstStepValid: false,
    }));
  };

  const handleChangeDefault = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setValues((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  };

  const handleChangeCountry = (event) => {
    let cities = [];
    let currency = '';
    let countryObj = '';
    const { name } = event.target;
    const { value } = event.target;

    values.allCountriesBranches.forEach((country) => {
      if (country.id === value) {
        cities = country.cities;
        currency = country.currency;
        countryObj = country;
      }
    });

    setValues((oldValues) => ({
      ...oldValues,
      [name]: value,
      cities,
      currency,
      city: '',
      cityObj: '',
      branches: [],
      branch: '',
      branchPackage: '',
      packageIdSelected: '',
      packageSelected: '',
      packageSeasonIdSelected: '',
      branchSession: [],
      sessionSelected: [],
      firstStepValid: false,
      countryObj,
      payment: 'online',
    }));
  };

  function handleChangeCity(cityObj) {
    let branches = [];
    const name = 'city';
    const value = cityObj ? cityObj.id : '';

    values.cities.forEach((city) => {
      if (city.id === value) {
        branches = city.branches.filter((branch) => branch.is_active);
      }
    });

    setValues((oldValues) => ({
      ...oldValues,
      [name]: value,
      branches,
      branch: '',
      branchPackage: '',
      packageIdSelected: '',
      packageSelected: '',
      packageSeasonIdSelected: '',
      branchSession: [],
      sessionSelected: [],
      firstStepValid: false,
      cityObj,
    }));
  }

  function handleChangeSport(sport) {
    setValues((oldValues) => ({
      ...oldValues,
      sport,
      branch: '',
      branchPackage: '',
      packageIdSelected: '',
      packageSelected: '',
      packageSeasonIdSelected: '',
      branchSession: [],
      sessionSelected: [],
      firstStepValid: false,
    }));
  }

  function handleChangeBranch(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    function isBranch(branchPackage) {
      return branchPackage.id === parseInt(value, 10);
    }

    if (values.centerCode) {
      let branchObj = '';
      let city = '';
      let country = '';
      let currency = '';
      values.branches.forEach((branch) => {
        if (branch.id === parseInt(value, 10)) {
          branchObj = branch;
          city = branch.city.id;
          country = branch.city.country.id;
          currency = branch.city.country.currency;
        }
      });
      setValues((oldValues) => ({
        ...oldValues,
        city,
        country,
        currency,
        countryObj: branchObj.city.country,
        cityObj: branchObj.city,
      }));
    }

    Service.getAllPackages().then((res) => {
      const branchPackage = res.data.find(isBranch);
      setValues((oldValues) => ({
        ...oldValues,
        [name]: value,
        branchPackage,
        packageIdSelected: '',
        packageSelected: '',
        packageSeasonIdSelected: '',
        sessionSelected: [],
        firstStepValid: false,
      }));
    });

    Service.getBranchSessions(value).then((res) => {
      const branchSession = res.data;
      branchSession.sort((a, b) => (a.session_day > b.session_day ? 1 : -1));

      setValues((oldValues) => ({
        ...oldValues,
        branchSession,
        branchSessionList: res.data,
      }));
    });
  }

  function handleChangePackageSeason(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    const packageSelected = values.branchPackage.branch_package.find(isPackage);
    function isPackage(branchPackage) {
      return branchPackage.id === parseInt(name, 10);
    }
    let firstStepValid = false;
    if (packageSelected.sessionless_package) {
      firstStepValid = true;
    }

    setValues((oldValues) => ({
      ...oldValues,
      packageIdSelected: name,
      packageSeasonIdSelected: value,
      packageSelected,
      firstStepValid,
    }));
  }

  function handleChangeSlots(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { sessionSelected } = values;
    if (value) {
      sessionSelected.push(target.name);
    } else {
      remove(sessionSelected, target.name);
    }
    let firstStepValid = false;
    if (
      sessionSelected.length ===
      values.packageSelected.number_of_weekly_sessions
    ) {
      firstStepValid = true;
    }

    setValues((oldValues) => ({
      ...oldValues,
      sessionSelected,
      firstStepValid,
    }));
  }

  function remove(array, element) {
    const index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  return (
    <>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {!props.isLoggedUser ? (
            <Typography component="h6" variant="h6" align="center">
              Academy Registration
            </Typography>
          ) : (
            <Typography component="h6" variant="h6" align="center">
              Academy Registration
            </Typography>
          )}
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <PageLoading isLoading={values.isLoading} />
          <>
            {activeStep === steps.length ? (
              <>
                {getStepContent(activeStep, values, handleInputChange, props)}
                <div className={classes.buttons}>
                  <ButtonDefault
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/"
                    className={classes.button}
                  >
                    Done
                  </ButtonDefault>
                </div>
              </>
            ) : (
              <>
                {getStepContent(activeStep, values, handleInputChange, props)}
                {activeStep === 2 &&
                props.isLoggedUser &&
                props.academy_to !== '/player/register' ? (
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      component={Link}
                      to="/"
                    >
                      Done
                    </Button>
                  </div>
                ) : (
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button
                        color="primary"
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                    )}
                    {((activeStep === 0 && values.firstStepValid) ||
                      activeStep !== 0) &&
                      !(
                        (
                          ((activeStep === 2 &&
                            props.academy_to === '/player/register') ||
                            (activeStep === 1 &&
                              (props.academy_to === '/player/active' ||
                                props.academy_to === '/player/notpaid'))) &&
                          values.payment === 'online' &&
                          values.countryObj.name.toLowerCase() !== 'india'
                        ) // next button has razor pay for india
                      ) && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                          disabled={values.disableButton}
                        >
                          {activeStep === steps.length - 1 ? 'Sign Up' : 'Next'}
                        </Button>
                      )}
                  </div>
                )}
              </>
            )}
            {renderRedirectSignIn()}
          </>
        </Paper>
        {values.sessionSelected.length >
          values.packageSelected.number_of_weekly_sessions && (
          <MySnackbarContentWrapper
            variant="warning"
            message={`Please select only ${values.packageSelected.number_of_weekly_sessions} sessions`}
          />
        )}
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    academy_to: state.to,
  };
}

export default connect(mapStateToProps)(Registration);
