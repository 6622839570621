import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Today from '@material-ui/icons/Today';
import Schedule from '@material-ui/icons/Schedule';
import LocationOn from '@material-ui/icons/LocationOn';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Utils from '../shared/Utils';
import social from '../static/images/social.png';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    margin: 15,
  },
  media: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  posEnd: {
    marginBottom: 12,
    marginTop: 10,
    fontWeight: 'bold',
  },
  listItem: {
    padding: theme.spacing(0),
  },
}));

const getDay = (day) => {
  const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  return days[day - 1];
};

export default function PlayerPackages(props) {
  const classes = useStyles();
  const { branch } = props.packageObj;
  const packageNameCost = `${
    props.packageObj.package_season_type.season_type_name != null
      ? props.packageObj.package_season_type.season_type_name
      : ''
  } ${props.packageObj.package_cost} ${props.packageObj.currency}`;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography gutterBottom variant="h6">
          {props.packageObj.branch_packages.name}
        </Typography>
        <List>
          <ListItem className={classes.listItem} key={props.name}>
            <ListItemIcon>
              <Today />{' '}
            </ListItemIcon>
            <ListItemText
              primary={`${Utils.getDateUIString(
                props.packageObj.start_date
              )} - ${Utils.getDateUIString(props.packageObj.end_date)}`}
              secondary={packageNameCost}
            />
          </ListItem>
          {props.packageObj.branch_session.map((session) => {
            const text = `${getDay(session.session_day)} ${Utils.get12HFrom24H(
              session.start_time
            )}-${Utils.get12HFrom24H(session.end_time)}`;
            return (
              <ListItem key={session.id} className={classes.listItem}>
                <ListItemIcon>
                  <Schedule />{' '}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
        </List>

        <Divider />
        <List>
          <ListItem
            style={{
              display: 'block',
              textAlign: 'center',
            }}
          >
            <Box>
              <img
                src={branch.branch_logo_path}
                alt=""
                style={{ width: '45px', height: 'auto' }}
              />
            </Box>
          </ListItem>
          <ListItem className={classes.listItem} key={props.address}>
            <ListItemIcon>
              <LocationOn />{' '}
            </ListItemIcon>
            <ListItemText primary={branch.name} secondary={branch.email} />
          </ListItem>
          <ListItem className={classes.listItem} key={props.branch_manager}>
            <ListItemIcon>
              <SupervisorAccount />{' '}
            </ListItemIcon>
            <ListItemText
              primary={branch.branch_manager}
              secondary={branch.contact_number}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <Box className={classes.media}>
              {branch.social_network_url?.map((m) => (
                <a
                  href={m.url}
                  alt={m.name}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: '0 4px' }}
                >
                  <img
                    src={m.icon_path ? m.icon_path : social}
                    alt=""
                    style={{ width: '23px', height: 'auto' }}
                  />
                </a>
              ))}
            </Box>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
