import React from 'react';
import { connect } from 'react-redux';
import CoachMedicalQns from '../page_components/coach/CoachMedicalQns';
import PlayerMedicalQns from '../page_components/player/registered/PlayerMedicalQns';

function MedicalQns(prop) {
  return (
    <div>
      {prop.to === '/coach' ? <CoachMedicalQns /> : <PlayerMedicalQns />}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
    to: state.to,
  };
}

export default connect(mapStateToProps)(MedicalQns);
