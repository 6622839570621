/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinkMaterial from '@material-ui/core/Link';
import { Link, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import QueryString from 'query-string';
import axios from 'axios';
import LoginFacebook from '../components/ButtonFacebook';
import UserPool from '../shared/UserPool';
import Config from '../shared/Config';
import Button from '../components/ButtonDefault';
import PageLoading from '../components/PageLoading';
import Service from '../shared/Services';
import Utils from '../shared/Utils';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 300,
  },
}));

function SignIn(props) {
  useEffect(() => {
    if (props.location && props.location.value === 'logout') {
      window.location.reload(true);
      localStorage.removeItem('JWT_TOKEN');
      props.dispatch({ type: 'SIGNED_OUT' });

      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.signOut();
      }
    }

    // const to = localStorage.getItem('REDIRECT_URL');
    if (localStorage.getItem('REDIRECT_URL')) {
      setValues((oldValues) => ({
        ...oldValues,
        to: localStorage.getItem('REDIRECT_URL'),
      }));
    }
    const qp = props.location ? QueryString.parse(props.location.hash) : {};
    if (qp.id_token) {
      localStorage.setItem('JWT_TOKEN', `Bearer ${qp.id_token}`);
      props.dispatch({ type: 'SIGNED_IN' });
      setValues((oldValues) => ({
        ...oldValues,
        redirect: true,
      }));
    }
  }, [props]);

  const classes = useStyles();
  const emailFromSignUp = localStorage.getItem('USER')
    ? localStorage.getItem('USER')
    : '';
  const pwdFromSignUp = localStorage.getItem('PWD')
    ? localStorage.getItem('PWD')
    : '';
  localStorage.removeItem('USER');
  localStorage.removeItem('PWD');

  const [values, setValues] = React.useState({
    to: '/',
    email: emailFromSignUp,
    password: pwdFromSignUp,
    error: false,
    redirect: false,
    showPassword: false,
    autoLoad: false,
    errorMessage: 'Email ID or Password is incorrect',
  });

  const onSign = (event) => {
    event.preventDefault();
    setValues((oldValues) => ({
      ...oldValues,
      disabled: true,
    }));
    const user = new CognitoUser({
      Username: values.email.trim(),
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: values.email.trim(),
      Password: values.password.trim(),
    });
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        localStorage.setItem('JWT_TOKEN', `Bearer ${data.idToken.jwtToken}`);
        props.dispatch({ type: 'SIGNED_IN' });
        axios
          .all([Service.getUserDetails(), Service.getPackageDetails()])
          .then(
            axios.spread((user, pkgs) => {
              if (user.data.is_coach) {
                props.dispatch({ type: 'COACH' });
              } else if (Array.isArray(pkgs.data)) {
                if (Utils.hasActivePackages(pkgs.data)) {
                  props.dispatch({ type: 'PAID' });
                } else if (Utils.hasNewInActivePackages(pkgs.data)) {
                  props.dispatch({ type: 'NOTPAID' });
                }
              }
              setValues((oldValues) => ({
                ...oldValues,
                redirect: true,
                disabled: false,
              }));
            })
          )
          .catch((error) => {
            setValues((oldValues) => ({
              ...oldValues,
              redirect: true,
              disabled: false,
            }));
          });
      },

      onFailure: (err) => {
        // console.error("onFailure:", err);
        setValues((oldValues) => ({
          ...oldValues,
          error: true,
          disabled: false,
          errorMessage: err.message
            ? `${err.message}or Please verify your email by clicking the link that has been sent to your email address in order to log in using your email.`
            : 'Email ID or Password is incorrect',
        }));
      },

      newPasswordRequired: (data) => {
        setValues((oldValues) => ({
          ...oldValues,
          error: true,
          disabled: false,
          errorMessage: data.message
            ? data.message
            : 'Please set a new password',
        }));
      },
    });
  };

  const handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setValues((oldValues) => ({
      ...oldValues,
      [name]: value,
      error: false,
      disabled: false,
    }));
  };

  // eslint-disable-next-line consistent-return
  const renderRedirect = () => {
    if (props.isLoggedUser || values.redirect) {
      return <Redirect to={values.to} />;
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <PageLoading isLoading={values.disabled} />
          <FormControl className={clsx(classes.margin, classes.textField)}>
            <FilledInput
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address/Username"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleChange}
              autoFocus
            />
          </FormControl>
          <FormControl className={clsx(classes.margin, classes.textField)}>
            <FilledInput
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={values.showPassword ? 'text' : 'password'}
              id="password"
              value={values.password}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              autoComplete="current-password"
            />
          </FormControl>
          {values.error && (
            <FormHelperText error id="component-error-text">
              {values.errorMessage}
            </FormHelperText>
          )}
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <FormControl className={clsx(classes.margin, classes.textField)}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={onSign}
              disabled={values.disabled}
              className={classes.submit}
            >
              Sign In
            </Button>
          </FormControl>
          <Grid container>
            <Grid item xs>
              <LinkMaterial
                href={Config.RESET}
                target="_blank"
                rel="noopener"
                variant="body2"
              >
                Forgot password?
              </LinkMaterial>
            </Grid>
            <Grid item>
              <LinkMaterial component={Link} to="/signup" variant="body2">
                Don't have an account? Sign Up
              </LinkMaterial>
            </Grid>
          </Grid>
        </form>
        <Divider className={classes.margin} />
        <LoginFacebook />
        <Divider className={classes.margin} />
        {renderRedirect()}
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    isLoggedUser: state.isLoggedUser,
  };
}

export default connect(mapStateToProps)(SignIn);
