import React, { useState } from 'react';
import 'date-fns';
import { Tabs, Tab, Box } from '@material-ui/core';
import AttendanceUtil from './AttendanceUtil';

export default function AttendanceHome() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="Weekly Sessions" />
        <Tab label="Session Bundle" />
        <Tab label="Membership" />
      </Tabs>
      <TabPanelContent value={value} index={0}>
        <AttendanceUtil type="weekly" />
      </TabPanelContent>
      <TabPanelContent value={value} index={1}>
        <AttendanceUtil type="bundle" />
      </TabPanelContent>
      <TabPanelContent value={value} index={2}>
        <AttendanceUtil type="membership" />
      </TabPanelContent>
    </div>
  );
}

const TabPanelContent = ({ value, index, children }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);
