import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  makeStyles,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  IconButton,
  styled,
  alpha,
  InputBase,
} from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Swal from 'sweetalert2';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import SearchIcon from '@material-ui/icons/Search';
import PageLoading from './PageLoading';
import Service from '../shared/Services';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      // note: this style will be used when other modules will be added
      // overflowX: 'scroll',
      margin: '2rem 0.8rem 0.8rem 0.8rem',
    },
    cursor: 'pointer',
  },
  main: {
    textAlign: 'initial',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardMedia: {
    borderRadius: '15px',
    width: '90%',
    margin: '0 auto',
  },
  desc: {
    textAlign: 'left',
    fontSize: '12px',
    color: '#9d9d9d',
    overflowWrap: 'break-word',
  },
  listIcon: {
    alignSelf: 'flex-start',
    marginTop: '3px',
    color: 'red',
  },
  listItem: {
    margin: 0,
  },
  placeholder: {
    color: '#ccc',
    margin: '7rem auto',
  },
  dialogBtn: {
    justifyContent: 'flex-start',
    margin: '0.5rem 1rem',
  },
  date: {
    fontSize: '13px',
  },
  error: {
    color: '#aaa',
    fontSize: '11px',
    float: 'right',
  },
}));

const Search = styled('div')(({ theme }) => ({
  marginTop: '1rem',
  position: 'relative',
  border: '1px solid #ccc',
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  right: 0,
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(() => ({
  color: 'inherit',
  width: '28rem',
}));

export default function Notes() {
  const classes = useStyles();
  const [allNotes, setAllNotes] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openedNote, setOpenedNote] = React.useState({});
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setEditMode(false);
    setOpenedNote({});
    setModal(true);
  };
  const handleClose = () => {
    setModal(false);
  };

  const handleChange = (e) => {
    e.persist();
    setOpenedNote((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const addNote = () => {
    const { id, title, message, is_important: isImportant } = openedNote;
    if (editMode) {
      // edit notes
      setOpen(true);
      setModal(false);
      Service.updateNotes(id, title, message, isImportant)
        .then((res) => {
          setOpen(false);
          const temp = allNotes.filter((data) => data.id !== id);
          setAllNotes([res.data, ...temp]);
          Swal.fire('Note Updated Successfully!');
          setEditMode(false);
        })
        .catch((error) => {});
    } else {
      // add notes
      setOpen(true);
      Service.createNotes({
        title,
        message,
      })
        .then((res) => {
          setOpen(false);
          setAllNotes([res.data, ...allNotes]);
        })
        .catch((error) => {});
      setModal(false);
      setEditMode(false);
    }
  };

  const openNote = (note) => {
    setEditMode(true);
    setOpenedNote(note);
    setModal(true);
  };

  const deleteNote = () => {
    const { id } = openedNote;
    // delete notes
    setOpen(true);
    setModal(false);
    Service.deleteNotes(id, false)
      .then((res) => {
        setOpen(false);
        setAllNotes(allNotes.filter((data) => data.id !== id));
        Swal.fire('Note deleted Successfully!');
        setEditMode(false);
      })
      .catch((error) => {});
  };

  const handleSearch = (e) => {
    setInputSearch(e.target.value.trim());
  };

  useEffect(() => {
    if (inputSearch.length >= 3) {
      Service.searchAllNotes(inputSearch)
        .then((res) => {
          setAllNotes(res.data);
        })
        .catch((error) => {});
    } else {
      Service.getAllNotes().then((res) => {
        setOpen(false);
        setAllNotes(res.data);
      });
    }
  }, [inputSearch]);

  const toggleFavourite = (note) => {
    const { id, title, message, is_important: isImportant } = note;
    setOpen(true);
    // edit notes
    Service.updateNotes(id, title, message, !isImportant)
      .then((res) => {
        setOpen(false);
        const temp = allNotes.filter((data) => data.id !== id);
        setAllNotes([res.data, ...temp]);
        setModal(false);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search in notes"
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearch}
          style={{ width: '28rem' }}
          name="search"
          inputSearch={inputSearch}
        />
      </Search>
      <div>
        <Typography className={classes.error}>enter min 3 char</Typography>
      </div>
      <div style={{ margin: '2rem 2rem 0rem 2rem' }}>
        <Button color="secondary" variant="contained" onClick={handleClickOpen}>
          Create Note
        </Button>
      </div>
      <PageLoading isLoading={open} />
      {allNotes.length > 0 ? (
        allNotes.map((note) => (
          <Box
            style={{
              border: '1px solid #ccc',
              borderRadius: '10px',
              margin: '1rem 0',
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Typography className={classes.date}>
                {format(new Date(note.created), 'dd-MMMM-yyyy')}
              </Typography>
              <IconButton
                onClick={() => toggleFavourite(note)}
                style={{ color: 'red' }}
                fontSize="small"
              >
                {note.is_important ? <StarIcon /> : <StarBorderIcon />}
              </IconButton>
            </Box>
            <Card
              className={classes.card}
              elevation={0}
              onClick={() => openNote(note)}
            >
              <CardContent
                style={{ textAlign: 'initial', padding: '0px 16px' }}
              >
                <Box>
                  <Typography
                    variant="subtitle1"
                    style={{ overflowWrap: 'break-word' }}
                  >
                    <b>{note.title}</b>
                  </Typography>
                  <Typography type="subtitle1" className={classes.desc}>
                    {note.message}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        ))
      ) : (
        <Box className={classes.placeholder}>
          <MenuBookIcon fontSize="large" />
          <Typography type="h6"> NO NOTES FOUND </Typography>
          <Typography type="subtitle1">
            Create a new note from the button below
          </Typography>
        </Box>
      )}

      <Dialog fullWidth open={modal} onClose={handleClose}>
        <DialogTitle style={{ padding: '24px 24px 0' }}>
          <TextField
            autoFocus
            margin="dense"
            required
            name="title"
            placeholder="Enter Title"
            type="text"
            fullWidth
            variant="standard"
            value={openedNote.title}
            onChange={handleChange}
          />
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder="Start writing here..."
            multiline
            name="message"
            fullWidth
            rows={20}
            required
            value={openedNote.message}
            variant="standard"
            type="text"
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions className={classes.dialogBtn}>
          {editMode ? (
            <Button onClick={deleteNote} color="secondary" variant="outlined">
              Delete Note
            </Button>
          ) : null}

          <Button
            onClick={() => addNote(editMode)}
            color="secondary"
            variant="contained"
          >
            {editMode ? 'Update Note' : 'Save Note'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
