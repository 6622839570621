/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Service from '../../shared/Services';
import Utils from '../../shared/Utils';
import StudentListMedicalQns from '../../components/StudentListMedicalQns';

const useStyles = makeStyles((theme) => ({
  listItem: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    marginBottom: '1rem',
    cursor: 'pointer',
  },
}));

export default function CoachMedicalQns() {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(
    Utils.formatDate(new Date())
  );
  const { CancelToken } = axios;
  const source = CancelToken.source();
  const [values, setValues] = React.useState({
    // Package Selection
    classes: [],
    showClasses: true,
    showReport: false,
  });
  const handleShowReport = (showReport) => {
    setValues((oldValues) => ({
      ...oldValues,
      showReport,
    }));
  };

  const handleDateChange = (date) => {
    setSelectedDate(Utils.formatDate(date));
    getClasses();
    setValues((oldValues) => ({
      ...oldValues,
      index: -1,
      showClasses: true,
    }));
  };

  const handleShowClasses = () => {
    setValues((oldValues) => ({
      ...oldValues,
      showClasses: true,
    }));
  };

  const getClasses = () => {
    Service.getCoachAttendance(source, selectedDate).then((res) => {
      setValues((oldValues) => ({
        ...oldValues,
        classes: res.data,
      }));
    });
  };

  const handleClickClass = (index) => {
    setValues((oldValues) => ({
      ...oldValues,
      index,
      showClasses: false,
      showReport: false,
    }));
  };

  useEffect(() => {
    const CancelToken1 = axios.CancelToken;
    const source1 = CancelToken1.source();
    // code to run on component mount
    Service.getCoachAttendance(source1, selectedDate).then((res) => {
      setValues((oldValues) => ({
        ...oldValues,
        classes: res.data,
      }));
    });

    return function cleanup() {
      source1.cancel('Component Closed');
    };
  }, [selectedDate]);

  return (
    <div>
      {!values.showReport && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Select Date"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      )}
      {values.classes === '' || parseInt(values.classes.length, 10) === 0 ? (
        <Typography gutterBottom>No Classes Scheduled for today</Typography>
      ) : null}
      {values.showClasses ? (
        <List dense={false}>
          {values.classes.map((classObj, index) => (
            <ListItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={handleClickClass.bind(this, index)}
              className={classes.listItem}
            >
              <ListItemAvatar>
                <Avatar>{classObj.students.length}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${classObj.session_day.substring(
                  0,
                  3
                )} ${classObj.start_time.substring(
                  0,
                  5
                )} - ${classObj.end_time.substring(0, 5)}`}
                secondaryTypographyProps={{ style: { textAlign: 'left' } }}
                secondary={
                  classObj.skill_level.charAt(0).toUpperCase() +
                  classObj.skill_level.slice(1)
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <StudentListMedicalQns
          selectedDate={selectedDate}
          handleShowClasses={handleShowClasses}
          handleShowReport={handleShowReport}
          classObj={values.classes[values.index]}
          showReport={values.showReport}
        />
      )}
    </div>
  );
}
