import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Button, ListItemAvatar, Avatar } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import ProgressReportTabsCoach from './ProgressReportTabsCoach';
import Utils from '../shared/Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cell: {
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  studentName: {
    color: 'primary',
  },
  studentNameRed: {
    color: 'red',
  },
  studentNameOrange: {
    color: 'orange',
  },
  studentId: {
    textAlign: 'left',
    fontSize: '14px',
  },
  studentIdRed: {
    textAlign: 'left',
    fontSize: '14px',
    color: 'red',
  },
  studentIdOrange: {
    textAlign: 'left',
    fontSize: '14px',
    color: 'orange',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#38468e',
    color: theme.palette.common.white,
  },
  body: {
    backgroundColor: '#4657aa',
    color: theme.palette.common.white,
    fontSize: 14,
  },
}))(TableCell);

export default function StudentListProgressReport(props) {
  const classes = useStyles();
  const tempclassObj = props.classObj;
  if (tempclassObj !== '' && tempclassObj.students !== '') {
    tempclassObj.students = tempclassObj.students.sort((a, b) => {
      const fa = a.fullname.toLowerCase();
      const fb = b.fullname.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }

  const [values, setValues] = React.useState({
    classObj: tempclassObj,
  });

  const markProgress = (index) => () => {
    setValues((oldValues) => ({
      ...oldValues,
      studentId: values.classObj.students[index].student_user_id,
      studentName: values.classObj.students[index].fullname,
      userSubscriptionId: values.classObj.students[index].user_subscription_id,
    }));
    props.handleShowReport(true);
  };

  function hideProgress() {
    props.handleShowReport(false);
  }

  return (
    <div>
      {!props.showReport && (
        <List
          subheader={
            <ListSubheader>
              {' '}
              <IconButton
                onClick={props.handleShowClasses}
                edge="start"
                aria-label="mark"
              >
                <ArrowBack />
              </IconButton>{' '}
              {`${values.classObj.session_day.substring(
                0,
                3
              )} ${values.classObj.start_time.substring(
                0,
                5
              )} - ${values.classObj.end_time.substring(
                0,
                5
              )} ${values.classObj.skill_level
                .charAt(0)
                .toUpperCase()}${values.classObj.skill_level.slice(1)}`}
            </ListSubheader>
          }
          className={classes.root}
        >
          {values.classObj === '' ||
          values.classObj.students === '' ||
          parseInt(values.classObj.students.length, 10) === 0 ? (
            <Typography gutterBottom>
              No Students enrolled this class
            </Typography>
          ) : null}
          {values.classObj.students.map((student, index) => (
            <div className={classes.listItem}>
              <ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <ListItemAvatar>
                  <Avatar alt="player image" src={student.url}>
                    <PersonIcon fontSize="large" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  className={
                    // eslint-disable-next-line no-nested-ternary
                    Utils.getDatefromStringAddDays(student.end_date, 0) <
                    Utils.getDatefromString(props.selectedDate)
                      ? classes.studentNameRed
                      : Utils.getDatefromStringAddDays(student.end_date, -7) <=
                        Utils.getDatefromString(props.selectedDate)
                      ? classes.studentNameOrange
                      : classes.studentName
                  }
                  primary={
                    student.fullname.charAt(0).toUpperCase() +
                    student.fullname.slice(1)
                  }
                  secondary={
                    <div>
                      <Typography
                        type="subtitle1"
                        className={
                          // eslint-disable-next-line no-nested-ternary
                          Utils.getDatefromStringAddDays(student.end_date, 0) <
                          Utils.getDatefromString(props.selectedDate)
                            ? classes.studentIdRed
                            : Utils.getDatefromStringAddDays(
                                student.end_date,
                                -7
                              ) <= Utils.getDatefromString(props.selectedDate)
                            ? classes.studentIdOrange
                            : classes.studentId
                        }
                      >
                        {student.student_user_id},{' '}
                        {Utils.getDateUIString(student.start_date)} -{' '}
                        {Utils.getDateUIString(student.end_date)}
                      </Typography>
                    </div>
                  }
                />
                {Utils.getDatefromString(student.end_date) >=
                  Utils.getDatefromString(props.selectedDate) && (
                  <ListItemSecondaryAction>
                    <Button
                      size="small"
                      onClick={markProgress(index)}
                      variant="contained"
                      color="primary"
                    >
                      Mark
                    </Button>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </div>
          ))}
        </List>
      )}
      {props.showReport && (
        <div>
          <TableContainer className={classes.root}>
            <ListSubheader>
              {' '}
              <IconButton onClick={hideProgress} edge="start" aria-label="mark">
                <ArrowBack />
              </IconButton>{' '}
              {`${values.classObj.session_day.substring(
                0,
                3
              )} ${values.classObj.start_time.substring(
                0,
                5
              )} - ${values.classObj.end_time.substring(
                0,
                5
              )} ${values.classObj.skill_level
                .charAt(0)
                .toUpperCase()}${values.classObj.skill_level.slice(1)}`}
            </ListSubheader>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    {' '}
                    {values.studentName}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {values.studentId}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center">
                    {Utils.gotMonthName(props.selectedDate.split('-')[1])}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {props.selectedDate.split('-')[0]}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <ProgressReportTabsCoach
            selectedDate={props.selectedDate}
            year={props.selectedDate.split('-')[0]}
            month={props.selectedDate.split('-')[1]}
            studentId={values.studentId}
            userSubscriptionId={values.userSubscriptionId}
            sessionId={props.classObj.session_id}
          />
        </div>
      )}
    </div>
  );
}
