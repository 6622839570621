/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { makeStyles, Typography, Grid, TextField } from '@material-ui/core';
import 'date-fns';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Service from '../../../shared/Services';

const useStyles = makeStyles(() => ({
  root: {
    overflowX: 'hidden',
    height: '480px',
    paddingRight: '10px',
  },
}));

export default function PlayerFitness() {
  const classes = useStyles();
  const [fitnessAns, SetFitnessAns] = React.useState([]);
  const [fitnessQns, SetFitnessQns] = React.useState([]);

  useEffect(() => {
    Service.getPlayerFitnessAns().then((res) => {
      SetFitnessAns(res.data);
    });

    Service.getFitnessQns().then((res) => {
      SetFitnessQns(res.data);
    });
  }, []);

  return (
    <>
      {fitnessAns.length !== 0 ? (
        <div className={classes.root}>
          {fitnessQns.map((ans) => (
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <CheckCircleIcon style={{ color: 'green' }} />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="subtitle2"
                  style={{ textAlign: 'left', marginBottom: '0.7rem' }}
                >
                  {ans.question}
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  fullWidth
                  variant="outlined"
                  rows={4}
                  disabled
                  placeholder="Specify Reasons"
                  value={
                    fitnessAns.find(
                      (ftans) => ftans.quaternaries_question === ans.id
                    )?.text_answer
                  }
                />
              </Grid>
            </Grid>
          ))}

          <Typography
            variant="body2"
            style={{ color: '#ccc', marginTop: '2rem' }}
          >
            NOTE: You are not allowed to edit the fitness test. For any queries
            please contact your coach.
          </Typography>
        </div>
      ) : (
        <div style={{ color: 'darkgrey', padding: '8rem 2rem' }}>
          <Typography variant="h6">No fitness test report found</Typography>
          <Typography variant="body2">
            Please ask your coach to submit fitness report
          </Typography>
        </div>
      )}
    </>
  );
}
